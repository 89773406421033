import React from 'react'

const Leader = ({ avatar, alt, name, designation }) => {
    return <div className="oc-team-grid-item">
    <div className="oc-card oc-card-team">
        <div className="oc-card-team-img">
            <img loading="lazy" src={avatar} alt={alt} height="200" width="200" />
        </div>
        <div className="oc-card-team-info">
            <h6>{name}</h6>
            <p>{designation}</p>
        </div>
    </div>
</div>
}


export default Leader