import { createContext, useEffect, useState } from "react";
import useHttp from "../hooks/useHttp";

const initialData = {
  description: "",
  area: "",
  code: "",
  logo: "",
  subcounties: [
    {
      name: "",
      wards: [],
      population: [],
      household: "",
    },
  ],
  administrative_hq: "",
  contacts: {
    address: "",
    email: "",
    phone: "",
    website: "",
    twitter: "",
    facebook: "",
    youtube: "",
  },
  leaders: [
    {
      age: null,
      avatar: null,
      key: "governor",
      name: "",
      twitter: null,
    },
    {
      age: null,
      avatar: null,
      key: "deputy_governor",
      name: "",
      twitter: null,
    },
    {
      age: null,
      avatar: null,
      key: "senator",
      name: "",
      twitter: null,
    },
    {
      age: null,
      avatar: null,
      key: "women_rep",
      name: "",
      twitter: null,
    },
  ],
  name: "",
  population: [],
};

const CountyContext = createContext({
  description: "",
  area: "",
  code: "",
  logo: "",
  administrative_hq: "",
  subcounties: [
    {
      name: "",
      wards: [],
      population: [],
      household: "",
    },
  ],
  contacts: {
    address: "",
    email: "",
    phone: "",
    website: "",
    twitter: "",
    facebook: "",
    youtube: "",
  },
  leaders: [
    {
      age: null,
      avatar: null,
      key: "governor",
      name: "",
      twitter: null,
    },
    {
      age: null,
      avatar: null,
      key: "deputy_governor",
      name: "",
      twitter: null,
    },
    {
      age: null,
      avatar: null,
      key: "senator",
      name: "",
      twitter: null,
    },
    {
      age: null,
      avatar: null,
      key: "women_rep",
      name: "",
      twitter: null,
    },
  ],
  name: "",
  population: [],
});

export const CountyContextProvider = (props) => {
  const [countyData, setCountyData] = useState(initialData);
  const { sendRequest } = useHttp();
  const [contactInfo, setContactInfo] = useState(initialData.contacts);

  useEffect(() => {
    const fetchCountyData = async () => {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/about`
      );
      if (response && response.data) {
        setCountyData(response.data);
        const p = response.data.contacts.filter(
          (res) => res.name === "phone"
        )[0];
        const e = response.data.contacts.filter(
          (res) => res.name === "email"
        )[0];
        const w = response.data.contacts.filter(
          (res) => res.name === "website"
        )[0];
        const a = response.data.contacts.filter(
          (res) => res.name === "address"
        )[0];

        setContactInfo({
          phone: p ? p.value : "",
          email: e ? e.value : "",
          website: w ? w.value : "",
          address: a ? a.value : "",
        });
      }
    };
    fetchCountyData();
  }, [sendRequest]);

  return (
    <CountyContext.Provider
      value={{
        name: countyData.name,
        area: countyData.area,
        logo: countyData.logo,
        population: countyData.population,
        description: countyData.description,
        administrative_hq: countyData.administrative_hq
          ? countyData.administrative_hq
          : "",
        subcounties: countyData.subcounties ? countyData.subcounties : [],
        code: countyData.code,
        contacts: contactInfo,
        leaders: countyData.leaders,
      }}
    >
      {props.children}
    </CountyContext.Provider>
  );
};

export default CountyContext;
