import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { fCurrency, fShortenNumber } from "../util/formatNumber";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const TopSpendingProjects = ({ projects, isTable }) => {
  const [topProjects, setTopProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const sortedProjects = projects.sort(
      (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
    );
    const topFive = sortedProjects.slice(0, 5);
    setTopProjects(topFive);
  }, [projects]);

  return (
    <>
      <h6 className="styled-heading styled-heading-small">
        Top 5 Spending Projects
      </h6>
      {isTable === false && (
        <ul className="top-spending">
          {topProjects.map((topProject, index) => (
            <li
              key={topProject.id}
              onClick={() => navigate(`/projects/${topProject.id}`)}
              style={{ cursor: "pointer" }}
            >
              <p>{topProject.name}</p>
              <span>{`KES ${fShortenNumber(topProject.amount)}`}</span>
            </li>
          ))}
        </ul>
      )}
      {isTable === true && (
        <>
          <TableContainer component={Paper}>
            <Table className="data-table" stickyHeader>
              <caption className="caption">Data Source: <strong>Office of the Controller of Budgets</strong></caption>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Project Title</TableCell>
                  <TableCell align="left">Sub-county</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Amount(KES)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topProjects.map((row) => {
                  const { name, amount, status, constituency } = row;
                  return (
                    <TableRow
                      sx={{ padding: 2 }}
                      hover
                      key={name}
                      tabIndex={-1}
                    >
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{constituency.name}</TableCell>
                      <TableCell align="left">{status}</TableCell>
                      <TableCell align="left">{fCurrency(amount)}</TableCell>
                      {/* <TableCell align="left" sx={{ padding: "8px" }}>
                      <CustomProgressBar
                        bgcolor="#299F00"
                        height="25px"
                        baccColor="#ecfce6"
                        progress={percentage}
                      />
                    </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default TopSpendingProjects;
