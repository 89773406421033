import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Button, Divider } from '@mui/material';
import { Icon } from '@iconify/react';
import pdfFill from '@iconify/icons-eva/clipboard-fill';
import csvFill from '@iconify/icons-eva/external-link-fill';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CSVLink } from 'react-csv';
// material
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

ExportOptionsMenu.propTypes = {
  onExportToCsv: PropTypes.func,
  onExportToPdf: PropTypes.func,
  onExportToXls: PropTypes.func,
};

export default function ExportOptionsMenu({
  onExportToCsv,
  onExportToPdf,
  onExportToXls,
  headers,
  items,
  fileName,
  excelFileName,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        ref={ref}
        variant='contained'
        sx={{
          backgroundColor: '#108214',
          color: 'white',
        }}
        size='small'
        startIcon={<FileDownloadIcon />}
        onClick={() => setIsOpen(true)}
      >
        Download Data As...
      </Button>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <CSVLink
          headers={headers}
          asyncOnClick={true}
          data={items}
          filename={fileName}
          onClick={(event, done) => {
            onExportToCsv();
            done();
          }}
        >
          <MenuItem sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={csvFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary='CSV'
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        </CSVLink>
        <Divider />
        <MenuItem onClick={onExportToPdf} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={pdfFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary='PDF'
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
