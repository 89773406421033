import { useState, useEffect, useCallback, useRef } from 'react';

const useHttp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const abortRef = useRef([]);

    const sendRequest = useCallback(async(url, method='GET', body=null, headers={}) => {
        setIsLoading(true);
        const AbortController = window.AbortController;
        const abortCtlr = new AbortController();
        abortRef.current.push(abortCtlr);
        try {
            const response = await fetch(url, {
                method, body, headers, signal: abortCtlr.signal
            });
            const resData = await response.json();
            if(!response.ok) {
                throw new Error(resData.data);
            }
            setIsLoading(false);
            return resData;
        } catch (error) {
            setIsLoading(false);
            setError(error);
        }
    },[]);
    const clearError = () => {
        setError(null);
    }
    useEffect(()=>{
        return abortRef.current.forEach(abt=>abt.abort());
    },[])
    return { isLoading, error, sendRequest, clearError }
}

export default useHttp;