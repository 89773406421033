import { filter } from "lodash";
import React, { useState } from "react";
import { capitalCase } from "change-case";
// material
import { useTheme } from "@mui/material/styles";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
// components
import SearchNotFound from "./SearchNotFound";
import HealthTableHead from "./HealthTableHead";
import HealthToolbar from "./HealthToolbar";
import Label from "../util/Label";

const TABLE_HEAD = [
  { id: "facility", label: "Facility Name", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "owner", label: "Owner", alignRight: false },
  { id: "phone", label: "Contact", alignRight: false },
  { id: "infrastructure", label: "Infrastructure", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "utilities", label: "Utilities", alignRight: false },
  { id: "ward", label: "Ward", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (data) => data.facility.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function FacilitiesTable({
  countyFacilities,
  numberOfFacilities,
}) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("facility");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = countyFacilities.map((n) => n.facility);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredFacilities = applySortFilter(
    countyFacilities,
    getComparator(order, orderBy),
    filterName
  );

  const isFacilityNotFound = filteredFacilities.length === 0;

  return (
    <>
      {/* <Scrollbar> */}
      <HealthToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      ></HealthToolbar>
      <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
        <Table className="data-table" stickyHeader>
          <HealthTableHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={countyFacilities.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {filteredFacilities
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const {
                  id,
                  facility,
                  type,
                  owner,
                  phone,
                  infrastructure,
                  status,
                  utilities,
                  ward,
                } = row;
                const isItemSelected = selected.indexOf(facility) !== -1;
                let labelColor = "warning";
                if (status && status === "Incomplete and in use") {
                  labelColor = "error";
                } else if (status && status === "Complete and in use") {
                  labelColor = "success";
                } else if (status && status === "Complete but not in use") {
                  labelColor = "info";
                } else if (status && status === "Other") {
                  labelColor = "warning";
                }

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell align="left">
                      {facility ? capitalCase(facility) : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {type ? capitalCase(type) : "-"}
                    </TableCell>
                    <TableCell align="left">{owner}</TableCell>
                    <TableCell align="left">{phone}</TableCell>
                    <TableCell align="left">
                      <Label
                        variant={
                          theme.palette.mode === "light" ? "ghost" : "filled"
                        }
                        color={labelColor}
                      >
                        {capitalCase(status ? status : "Unavailable")}
                      </Label>
                    </TableCell>
                    <TableCell align="left">{infrastructure}</TableCell>
                    <TableCell align="left">{utilities}</TableCell>
                    <TableCell align="left">{ward}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          {isFacilityNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={numberOfFacilities}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* </Scrollbar> */}
    </>
  );
}
