import React, { useEffect, useState } from "react";
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
//
import BaseOptionChart from "./BaseOptionChart";
import { fShortenNumber } from "../util/formatNumber";

// ----------------------------------------------------------------------

// const CHART_DATA = [
//   { name: 'Public', data: [44, 55, 57, 56, 61, 58, 63, 60] },
//   { name: 'Private', data: [76, 85, 101, 98, 87, 105, 91, 114] },
//   { name: 'FBO', data: [56, 95, 111, 108, 97, 115, 101, 124] }
// ];

const getAmounts = (arry) => {
  return arry.map((arr) => arr.amount);
};

const getLabels = (arry) => {
  return arry.map((arr) => arr.name);
};

// export const isObject = (obj) => {
//   return Object.prototype.toString.call(obj) === '[object Object]';
// };

export default function TopBudgetAbsorptionDepts({ departmentSpendData }) {
  const [departments, setDepartments] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (departmentSpendData) {
      if (departmentSpendData.length >= 5) {
        const deptSpend = departmentSpendData
          .sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount))
          .slice(0, 5);
        const deptAmounts = [{ name: "Amount", data: getAmounts(deptSpend) }];
        setAmounts(deptAmounts);
        const labelItems = getLabels(deptSpend);
        setDepartments(labelItems);
        setColors(departmentSpendData.map((item) => item.color));
      } else {
        const deptAmounts = [
          { name: "Amount in KES", data: getAmounts(departmentSpendData) },
        ];
        setAmounts(deptAmounts);
        const labelItems = getLabels(departmentSpendData);
        setDepartments(labelItems);
        setColors(departmentSpendData.map((item) => item.color));
      }
    }
  }, [departmentSpendData]);

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      background: "#fff",
      toolbar: {
        show: true,
        offsetX: 15,
        offsetY: -40,
      },
    },
    stroke: { show: false },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        barHeight: "50%",
        //columnWidth: '80%',
        borderRadius: 0,
        distributed: true,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      formatter: function (val, opt) {
        return fShortenNumber(val);
      },
    },
    xaxis: {
      categories: departments,
      labels: {
        formatter: function (val) {
          return fShortenNumber(val);
        },
      },
      style: {
        textTransform: "capitalize",
      },
      title: {
        text: "Amount in Kes",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        style: {
          cssClass: "oc-charts-yaxis-label",
        },
      },
      style: {
        textTransform: "lowercase",
      },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter(val) {
          return `${fShortenNumber(val)}`;
        },
      },
    },
    colors: colors,
    responsive: [
      {
        breakpoint: 767.98,
        options: {
          chart: {
            height: 500,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "80%",
            },
          },
          legend: {
            horizontalAlign: "left",
            fontSize: "12px",
            position: "bottom",
            show: true,
            offsetX: 0,
            offsetY: 0,
            itemMargin: {
              horizontal: 0,
              vertical: 5,
            },
          },
          yaxis: {
            show: false,
          },
        },
      },
    ],
  });

  return (
    <div className="oc-card">
      <div className="sector-card-top">
        <h6 className="styled-heading styled-heading-small">
          Best 5 Budget Absorption Departments
        </h6>
        <ReactApexChart
          type="bar"
          series={amounts}
          options={chartOptions}
          height="auto"
        />
      </div>
    </div>
  );
}
