import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

ProjectsToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function ProjectsToolbar({
  filterName,
  onFilterName,
}) {
 
  return (
    <div className="mb-4">
      <TextField
          fullWidth
          size="small"
          label="Search for projects"
          className="bg-white"
          placeholder="Start typing..."
          value={filterName}
          onChange={onFilterName}
        />    
    </div>
  );
}
