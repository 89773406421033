import React, { useState, useEffect } from "react";
import { merge } from "lodash";
import { CircularProgress } from "@mui/material";
import { fShortenNumber } from "../../util/formatNumber";
import ReactApexChart from "react-apexcharts";
//
import BaseOptionChart from "../../components/BaseOptionChart";

// ----------------------------------------------------------------------

const getBudgetPlanNumbers = (arry, y) => {
  var bp = [];
  y.forEach((year) => {
    var t = 0;
    arry.forEach((arr) => {
      if (arr.period === year && !isNaN(arr.budget_plan)) {
        t = t + arr.budget_plan * 1000000;
      }
    }
    )
    bp.push(t);
  }  
  )
return bp ;
};

const getActualReceivedNumbers = (arry, y) => {
  var bp = [];
  y.forEach((year) => {
    var t = 0;
    arry.forEach((arr) => {
      if (arr.period === year && !isNaN(arr.actual_received)) {
        t = t + arr.actual_received * 1000000;
      }
    }
    )
    bp.push(t);
  }  
  )
return bp ;
};

const getActualExpenditureNumbers = (arry, y) => {
  var bp = [];
  y.forEach((year) => {
    var t = 0;
    arry.forEach((arr) => {
      if (arr.period === year && !isNaN(arr.actual_expenditure)) {
        t = t + arr.actual_expenditure * 1000000;
      }
    }
    )
    bp.push(t);
  }  
  )
return bp ;};

const getYears = (arry) => {
  var years = [];
  years = arry.map((arr) => arr.period);
  return [...new Set(years)]
};

export default function RevExpeditureLine({
  dataOnRevenue,
  dataOnExpenditure,
  dataOnBudgetTrends,
}) {
  const [years, setYears] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const itemYears = getYears(dataOnBudgetTrends);

    const itemData = [
      {
        name: "Budget Plan",
        data: getBudgetPlanNumbers(dataOnBudgetTrends, itemYears),
      },
      {
        name: "Actual Received",
        data: getActualReceivedNumbers(dataOnBudgetTrends, itemYears),
      },
      {
        name: "Actual Expenditure",
        data: getActualExpenditureNumbers(dataOnBudgetTrends, itemYears) 
      },
    ];


    setYears(itemYears);
    setData(itemData);
    setIsLoading(false);
  }, [dataOnRevenue, dataOnExpenditure, dataOnBudgetTrends]);

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      background: "#fff",
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 5,
    },
    xaxis: {
      title: { text: "Fiscal Year" },
      categories: years,
    },
    yaxis: {
      forceNiceScale: true,
      title: {
        text: "Amount in KES",
      },
      labels: {
        formatter: function (val) {
          return fShortenNumber(val);
        },
      },
    },
    tooltip: {
      theme: "dark",
      x: { show: false },
      marker: { show: false },
      y: {
        formatter(val) {
          return `${fShortenNumber(val)}`;
        },
      },
    },
    colors: ["#2E93fA", "#66DA26", "#546E7A", "#E91E63", "#FF9800"],
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
      },
    },
    responsive: [
      {
        breakpoint: 767.98,
        options: {
          chart: {
            height: 500,
            toolbar: {
              show: false
            }
          }         
        }
      }
    ]     
  });

  return isLoading ? (
    <CircularProgress sx={{ color: "green" }} />
  ) : (
    <>
      <h4 className="styled-heading styled-heading-small">Trend Analysis</h4>
      <div className="oc-card">
        <ReactApexChart
          type="line"
          series={data}
          options={chartOptions}
          height={360}
        />
      </div>
    </>
  );
}
