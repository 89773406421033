import { Button, Divider } from "@mui/material";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { CSVLink } from "react-csv";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import csvFill from "@iconify/icons-eva/external-link-fill";
import pdfFill from "@iconify/icons-eva/clipboard-fill";
import { Icon } from "@iconify/react";
import {
  handlePdfExport,
  prepareColumnDataForExport,
  prepareDataForExport,
  prepareGroupColumnDataForExport,
} from "../util/pdfExports";
import { fShortenNumber } from "../util/formatNumber";

const Export = ({
  indicatorData,
  selectedItemIndicator,
  data,
  hasConstituency,
  hasGroup,
  yrs,
  cols,
  orgData,
  organizedData,
  years,
  columns,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [headers, setHeaders] = useState([]);
  const [items, setItems] = useState([]);
  const [exportPdfBody, setExportPdfBody] = useState([]);

  const showValue = (myKey, _set, product, period, format) => {
    if (indicatorData) {
      let cell = indicatorData.data.find(
        (a) =>
          a[indicatorData.metadata.key] === product.name &&
          a.period === period &&
          a[indicatorData.metadata.set_key] === _set
      );
      return cell
        ? cell.value != 0
          ? format === true
            ? fShortenNumber(cell.value)
            : cell.value
          : "0"
        : "0";
    }
  };

  const handleItems = useCallback(() => {
    if (indicatorData) {
      if (!hasConstituency && hasGroup) {
        console.log("if");
        const hdr = [
          { label: "", key: "location" },
          { label: "", key: "alias" },
        ];
        const fr = { location: "", alias: "" };
        const itms = [];

        indicatorData.metadata &&
          indicatorData.metadata.periods.forEach((period, index) => {
            indicatorData.metadata &&
              indicatorData.metadata.sets.forEach((set) => {
                const key = `${set}_${index}`.replace(/\s/g, "_");
                hdr.push({ label: period, key: key });
                fr[key] = set;
              });
          });
        itms.push(fr);

        indicatorData.metadata &&
          indicatorData.metadata.products.forEach((product) => {
            const row = {};
            row["location"] = product.location ? product.location : "";
            row["alias"] = product.alias;
            indicatorData.metadata &&
              indicatorData.metadata.periods.forEach((period, index) => {
                indicatorData.metadata &&
                  indicatorData.metadata.sets.forEach((set) => {
                    const key = `${set}_${index}`.replace(/\s/g, "_");
                    row[key] = showValue(
                      indicatorData.key,
                      set,
                      product,
                      period,
                      false
                    );
                  });
              });
            itms.push(row);
          });

        setItems(itms);
        setHeaders(hdr);
      } else if (hasGroup) {
        const { head, body, pdfBody } = prepareGroupColumnDataForExport(
          orgData,
          yrs,
          cols,
          hasConstituency
        );
        setHeaders(head);
        setItems(body);
        setExportPdfBody(pdfBody);
      } else {
        if (columns.length > 0) {
          const { head, body, pdfBody } = prepareColumnDataForExport(
            organizedData,
            years,
            columns,
            hasConstituency
          );
          setHeaders(head);
          setItems(body);
          setExportPdfBody(pdfBody);
        } else {
          const { body, head, pdfBody } = prepareDataForExport(
            organizedData,
            years,
            columns,
            hasConstituency
          );
          setHeaders(head);
          setItems(body);
          setExportPdfBody(pdfBody);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorData]);

  const onHandlePdfExport = () => {
    if (headers.length > 16) {
      alert(
        "Unable to save data as PDF correctly due to the number of columns on the table (download as CSV instead)"
      );
      return;
    }
    handlePdfExport(
      headers,
      exportPdfBody,
      `${selectedItemIndicator.value}-${selectedItemIndicator.name}`,
      `${selectedItemIndicator.value}-${selectedItemIndicator.name}.pdf`,
      selectedItemIndicator.name
    );
  };

  useEffect(() => {
    handleItems();
  }, [handleItems]);

  return (
    <>
      <Button
        ref={ref}
        variant="contained"
        sx={{
          backgroundColor: "#108214",
          color: "white",
        }}
        size="small"
        startIcon={<FileDownloadIcon />}
        onClick={() => setIsOpen(true)}
      >
        Download Data As...
      </Button>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <CSVLink
          headers={headers}
          asyncOnClick={true}
          data={items}
          filename={`${selectedItemIndicator.value}-${selectedItemIndicator.name}.csv`}
          onClick={(event, done) => {
            handleItems();
            done();
          }}
        >
          <MenuItem sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Icon icon={csvFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="CSV"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        </CSVLink>
        <Divider />
        <MenuItem onClick={onHandlePdfExport} sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Icon icon={pdfFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="PDF"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default Export;
