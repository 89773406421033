import { fShortenNumber } from '../util/formatNumber';
import React from 'react';
const RevExCard = ({ revExItem }) => {
  //const { name, total, type, trend, itemType } = revExItem;
  return (
    <div className='oc-budget-card oc-card'>
      <h6>{revExItem && revExItem.name ? revExItem.name : ''}</h6>
      <div
        className={`oc-budget-card-stat ${
          revExItem && revExItem.trend === 'up' ? 'success' : 'primary'
        }`}
      >
        <p>
          {revExItem && revExItem.type === 'total'
            ? fShortenNumber(
                revExItem && revExItem.total && revExItem.itemType === 'revenue'
                  ? revExItem.total
                  : revExItem.total
              )
            : `${revExItem && revExItem.total ? revExItem.total : 0}`}
        </p>
        <span
          className={`trend ${
            revExItem && revExItem.trend === 'up' ? 'trend-up' : 'trend-down'
          }`}
        ></span>
      </div>
    </div>
  );
};

export default RevExCard;
