import React from 'react';
import ReactApexChart from 'react-apexcharts';
import BaseOptionChart from '../BaseOptionChart';
import {merge} from 'lodash';
import {fShortenNumber, fNumber} from '../../util/formatNumber';

export default function PopulationChart({dataSet}) {
    const chartData = merge(BaseOptionChart(), {
        chart: {
            type: 'bar',
            stacked: false,
            toolbar: {
                show: true,
                offsetX: 15,
                offsetY: -40,
            }
        },
        legend: {
            show: true,
        },
        colors: ['#008FFB', '#FF4560'],
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '100%',
                borderRadius: 0,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#999']
            },
            offsetX: 50,
            formatter: function (val) {
                return fShortenNumber(Math.abs(Math.round(val)))
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff'],
        },
        tooltip: {
            shared: false,
            x: {
                show: true,
                formatter: function (val) {
                    return val;
                },
            },
            y: {
                formatter: function (val) {
                    return fNumber(Math.abs(Math.round(val)));
                },
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
        },
        xaxis: {
            categories: [
                'Not Stated',
                '100+',
                '95-99',
                '90-94',
                '85-89',
                '80-84',
                '75-79',
                '70-74',
                '65-69',
                '60-64',
                '55-59',
                '50-54',
                '45-49',
                '40-44',
                '35-39',
                '30-34',
                '25-29',
                '20-24',
                '15-19',
                '10-14',
                '5-9',
                '0-4',
            ],
            title: {
                text: 'Population',
            },
            labels: {
                formatter: function (val) {
                    return fShortenNumber(Math.abs(Math.round(val)));
                },
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'middle',
                formatter: function (val, opt) {
                    return fShortenNumber(val);
                },
            },
            show: true,
            enabledOnSeries: [0],
            textAnchor: "left",
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: false,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
                colors: [],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
            },
        },
        yaxis: {
            title: {
                //text: 'Age Groups'
            },
        },
    });


    return (
        <>
            {dataSet.length > 0 && (
                <ReactApexChart
                    type='bar'
                    series={dataSet}
                    options={chartData}
                    height='500'
                />
            )}
        </>
    );
}
