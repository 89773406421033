import React, { useState, useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Map from "../components/map/Map";
import useFetchSectors from "../hooks/useFetchSectors";
import useFetchProjects from "../hooks/useFetchProjects";
import useHttp from "../hooks/useHttp";
import { fNumber, fPercent, fShortenNumber } from "../util/formatNumber";
import Area from "../images/icons/area.png";
import Population from "../images/icons/population.png";
import Woman from "../images/icons/woman.png";
import Man from "../images/icons/man.png";
//import Intersex from '../images/icons/intersex.png';
// import Family from '../images/icons/family.png';
// import Hospital from '../images/icons/hospital.png';
// import Sports  from '../images/icons/sports.png';
// import School from '../images/icons/school.png';
import TeaFarm from "../images/harvesting-tea-nandi.jpg";
import RevExCard from "../components/RevExCard";
// import FacilityChart from '../components/DashboardCharts/FacilityChart';
// import CooperativesTypeChart from '../components/DashboardCharts/CooperativesTypeChart';
import PopulationChart from "../components/DashboardCharts/PopulationChart";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import CountyContext from "../store/CountyContext";

export default function Homepage() {
  const { sectors } = useFetchSectors();
  const { separatedProjects } = useFetchProjects();
  const {
    name: countyName,
    area: countyArea,
    population,
  } = useContext(CountyContext);

  const { sendRequest } = useHttp();
  // const [ecdes, setEcdes] = useState(0);
  // const [healthFacility, sethealthFacility] = useState(0);
  const [absorption, setAbsorption] = useState(0);
  const [budgetComp, setBudgetComp] = useState();
  const [lastFy, setLastFy] = useState("");
  const [expenditureComp, setExpenditureComp] = useState();
  //const [facilityCount, setFacilitiesCount] = useState({});
  // const [cooperativeTypes, setCooperativesTypes] = useState(null);
  // const [sportCentersCount, setSportCenters] = useState(null)
  const [populationCount, setPopulationCount] = useState([]);
  //const [colorMap, setColors] = useState([]);

  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  //   const fetchSubCountyColors = useCallback(async () => {
  //     try {
  //       const res = await sendRequest(
  //         `${process.env.REACT_APP_BASEDEVURL}/api/boundary`
  //       );
  //       if (res && res.data) {
  //        setColors(res.data.map(a => a.color))
  //       }
  //     } catch (error) {
  //     }
  //   },
  //   [sendRequest]
  // );
  // useEffect(() => {
  //   fetchSubCountyColors();
  // }, [fetchSubCountyColors]);

  const fetchExpenditure = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/budgets/expenditure`
      );
      if (res && res.data) {
        const lastExp = res.data.trends[res.data.trends.length - 1].total;
        setLastFy(res.data.trends[res.data.trends.length - 1].period);
        setBudgetComp({
          name: "Expenditure (KES)",
          total: fShortenNumber(+lastExp),
          type: "total",
          trend: "down",
          itemType: "expenditure",
        });
        setAbsorption({
          name: "Budget Absorption Rate (%)",
          total: fPercent(res.data.absorption * 100),
          type: "percentage",
          trend: "up",
          itemType: "revenue",
        });
      }
    } catch (error) {}
  }, [sendRequest]);

  const fetchRevenue = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/budgets/revenue`
      );
      if (res && res.data) {
        const lastRev = res.data.trends[res.data.trends.length - 1].total;
        setExpenditureComp({
          name: "Revenue Allocation (KES)",
          total: fShortenNumber(+lastRev),
          type: "total",
          trend: "up",
          itemType: "revenue",
        });
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchExpenditure();
    fetchRevenue();
  }, [fetchExpenditure, fetchRevenue]);

  // const fetchEcdes = useCallback(async () => {
  //   try {
  //     const res = await sendRequest(
  //       `${process.env.REACT_APP_BASEDEVURL}/api/indicator/4.4`
  //     );
  //     if (res && res.data && res.data.stats) {
  //       const total =
  //         res.data.stats[0].data[res.data.stats[0].data.length - 1].count +
  //         res.data.stats[1].data[res.data.stats[0].data.length - 1].count;
  //       setEcdes(total);
  //     }
  //   } catch (error) {}
  // }, [sendRequest]);

  // useEffect(() => {
  //   fetchEcdes();
  // }, [fetchEcdes]);

  // const fetchFacilities = useCallback(async () => {
  //   try {
  //     const res = await sendRequest(
  //       `${process.env.REACT_APP_BASEDEVURL}/api/sectors/dashboard/5`
  //     );
  //     if (res && res.data) {
  //       const dataC = res.data.filter((r) => r.index.includes("5.1"));
  //       setFacilitiesCount({
  //         title: dataC[0].label ? dataC[0].label : "",
  //         keys: dataC[0].datasets.keys.filter((ds) => ds !== null),
  //         values: dataC[0].datasets.values,
  //       });

  //       sethealthFacility(dataC[0].datasets.values.reduce((a, b) => a + b, 0))
  //     }
  //   } catch (error) {}
  // }, [sendRequest]);

  // useEffect(() => {
  //   fetchFacilities();
  // }, [fetchFacilities]);

  // const fetchCooperativesTypes = useCallback(async () => {
  //   try {
  //     const res = await sendRequest(
  //       `${process.env.REACT_APP_BASEDEVURL}/api/sectors/dashboard/1`
  //     );
  //     if (res && res.data) {
  //       const dataC = res.data.filter((r) => r.index.includes("6.40"));
  //       setCooperativesTypes({
  //         title: dataC[0].label ? dataC[0].label : '',
  //         keys: dataC[0].datasets.keys.filter((ds) => ds !== null),
  //         values: dataC[0].datasets.values,
  //       });
  //     }
  //   } catch (error) {}
  // }, [sendRequest]);

  // useEffect(() => {
  //   fetchCooperativesTypes();
  // }, [fetchCooperativesTypes]);

  // const fetchSportCentersCount = useCallback(async () => {
  //   try {
  //     const res = await sendRequest(
  //       `${process.env.REACT_APP_BASEDEVURL}/api/sectors/dashboard/7`
  //     );
  //     if (res && res.data) {
  //       const dataC = res.data.filter((r) => r.index.includes("5.23"));
  //       setSportCenters({
  //         // title: dataC[0].label ? dataC[0].label : '',
  //         // keys: dataC[0].datasets.keys.filter((ds) => ds !== null),
  //         values: dataC[0].datasets.values,

  //       });
  //       setSportCenters(dataC[0].datasets.values.reduce((a, b) => a + b, 0))
  //     }
  //   } catch (error) {}
  // }, [sendRequest]);

  // useEffect(() => {
  //   fetchSportCentersCount();
  // }, [fetchSportCentersCount]);

  const fetchPopuplation = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/age/groups`
      );
      if (res && res.data) {
        setPopulationCount([
          {
            name: "male",
            data: res.data.males.map((a) => parseFloat(a) * -1).reverse(),
          },
          { name: "female", data: res.data.females.reverse() },
        ]);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchPopuplation();
  }, [fetchPopuplation]);

  return (
    <div className="wrapper">
      <div className="hero-section">
        <img src={TeaFarm} alt="Tea farm" />
        <div className="hero-section-text">
          <h1>{`${countyName} Open County Portal`}</h1>
          <InputGroup className="hero-search">
            <FormControl
              onKeyUp={(e) => {
                if (e.key === "Enter")
                  navigate({ pathname: "/search", search: `?q=${query}` });
              }}
              onChange={(e) => setQuery(e.target.value)}
              defaultValue={query}
              size="lg"
              placeholder={`Search for data in ${countyName} County`}
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <Button
              className="bg-warning"
              onClick={() =>
                navigate({ pathname: "/search", search: `?q=${query}` })
              }
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
        </div>
      </div>
      <div className="content-section">
        <Container>
          <h2 className="styled-heading styled-heading-large">
            County Overview
          </h2>

          <Row className="mb-0 mb-md-4">
            <Col lg="6">
              <div className="oc-card">
                <h4 className="oc-card-small-heading">
                  {`Population Pyramid for ${countyName} County (2019 census)`}
                </h4>
                <PopulationChart dataSet={populationCount} />
                <span className="text-muted small">
                  Source:{" "}
                  <strong>Population from KPHC 2019 vol3, Area vol 1</strong>
                </span>
              </div>
            </Col>

            <Col lg="6">
              <div className="oc-card">
                <div className="icon-grid">
                  <div className="icon-grid-item">
                    <img src={Area} alt="Area" width="50" height="50" />
                    <h6 className="icon-grid-item-title">Area (SQ KM)</h6>
                    <p className="icon-grid-item-number">
                      {/* {fNumber(nandiData ? nandiData.area : '')}
                       */}
                      {countyArea}
                    </p>
                  </div>
                  <div className="icon-grid-item">
                    <img
                      src={Population}
                      alt="Population"
                      width="50"
                      height="50"
                    />
                    <h6 className="icon-grid-item-title">Total Population </h6>
                    <p className="icon-grid-item-number">
                      {fNumber(
                        population && population[0] && population[0].total
                          ? population[0].total
                          : ""
                      )}
                    </p>
                  </div>
                  <div className="icon-grid-item">
                    <img src={Man} alt="Man" width="50" height="50" />
                    <h6 className="icon-grid-item-title">Male Population </h6>
                    <p className="icon-grid-item-number">
                      {fNumber(
                        population && population[0] && population[0].male
                          ? population[0].male
                          : 441259
                      )}
                    </p>
                  </div>
                  <div className="icon-grid-item">
                    <img src={Woman} alt="Woman" width="50" height="50" />
                    <h6 className="icon-grid-item-title">Female Population </h6>
                    <p className="icon-grid-item-number">
                      {fNumber(
                        population && population[0] && population[0].female
                          ? population[0].female
                          : 444430
                      )}
                    </p>
                  </div>
                  {/* <div className='icon-grid-item'>
                    <img src={Intersex} alt='Intersex' width='50' height='50' />
                    <h6 className='icon-grid-item-title'>Intersex Population</h6>
                    <p className='icon-grid-item-number'>
                     {fNumber(population.intersex? population.intersex : 0)}
                    </p>
                  </div>
                  <div className='icon-grid-item'>
                    <img src={Family} alt='Intersex' width='50' height='50' />
                    <h6 className='icon-grid-item-title'>No. of HouseHolds</h6>
                    <p className='icon-grid-item-number'>
                    199,426
                    </p>
                  </div> */}
                </div>
                <span className="text-muted small">
                  Source:{" "}
                  <strong>Population from KPHC 2019 vol3, Area vol 1</strong>
                </span>
              </div>
            </Col>
          </Row>

          <Row className="mb-0 mb-md-4">
            <Col lg="6">
              <div className="oc-card">
                <div className="oc-heading-group">
                  <h4 className="styled-heading styled-heading-small">
                    Administrative Boundaries
                  </h4>
                </div>
                <div className="oc-map-small">
                  <Map />
                </div>
              </div>
            </Col>

            <Col lg="6">
              <div className="oc-card">
                <div className="oc-heading-group">
                  <h4 className="styled-heading styled-heading-small">
                    Sectors
                  </h4>
                  <Link className="oc-card-small-link" to="/sectors">
                    View all
                  </Link>
                </div>
                <div className="oc-card-sectors">
                  {sectors.slice(0, 4).map((sector, index) => (
                    <div className="oc-card-sector" key={index}>
                      <div className="oc-card-sector-icon">
                        <Link to={`/sector/${sector.id}`}>
                          <img
                            src={sector.icon}
                            alt={sector.name}
                            height="47"
                            width="47"
                          />
                        </Link>
                      </div>
                      <div className="oc-card-sector-txt">
                        <h6>
                          <Link
                            style={{
                              pointerEvents:
                                sector.indicators_count === 0 ? "none" : "",
                            }}
                            to={`/sector/${sector.id}`}
                          >
                            {sector.name}
                          </Link>
                        </h6>
                        <span>{sector.indicators_count} indicators</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mb-0 mb-md-4">
            <Col lg="12">
              <div className="oc-heading-group">
                <h4 className="styled-heading styled-heading-small">
                  {`Finance & Planning (FY ${lastFy})`}
                </h4>
                <Link className="oc-card-small-link" to="/budgets">
                  More details
                </Link>
              </div>
              <div className="oc-budget-cards">
                <RevExCard revExItem={budgetComp} />
                {/* <div className="oc-budget-card oc-card">
                  <h6>Revenue Allocation (2020-21)</h6>
                  <div className="oc-budget-card-stat primary">
                    <p>5.2B</p>
                    <span className="trend trend-down"></span>
                  </div>
                </div> */}
                {/* <div className="oc-budget-card oc-card">
                  <h6>Expenditure (2020-21)</h6>
                  <div className="oc-budget-card-stat primary">
                    <p>{fShortenNumber(annualExpenditure)}</p>
                    <span className="trend trend-up"></span>
                  </div>
                </div> */}
                <RevExCard revExItem={expenditureComp} />
                {/* <div className="oc-budget-card oc-card">
                  <h6>Budget Absorption (2020-21)</h6>
                  <div className="oc-budget-card-stat primary">
                    <p>94.2%</p>
                    <span className="trend trend-up"></span>
                  </div>
                </div> */}

                <RevExCard revExItem={absorption} />
              </div>
            </Col>
          </Row>

          <Row className="mb-0 mb-md-4">
            <Col lg="12">
              <div className="oc-heading-group">
                <h4 className="styled-heading styled-heading-small">
                  Projects (Cumulative since FY 2013-2014)
                </h4>
                <Link className="oc-card-small-link" to="/projects">
                  Browse projects
                </Link>
              </div>

              <Row>
                {separatedProjects.length > 0 &&
                  separatedProjects.map((separatedProject, index) => (
                    <Col lg="3" md="6" sm="12" key={index}>
                      <div className="oc-icon-card oc-card">
                        <div className="oc-icon-card-img">
                          <img
                            src={`./images/status-${separatedProject.status
                              .split(" ")
                              .join("-")
                              .toLowerCase()}.png`}
                            alt=""
                            height="50"
                            width="50"
                          />
                        </div>
                        <div className="oc-icon-card-txt">
                          <h6>{separatedProject.status}</h6>
                          <p>{separatedProject.projectCount}</p>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>

          {/* <Row className="mb-0 mb-md-4">
            <Col lg="12">
              <h4 className="styled-heading styled-heading-small">
                Other Information
              </h4>
              <Row>
              <Col lg="4">
                  <div className="oc-icon-card oc-card">
                    <div className="oc-icon-card-img">
                      <img src={Hospital} alt="" height="50" width="50" />
                    </div>
                    <div className="oc-icon-card-txt">
                      <h6>Total Health Facilities</h6>
                      <p>{fNumber(healthFacility)}</p>
                    </div>
                  </div>
                </Col>

                <Col lg="4">
                  <div className="oc-icon-card oc-card">
                    <div className="oc-icon-card-img">
                      <img src={School} alt="" height="50" width="50" />
                    </div>
                    <div className="oc-icon-card-txt">
                      <h6>Total ECDE Centres</h6>
                      <p>{fNumber(ecdes)}</p>
                    </div>
                  </div>
                </Col>


            <Col lg="4">
                  <div className="oc-icon-card oc-card">
                    <div className="oc-icon-card-img">
                      <img src={Sports} alt="" height="50" width="50" />
                    </div>
                    <div className="oc-icon-card-txt">
                      <h6>Total Sport Centres</h6>
                      <p>{ fNumber(sportCentersCount) }</p>
                    </div>
                  </div>
            </Col>
              </Row>
            </Col>
          </Row> */}

          {/* <Row>
            <Col lg="6" className="mb-0 mb-md-4">
              <div className="oc-card">
              <Link
                      to={"/sector/5"}
                      onClick={function () {
                        const sInd = 0;
                        if (sInd.length > 0) {
                          setSelectedItemIndicator(sInd[0]);
                          setInputItemIndicator(
                            sInd[0].value + " - " + sInd[0].name
                          );
                          handleFilterIndicator(sInd[0] ? sInd[0].value : "");
                        }
                      }}
                      className="oc-card-small-heading"
                    >
                         <h4 className="oc-card-small-heading">
                              {facilityCount && facilityCount.title
                    ? facilityCount.title
                    : '' }
                          </h4>
                    </Link>
                {Object.keys(facilityCount).length > 0 && (
                  <FacilityChart dataSet={facilityCount}  colorMap={colorMap} itemIndicatorOptions={itemIndicatorOptions}
                  setSelectedItemIndicator={setSelectedItemIndicator}
                  setInputItemIndicator={setInputItemIndicator}
                  handleFilterIndicator={handleFilterIndicator} />
                )}
              </div>
            </Col>

            <Col lg="6" className="mb-0 mb-md-4">
              <div className="oc-card">
              <Link
                      to={"/sector/1"}
                      onClick={function () {
                        const sInd = itemIndicatorOptions.filter((item) =>
                          item.name
                            .replace(/\s/g, "")
                            .toLowerCase()
                            .includes(
                              cooperativeTypes.title
                                .replace(" By Sub County", "")
                                .replace(/\s/g, "")
                                .toLowerCase()
                            )
                        );
                        if (sInd.length > 0) {
                          setSelectedItemIndicator(sInd[0]);
                          setInputItemIndicator(
                            sInd[0].value + " - " + sInd[0].name
                          );
                          handleFilterIndicator(sInd[0] ? sInd[0].value : "");
                        }
                      }}
                      className="oc-card-small-heading"
                    >
                        <h4 className="oc-card-small-heading">
                  { cooperativeTypes && cooperativeTypes.title
                    ? cooperativeTypes.title
                    : ''}
                </h4>
                    </Link>
                {cooperativeTypes != null && (
                  <CooperativesTypeChart dataSet={cooperativeTypes}  colorMap={colorMap}  itemIndicatorOptions={itemIndicatorOptions}
                  setSelectedItemIndicator={setSelectedItemIndicator}
                  setInputItemIndicator={setInputItemIndicator}
                  handleFilterIndicator={handleFilterIndicator} />
                )}
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </div>
  );
}
