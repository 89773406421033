import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cgds } from "./CitizenGeneratedData";
import Breadcrumb from "../ui-components/Breadcrumb";
import {
  Grid,
  Stack,
  Tooltip as MuiTooltip,
  Autocomplete,
  TextField,
  Chip,
  ButtonGroup,
  Button,
  Paper,
  InputBase,
  IconButton,
} from "@mui/material";
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  GeoJSON,
} from "react-leaflet";
import SearchIcon from "@mui/icons-material/Search";
import { icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { FullscreenControl } from "react-leaflet-fullscreen";
import MarkerClusterGroup from "react-leaflet-cluster";

import geojson from "../assets/nandi-subcounties.geojson.json";
import {
  DashboardCustomize,
  FilterAlt,
  Info,
  ListSharp,
  Map,
} from "@mui/icons-material";
import useFetchSabasiEducationGeoData from "../hooks/useFetchSabasiEducationData";
import EducationPopUp from "../components/EducationPopUp";
import EducationDetails from "../components/EducationDetails";
import useFetchFacilityTypes from "../hooks/useFetchFacilityType";
import { useDebounce } from "use-debounce";
import useHttp from "../hooks/useHttp";
import FacilitiesTable from "../components/EducationDataTable";
import useFetchSabasiHealthData from "../hooks/useFetchHealthData";
import { DATA_VIEWS, aboutAWeekAgo } from "./CitizenGeneratedDataDetails";
import useFetchSabasiWards from "../hooks/useFetchSabasiWards";

const getEducationInsights = (count) => [
  {
    title: "Total Number of Education facilities",
    quantity: count,
    isTooltip: false,
    isFilterIcon: true,
  },
  {
    title: "Average distance to education facility",
    quantity: "3 km",
    isTooltip: false,
    isFilterIcon: false,
  },
  {
    title: "Average Infrastructure Quality",
    quantity: "Good",
    isTooltip: true,
    isFilterIcon: false,
  },
  {
    title: "Total Population",
    quantity: "4,397,073",
    isTooltip: false,
    isFilterIcon: false,
  },
];

export const NANDI_EDUCATION = process.env.REACT_APP_NANDI_EDUCATION || "";

export const resolveInfoItem = (infra) => {
  if (infra.toLowerCase() === "very poor") {
    return "Facility infrastructure is severely lacking, outdated, and inadequate in various aspects.";
  } else if (infra.toLowerCase() === "poor") {
    return "Infrastructure quality is below acceptable standards, with noticeable deficiencies.";
  } else if (infra.toLowerCase() === "fair") {
    return "The facility's infrastructure meets basic requirements but may have some issues or areas for improvement.";
  } else if (infra.toLowerCase() === "good") {
    return "Infrastructure quality is generally satisfactory, with well-maintained facilities and modern equipment.";
  } else if (infra.toLowerCase() === "excellent") {
    return " Facility infrastructure is of high quality, state-of-the-art, and well-maintained";
  } else {
    return "-";
  }
};

export const getCoords = (geo) => {
  if (!geo) {
    return [0, 0];
  }

  const splatted = geo.replace(/\s/g, "").split(",");

  if (splatted.length === 2) {
    const itm = splatted.map((d) => Number(d));
    return [itm[0], itm[1]];
  } else {
    return [0, 0];
  }
};

function concatenateArrays(obj) {
  const result = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key].length > 0) {
      const values = obj[key].map((item) => item.name).join(",");
      result.push(`${key}=${values}`);
    }
  }

  return result.join("&");
}

const EducationCitizenGeneratedDataDetails = () => {
  const { id } = useParams();
  const [foundCgd, setFoundCgd] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [educationAlignment, setEducationAlignment] = useState({
    id: DATA_VIEWS.map,
    name: DATA_VIEWS.map,
  });

  const [isVisible, setIsVisible] = useState(false);
  const [selectedFacilityIdx, setSelectedFacilityIdx] = useState(null);

  const [inputWardsValue, setInputWardsValue] = useState("");

  const [inputStatusValue, setInputStatusValue] = useState("");

  const [inputFacilityTypesValue, setInputFacilityTypesValue] = useState("");
  const [inputFacilityOwnershipValue, setInputFacilityOwnershipValue] =
    useState("");

  const { wards, isLoading } = useFetchSabasiWards();
  const { facilityTypes, isLoadingFacilityTypes } =
    useFetchFacilityTypes("Education");

  const [selectedFilters, setSelectedFilters] = useState({
    //subcounty: [],
    ward: [],
    type: [],
    status: [],
    ownership: [],
  });

  const { educationGeo, setEducationGeo } = useFetchSabasiEducationGeoData();

  const { educationGeo: educationGeoAll } = useFetchSabasiEducationGeoData();

  const { healthData } = useFetchSabasiHealthData("Education");

  const { sendRequest, isLoading: isLoadingFilterData } = useHttp();

  const [educationDetailsItem, setEducationDetailsItem] = useState(undefined);

  const [filterText, setFilterText] = useState("");
  const [debounced] = useDebounce(filterText, 1000);

  useEffect(() => {
    const dt = cgds.filter((cgd) => cgd.id === +id);
    if (dt.length > 0) {
      setFoundCgd(dt[0]);
    }
  }, [id]);

  const handleFacilitySelected = (idx, facility, code) => {
    setSelectedFacilityIdx(idx);
  };

  return (
    <div className="data-wrapper">
      <Breadcrumb
        title={`Citizen Generated Data - ${foundCgd ? foundCgd.name : ""}`}
      >
        <Stack direction="row" spacing={1}>
          <Chip
            icon={<Map />}
            label="Map View"
            variant="filled"
            size="small"
            color={
              educationAlignment.name === DATA_VIEWS.map ? "success" : "default"
            }
            onClick={() =>
              setEducationAlignment({
                id: DATA_VIEWS.map,
                name: DATA_VIEWS.map,
              })
            }
          />
          <Chip
            icon={<DashboardCustomize />}
            label="Reports"
            size="small"
            color={
              educationAlignment.name === DATA_VIEWS.reports
                ? "success"
                : "default"
            }
            variant="filled"
            onClick={() =>
              setEducationAlignment({
                id: DATA_VIEWS.reports,
                name: DATA_VIEWS.reports,
              })
            }
          />
          <Chip
            icon={<ListSharp />}
            label="Data Table"
            size="small"
            color={
              educationAlignment.name === DATA_VIEWS.data_table
                ? "success"
                : "default"
            }
            variant="filled"
            onClick={() =>
              setEducationAlignment({
                id: DATA_VIEWS.data_table,
                name: DATA_VIEWS.data_table,
              })
            }
          />
        </Stack>
      </Breadcrumb>
      <div className="mt-2 ml-4 relative">
        <Grid container spacing={2}>
          {educationAlignment.name === DATA_VIEWS.map ? (
            <>
              <Grid item sm={12} md={3} lg={3} style={{ padding: "1rem" }}>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    {isVisible ? (
                      <EducationDetails
                        educationCode={
                          educationDetailsItem
                            ? educationDetailsItem.educationCode
                            : 0
                        }
                        setIsVisible={setIsVisible}
                      />
                    ) : (
                      <>
                        <p>
                          Based on a comprehensive Nandi County survey, this
                          report offers insights on education infrastructure,
                          empowering data-driven decisions to enhance local
                          education services.
                        </p>
                        <h1 className="styled-heading styled-heading-large">
                          Key Insights
                        </h1>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={2}
                        >
                          {getEducationInsights(educationGeo.length).map(
                            (kI) => (
                              <div
                                key={kI.title}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <p>{kI.title}</p>
                                  {kI.isTooltip && (
                                    <MuiTooltip
                                      title={resolveInfoItem("Good")}
                                      arrow
                                      fontSize="small"
                                      sx={{ marginLeft: "0.5rem" }}
                                    >
                                      <Info />
                                    </MuiTooltip>
                                  )}
                                  {kI.isFilterIcon && isFilterApplied && (
                                    <MuiTooltip
                                      title="filter applied"
                                      arrow
                                      fontSize="small"
                                      sx={{ marginLeft: "0.5rem" }}
                                    >
                                      <FilterAlt
                                        color="success"
                                        fontSize="medium"
                                      />
                                    </MuiTooltip>
                                  )}
                                </div>
                                <h5 className="font-bold">{kI.quantity}</h5>
                              </div>
                            )
                          )}
                        </Stack>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={9} lg={9}>
                <Grid container spacing={2} mb={1}>
                  {/* <Grid item sm={12} md={2}>
                    <Autocomplete
                      disableClearable
                      disabled={isLoading}
                      size="small"
                      limitTags={1}
                      multiple
                      options={constituencies.map((c) => ({
                        id: c.id,
                        name: c.name,
                      }))}
                      value={selectedFilters.subcounty}
                      inputValue={inputConstituencyValue}
                      onInputChange={(event, newInputValue) => {
                        setInputConstituencyValue(newInputValue);
                      }}
                      onChange={(event, newValue) => {
                        setConstituencyValue(newValue);
                        setSelectedFilters((prev) => ({
                          ...prev,
                          subcounty:
                            typeof newValue === "string"
                              ? newValue.split(",")
                              : newValue,
                        }));
                      }}
                      getOptionLabel={(option) => option.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option.name}
                            size="small"
                            label={option.name}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Sub County"
                          size="small"
                          label="Sub County"
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item sm={12} md={2}>
                    <Autocomplete
                      disableClearable
                      disabled={isLoading}
                      size="small"
                      limitTags={1}
                      multiple
                      options={wards.map((c) => ({
                        id: c,
                        name: c,
                      }))}
                      value={selectedFilters.ward}
                      inputValue={inputWardsValue}
                      onInputChange={(event, newInputValue) => {
                        setInputWardsValue(newInputValue);
                      }}
                      onChange={(event, newValue) => {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          ward:
                            typeof newValue === "string"
                              ? newValue.split(",")
                              : newValue,
                        }));
                      }}
                      getOptionLabel={(option) => option.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option.name}
                            size="small"
                            label={option.name}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Ward"
                          size="small"
                          label="Ward"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={2}>
                    <Autocomplete
                      disableClearable
                      className="view-filter"
                      size="small"
                      fullWidth
                      multiple
                      options={[
                        { id: 2, name: "Public" },
                        { id: 3, name: "Private" },
                      ]}
                      value={selectedFilters.ownership}
                      inputValue={inputFacilityOwnershipValue}
                      onInputChange={(event, newInputValue) => {
                        setInputFacilityOwnershipValue(newInputValue);
                      }}
                      onChange={(event, newValue) => {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          ownership:
                            typeof newValue === "string"
                              ? newValue.split(",")
                              : newValue,
                        }));
                      }}
                      getOptionLabel={(option) => option.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option.name}
                            size="small"
                            label={option.name}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Ownership"
                          size="small"
                          label="Facility Ownership"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={2}>
                    <Autocomplete
                      disableClearable
                      disabled={isLoadingFacilityTypes}
                      size="small"
                      limitTags={1}
                      multiple
                      options={facilityTypes.map((c) => ({
                        id: c.id,
                        name: c.name,
                      }))}
                      value={selectedFilters.type}
                      inputValue={inputFacilityTypesValue}
                      onInputChange={(event, newInputValue) => {
                        setInputFacilityTypesValue(newInputValue);
                      }}
                      onChange={(event, newValue) => {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          type:
                            typeof newValue === "string"
                              ? newValue.split(",")
                              : newValue,
                        }));
                      }}
                      getOptionLabel={(option) => option.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option.name}
                            size="small"
                            label={option.name}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Facility Type"
                          size="small"
                          label="Facility Type"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <Autocomplete
                      disableClearable
                      className="view-filter"
                      size="small"
                      fullWidth
                      multiple
                      limitTags={1}
                      options={[
                        { id: 1, name: "Complete and in use" },
                        { id: 2, name: "Complete but not in use" },
                        { id: 3, name: "Incomplete and in use" },
                        { id: 4, name: "Incomplete not in use" },
                        { id: 5, name: "Other" },
                      ]}
                      value={selectedFilters.status}
                      inputValue={inputStatusValue}
                      onInputChange={(event, newInputValue) => {
                        setInputStatusValue(newInputValue);
                      }}
                      onChange={(event, newValue) => {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          status:
                            typeof newValue === "string"
                              ? newValue.split(",")
                              : newValue,
                        }));
                      }}
                      getOptionLabel={(option) => option.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option.name}
                            size="small"
                            label={option.name}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Status"
                          size="small"
                          label="Status"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={2}>
                    <ButtonGroup
                      size="small"
                      aria-label="small button group"
                      color="success"
                      disabled={isLoadingFilterData}
                    >
                      <Button
                        variant="contained"
                        onClick={async () => {
                          try {
                            const resp = await sendRequest(
                              `https://api.sabasi.mobi/api/nandi/geo?sector=Education&${concatenateArrays(
                                selectedFilters
                              )}`
                            );
                            if (resp && resp.data) {
                              setEducationGeo(resp.data);
                              setIsFilterApplied(true);
                            }
                          } catch (err) {}
                        }}
                      >
                        Apply
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          setSelectedFilters({
                            ownership: [],
                            status: [],
                            subcounty: [],
                            type: [],
                            ward: [],
                          });
                          setEducationGeo(educationGeoAll);
                          setIsFilterApplied(false);
                        }}
                      >
                        Clear All
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
                <MapContainer
                  id="map-container"
                  center={[0.1836, 35.1269]}
                  zoom={10}
                  scrollWheelZoom={false}
                  style={{ height: "760px", width: "100%", zIndex: 0 }}
                >
                  <div className="w-full h-full relative">
                    <div
                      className="absolute top-2 left-0 right-0 p-2 m-auto text-center translate-y-0"
                      style={{ zIndex: 999 }}
                    >
                      <Paper
                        component="form"
                        sx={{
                          p: "1px 2px",
                          display: "flex",
                          alignItems: "center",
                          ml: 6,
                          width: 400,
                        }}
                      >
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Search Facility"
                          inputProps={{ "aria-label": "search facility" }}
                          onChange={(e) => {
                            setFilterText(e.target.value);
                          }}
                        />
                        <IconButton
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                        >
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </div>
                  </div>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <FullscreenControl forceSeparateButton position="topright" />
                  <GeoJSON
                    data={geojson}
                    style={{
                      weight: 2,
                      opacity: 1,
                      color: "#FF3514",
                      fillOpacity: 0.1,
                      fillColor: "transparent",
                    }}
                    // onEachFeature={(feature, layer) =>
                    //   onEachFeature(feature, layer)
                    // }
                  />
                  <MarkerClusterGroup
                    chunkedLoading
                    showCoverageOnHover={false}
                    animate
                  >
                    {educationGeo
                      .filter((g) =>
                        g.facility
                          .toLowerCase()
                          .includes(debounced.toLowerCase().trim())
                      )
                      .map((data, idx) => (
                        <Marker
                          key={data.code}
                          position={getCoords(data.gps)}
                          icon={
                            selectedFacilityIdx === idx
                              ? icon({
                                  iconUrl: "/health-pin.png",
                                  iconSize: [64, 64],
                                })
                              : icon({
                                  iconUrl: "/health-pin.png",
                                  iconSize: [48, 48],
                                })
                          }
                          eventHandlers={{
                            click: () => {
                              setIsVisible(true);
                              setEducationDetailsItem({
                                setIsVisible: setIsVisible,
                                educationCode: data.code,
                              });
                              handleFacilitySelected(
                                idx,
                                data.facility,
                                data.code
                              );
                            },
                          }}
                        >
                          <Tooltip className="request-popup" interactive>
                            <EducationPopUp
                              educationCode={data.code}
                              facility={data.facility}
                            />
                          </Tooltip>
                        </Marker>
                      ))}
                  </MarkerClusterGroup>
                  {/* {checked && (
                  <div
                    className="min-w-min absolute bottom-2 right-0 p-4"
                    style={{ zIndex: 999 }}
                  >
                    <PopulationInfo item={POPULATION} />
                  </div>
                )} */}
                </MapContainer>
              </Grid>
            </>
          ) : educationAlignment.name === DATA_VIEWS.reports ? (
            <>
              <iframe
                width="100%"
                height="800px"
                seamless
                title="Nandi Education Data"
                style={{ border: 0, overflow: "hidden" }}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={NANDI_EDUCATION}
              ></iframe>
            </>
          ) : (
            <div className="container mt-2">
              <FacilitiesTable
                countyFacilities={healthData}
                numberOfFacilities={healthData.length}
              />
            </div>
          )}
        </Grid>
        {educationAlignment.name === DATA_VIEWS.map && (
          <div class="absolute bottom-2 w-full">
            <div className="flex flex-row">
              <p>
                Data Source:{" "}
                <a
                  href="https://sabasi.mobi/"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Sabasi
                </a>
              </p>
              <p className="ml-8">
                Last Updated:{" "}
                <span className="font-bold">{`${aboutAWeekAgo()}`}</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EducationCitizenGeneratedDataDetails;
