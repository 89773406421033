// import React from "react";
import React, { useContext } from "react";
//import Dashboard from "./views/Dashboard";
import { Helmet } from "react-helmet";
import Homepage from "./views/Homepage";
import Projects from "./views/Projects";
import Project from "./views/Project";
import Sectors from "./views/Sectors";
import Sector from "./views/Sector";
import Budgets from "./views/Budgets";
import Documents from "./views/Documents";
import About from "./views/About";
import SearchResults from "./views/SearchResults";
import { Routes, Route } from "react-router-dom";
import Layout from "./views/Layout";
import CountyContext from "./store/CountyContext";
import CitizenGeneratedData from "./views/CitizenGeneratedData";
import CitizenGeneratedDataDetails from "./views/CitizenGeneratedDataDetails";

const AppHeader = ({ countyName, favicon, children }) => (
  <>
    <Helmet>
      <title>{countyName}</title>
      <meta property="og:image" content={favicon} />

      <link rel="icon" href={favicon} />
      <link rel="apple-touch-icon" href={favicon} />
      <link rel="manifest" href={favicon} />
    </Helmet>
    <>{children}</>
  </>
);

export default function App() {
  const { logo, name } = useContext(CountyContext);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <AppHeader
              countyName={`${name} Open County`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <Homepage />
            </AppHeader>
          }
        />
        <Route
          path="/citizen-generated-data"
          element={
            <AppHeader
              countyName={`${name} Open County - CGD`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <CitizenGeneratedData />
            </AppHeader>
          }
        />
        <Route
          path="/citizen-generated-data/:id"
          element={
            <AppHeader
              countyName={`${name} Open County - CGD`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <CitizenGeneratedDataDetails />
            </AppHeader>
          }
        />
        <Route
          path="/sectors"
          element={
            <AppHeader
              countyName={`${name} Open County - Sectors`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <Sectors />
            </AppHeader>
          }
        />
        <Route
          path="/budgets"
          element={
            <AppHeader
              countyName={`${name} Open County - Budgets`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <Budgets />
            </AppHeader>
          }
        />
        <Route
          path="/projects"
          element={
            <AppHeader
              countyName={`${name} Open County - Projects`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <Projects />
            </AppHeader>
          }
        />
        <Route
          path="/projects/:id"
          element={
            <AppHeader
              countyName={`${name} Open County - Project`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <Project />
            </AppHeader>
          }
        />
        <Route
          path="/documents"
          element={
            <AppHeader
              countyName={`${name} Open County - Documents`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <Documents />
            </AppHeader>
          }
        />
        <Route
          path="/about"
          element={
            <AppHeader
              countyName={`${name} Open County - About`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <About />
            </AppHeader>
          }
        />
        <Route
          path="/sector/:id"
          element={
            <AppHeader
              countyName={`${name} Open County - Sector`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <Sector />
            </AppHeader>
          }
        />
        <Route
          path="/search"
          element={
            <AppHeader
              countyName={`${name} Open County - Search`}
              favicon={
                logo
                  ? logo
                  : "https://data.nandicounty.go.ke/static/media/nandi-county-logo.ba1266d586cee759ab3b.jpg"
              }
            >
              <SearchResults />
            </AppHeader>
          }
        />
      </Route>
    </Routes>
  );
}
