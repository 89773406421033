import React, { useState, useEffect } from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';

import { fShortenNumber } from '../../../util/formatNumber';
//
import BaseOptionChart from '../../../components/BaseOptionChart';

const getNumbers = (arry) => {
  return arry.map((arr) => arr.amount);
};

export default function RevenueGraph({ revenueData, periodSelected }) {
  const [years, setYears] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (revenueData) {
      const yrData = revenueData.map((revData) => revData.name);
      const dtRev = [{ name: 'Amount in KES', data: getNumbers(revenueData) }];
      setYears(yrData);
      setData(dtRev);
    }
  }, [revenueData]);

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      background: '#fff',
      toolbar: {
        show: true,
        offsetX: 15,
        offsetY: -10,
      }
    },
    yaxis: {
      forceNiceScale: true,
      title: {
        text: 'Amount in KES',
      },
      min: 0,
      labels: {
        formatter: function (val) {
          return fShortenNumber(val);
        },
      },
    },
    xaxis: {
      title: { text: '' },
      min: 0,
      categories: years,
      labels: {
        show: false,
      },
    },
    tooltip: {
      followCursor: true,
      theme: 'dark',
      x: {
        show: true,
      },
      y: {
        formatter(val) {
          return `${fShortenNumber(val)}`;
        },
      },
    },
    theme: {
      // mode: 'dark',
      palette: 'palette2',
      monochrome: {
        enabled: false,
        color: '#2E3BAE',
        shadeTo: 'light',
        shadeIntensity: 0.65,
      },
    },
    colors: ['#2E93fA', '#66DA26', '#E1AD01', '#000'],
    plotOptions: {
      bar: {
        columnWidth: '80%',
        borderRadius: 0,
        distributed: true,
        //horizontal: true,
      },
    },
    grid: {
      show: true,
    },
    noData: {
      text: 'No Data',
    },
    responsive: [
      {
        breakpoint: 767.98,
        options: {
          chart: {
            height: 500
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '80%',
            }
          },
          legend: {
            fontSize: '12px',
            position: 'bottom',
            show: true,
            itemMargin: {
                horizontal: 0,
                vertical: 5
            }                       
          },
          yaxis: {
            show: false
          }          
        }
      }
    ]     
  });

  return (
    <>
      <ReactApexChart
        type='bar'
        series={data}
        options={{
          ...chartOptions,
          dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            formatter: function (val, opt) {
              return fShortenNumber(val);
            },
            style: {
              colors: ['#fff', '#000', '#000', '#fff'],
            },
          },
        }}
      />
    </>
  );
}
