/* eslint-disable no-restricted-globals */
import { useEffect, useContext } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import { VerifiedUserOutlined } from "@mui/icons-material";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CasesOutlinedIcon from "@mui/icons-material/CasesOutlined";
import Info from "@mui/icons-material/InfoOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import Logo from "./../images/nandi-open-county-logo.png";

import CountyContext from "../store/CountyContext";

const link = process.env.REACT_APP_CMS_URL;

const Header = () => {
  // Collapse the navbar when the user clicks outside of it

  const { logo } = useContext(CountyContext);

  const closeNav = (e) => {
    if (
      e.target.id !== "responsive-navbar-nav" &&
      e.target.className !== "navbar-toggler-icon"
    ) {
      document.getElementById("responsive-navbar-nav").classList.remove("show");
      document
        .getElementsByClassName("navbar-toggler")[0]
        .classList.add("collapsed");
    }
  };

  useEffect(() => {
    window.addEventListener("click", closeNav);
    return () => {
      window.removeEventListener("click", closeNav);
    };
  }, []);

  return (
    <header className="header-desktop">
      <Navbar expand="lg" className="main-nav" collapseOnSelect>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo && logo.length > 0 ? logo : Logo}
              alt="County Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto ms-auto" as="ul">
              <Nav.Item as="li">
                <Nav.Link
                  eventKey="1"
                  as={NavLink}
                  to="/citizen-generated-data"
                >
                  <VerifiedUserOutlined className="menu-icon" />
                  CGD
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="2" as={NavLink} to="/sectors">
                  <BubbleChartOutlinedIcon className="menu-icon" />
                  Sectors
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="3" as={NavLink} to="/budgets">
                  <AccountBalanceOutlinedIcon className="menu-icon" />
                  Budgets
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="4" as={NavLink} to="/projects">
                  <CasesOutlinedIcon className="menu-icon" />
                  Projects
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="5" as={NavLink} to="/documents">
                  <ArticleOutlinedIcon className="menu-icon" />
                  Documents
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="6" as={NavLink} to="/about">
                  <Info className="menu-icon" />
                  About
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <a
                  className="main-nav navbar-nav nav-item nav-link"
                  href={link}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <LoginOutlinedIcon className="menu-icon" />
                  Sign In
                </a>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
