import React, { useState, useEffect } from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { fShortenNumber } from '../../../util/formatNumber';
//
import BaseOptionChart from '../../../components/BaseOptionChart';

// ----------------------------------------------------------------------

export default function RevenueChartPie({ revenueData, periodSelected }) {
  const [years, setYears] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (revenueData) {
      const yrData = revenueData.map((revData) => revData.name);
      const revNameData = revenueData.map((revData) => +revData.amount);
      setYears(yrData);
      setData(revNameData);
    }
  }, [revenueData]);

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      background: '#fff',
      toolbar: {
        show: true,
        offsetX: 15,
        offsetY: -10,
      }
    },
    labels: years,
    legend: {
      position: 'right',
      offsetX: -20,
      offsetY: 100,
      itemMargin: { vertical: 8 },
    },
    tooltip: {
      followCursor: false,
      x: {
        show: true,
      },
      marker: {
        show: true,
      },
      y: {
        formatter(val) {
          return `${fShortenNumber(val)}`;
        },
      },
    },
    colors: ['#2E93fA', '#66DA26', '#E1AD01', '#000'],
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      formatter: function (val, opt) {
        return fShortenNumber(val) + '%';
      },
    },
    stroke: { show: false },
    plotOptions: {
      pie: {
        offsetY: 20,
        donut: {
          size: '65%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
              fontWeight: 600,
              offsetY: -5,
            },
            value: {
              show: true,
              fontSize: '18px',
              fontWeight: 600,
              textTransform: 'uppercase',
              offsetY: 0,
              formatter(val) {
                return `${fShortenNumber(val)}`;
              },
            },
            total: {
              formatter: function (w) {
                const totalRevenue = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
                return fShortenNumber(totalRevenue);
              },
              label: 'Total Revenue',
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 767.98,
        options: {
          chart: {
            height: 500
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,            
          }          
        }
      }
    ]      
  });

  return (
    <>
      {/*<Typography variant="h6">{`Revenue Data ${periodSelected}`}</Typography>*/}
      <ReactApexChart
        height='400px'
        type='donut'
        series={data}
        options={chartOptions}
      />
    </>
  );
}
