import React from 'react'
import {Link} from 'react-router-dom'
import {Container} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons'

const getYear = new Date().getFullYear()

const Footer = () => {

    return <footer>
            <Container>
                <div className="footer-inner">
                    <div className="colophon">
                        <p>Copyright © {getYear} · Powered by <a href="https://openinstitute.africa" target="_blank"
                                                                 rel="nofollow noreferrer"
                                                                 title="Visit Open Institute on the web">Open
                            Institute<FontAwesomeIcon icon={faArrowUpRightFromSquare}/></a>
                        </p>
                    </div>
                    <div className="footer-links">
                        <ul>
                            <li><a href={`${process.env.REACT_APP_BASEDEVURL}/download`} target="_blank" rel="nofollow noreferrer">Download
                                Indicator Template</a></li>
                            <li><Link to="/privacy">Privacy</Link></li>
                            <li><Link to="/">Disclaimer</Link></li>
                        </ul>
                    </div>
                </div>
            </Container>
        </footer>
}

export default Footer;