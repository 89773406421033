import React from "react";
// material
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
} from "@mui/material";
import { fShortenNumber } from "../util/formatNumber";
import Export from "./ExportIndicators";
import Paper from "@mui/material/Paper";
import OldIndicatorTable from "./OldIndicatorTable";

function organizeDataWithGroup(data) {
  const organized = {};
  const years = new Set();
  const columns = new Set();
  let hasConstituency = false;

  data.forEach((item) => {
    let identifier;
    if (item.constituency) {
      hasConstituency = true;
      identifier = item.constituency;
      if (!organized[identifier]) {
        organized[identifier] = {};
      }
      if (!organized[identifier][item.group]) {
        organized[identifier][item.group] = {};
      }
      if (!organized[identifier][item.group][item.period]) {
        organized[identifier][item.group][item.period] = {};
      }
      organized[identifier][item.group][item.period][item.key] = item.value;
      columns.add(item.key);
    } else {
      identifier = `${item.key} (${item.group})`;
      if (!organized[identifier]) {
        organized[identifier] = {};
      }
      if (!organized[identifier][item.period]) {
        organized[identifier][item.period] = {};
      }
      organized[identifier][item.period][item.key] = item.value; // Ensuring correct assignment
    }
    years.add(item.period);
  });

  return {
    organizedData: organized,
    years: Array.from(years).sort(),
    columns: Array.from(columns).sort(),
    hasConstituency,
  };
}

function organizeData(data) {
  const organized = {};
  const years = new Set();
  const columns = new Set(); // This will hold all possible keys
  let hasConstituency = false;
  let hasGroup = false;

  data.forEach((item) => {
    // Detect if any data item has a constituency
    if (item.constituency) {
      hasConstituency = true;
    }

    if (item.group) {
      hasGroup = true;
    }

    const key = item.constituency || item.key; // Use key as a fallback if no constituency

    if (!organized[key]) {
      organized[key] = {};
    }

    if (!organized[key][item.period]) {
      organized[key][item.period] = {};
    }

    if (item.constituency) {
      organized[key][item.period][item.key] = item.value;
      columns.add(item.key); // Add the key to the columns set
    } else {
      organized[key][item.period] = item.value; // Directly use value if no constituency
    }

    years.add(item.period);
  });

  return {
    organizedData: organized,
    years: Array.from(years).sort(),
    columns: Array.from(columns).sort(), // Ensure columns are only used if constituencies are detected
    hasConstituency,
    hasGroup,
  };
}

export default function IndicatorTable({
  isLoading,
  display,
  selectedItemIndicator,
  data,
}) {
  const { organizedData, years, columns, hasConstituency, hasGroup } =
    organizeData(data);
  const {
    organizedData: orgData,
    years: yrs,
    columns: cols,
  } = organizeDataWithGroup(data);

  return isLoading ? (
    <CircularProgress color="success" />
  ) : (
    <>
      <div className="table-toolbar">
        <Export
          indicatorData={display}
          selectedItemIndicator={selectedItemIndicator}
          data={data}
          orgData={orgData}
          yrs={yrs}
          cols={cols}
          organizedData={organizedData}
          years={years}
          columns={columns}
          hasConstituency={hasConstituency}
          hasGroup={hasGroup}
        />
      </div>
      {!hasConstituency && hasGroup ? (
        <OldIndicatorTable
          display={display}
          isLoading={isLoading}
          selectedItemIndicator={selectedItemIndicator}
        />
      ) : hasGroup ? (
        <>
          <TableContainer
            component={Paper}
            sx={{ minWidth: 800, maxHeight: 510 }}
          >
            <Table
              stickyHeader
              size="small"
              aria-label="indicators"
              className="data-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "#108214",
                      color: "#fff",
                      position: "sticky",
                      left: 0,
                    }}
                    sx={{ border: 1, borderColor: "#efefef" }}
                  >
                    {hasConstituency ? "Sub County" : "Entry"}
                  </TableCell>
                  {yrs.map((year) => (
                    <TableCell
                      key={year}
                      align="center"
                      colSpan={cols.length}
                      style={{ backgroundColor: "#3c3c3c", color: "#fff" }}
                      sx={{ border: 1, borderColor: "#efefef" }}
                    >
                      {year}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell />
                  {yrs.map((year) =>
                    cols.map((column) => (
                      <TableCell key={`${year}-${column}`}>{column}</TableCell>
                    ))
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(orgData).map(([constituency, groupData]) =>
                  Object.entries(groupData).map(([group, yearData]) => (
                    <TableRow key={`${constituency}-${group}`}>
                      <TableCell
                        style={{
                          position: "sticky",
                          left: 0,
                          background: "white",
                          boxShadow: "0px 4px 14px 1px rgba(0,0,0,0.37)",
                          borderRight: "1px solid black",
                        }}
                      >
                        {`${constituency} (`}
                        <span style={{ fontWeight: "bold", fontSize: "small" }}>
                          {group}
                        </span>
                        {`)`}
                      </TableCell>
                      {yrs.map((year) =>
                        cols.map((column) => (
                          <TableCell
                            key={`${constituency}-${group}-${year}-${column}`}
                            align="center"
                          >
                            {yearData[year] && yearData[year][column]
                              ? fShortenNumber(yearData[year][column])
                              : "0"}
                          </TableCell>
                        ))
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ minWidth: 800, maxHeight: 510 }}
        >
          <Table
            stickyHeader
            size="small"
            aria-label="indicators"
            className="data-table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "#108214",
                    color: "#fff",
                    position: "sticky",
                    left: 0,
                  }}
                  sx={{ border: 1, borderColor: "#efefef" }}
                >
                  {hasConstituency ? "Sub County" : "Entry"}
                </TableCell>
                {years.map((year) => (
                  <TableCell
                    key={year}
                    colSpan={hasConstituency ? columns.length : 1}
                    align="center"
                    style={{ backgroundColor: "#3c3c3c", color: "#fff" }}
                    sx={{ border: 1, borderColor: "#efefef" }}
                  >
                    {year}
                  </TableCell>
                ))}
              </TableRow>
              {hasConstituency && (
                <TableRow>
                  <TableCell></TableCell>{" "}
                  {/* Empty cell for the constituency label */}
                  {years.map((year) =>
                    columns.map((column) => (
                      <TableCell key={`${year}-${column}`} align="center">
                        {column}
                      </TableCell>
                    ))
                  )}
                </TableRow>
              )}
            </TableHead>

            <TableBody>
              {Object.entries(organizedData).map(([identifier, yearData]) => (
                <TableRow key={identifier}>
                  <TableCell
                    style={{
                      position: "sticky",
                      left: 0,
                      background: "white",
                      boxShadow: "0px 4px 14px 1px rgba(0,0,0,0.37)",
                      borderRight: "1px solid black",
                    }}
                  >
                    {identifier}
                  </TableCell>
                  {years.map((year) =>
                    hasConstituency ? (
                      columns.map((column) => (
                        <TableCell
                          key={`${year}-${column}-${identifier}`}
                          align="center"
                        >
                          {yearData[year] && yearData[year][column]
                            ? fShortenNumber(yearData[year][column])
                            : "0"}
                        </TableCell>
                      ))
                    ) : (
                      <TableCell key={`${year}-${identifier}`} align="center">
                        {fShortenNumber(yearData[year]) || "0"}{" "}
                        {/* Display the year data directly if no columns are specified */}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
// function extractUnitsofMeasure(unitsOfMeasure, set) {
//   var f = [...unitsOfMeasure].find((a) => a.includes(set));
//   return f ? f.split(" - ")[1] : "";
// }
