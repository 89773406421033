import { merge } from "lodash";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { fShortenNumber } from "../../util/formatNumber";

import BaseOptionChart from "../BaseOptionChart";

function SectorSummaryColumnChart({ dataset, colors, yaxisLabel, xAxisLabel,categories }) {

  const columnChartOptions = merge(BaseOptionChart(), {
    legend: {
      show: false,
    },
    chart: {
      height: 350,
      type: "bar",
    },
    title: {
      show: false,
    },
    // RGB to be used in the colors array below 62,138,139
    colors: colors,

    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      formatter: function (val, opt) {
        return fShortenNumber(val);
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: xAxisLabel,
      },
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      enabled: true,
      offsetY: 0,
      x: {
        show: false,
      },
      y: {
        formatter: (value) => fShortenNumber(value),
      },
      marker: {
        show: false,
      },
      // custom: ({ series, seriesIndex, dataPointIndex }) => {
      //   const category = finalData[dataPointIndex].category;
      //   const value = finalData[dataPointIndex].value;
      //   return `<div className="apexcharts-tooltip-custom p-2 bold">
      //             ${category}: ${fShortenNumber(value)}
      //           </div>`;
      // },
    },
    yaxis: {
      title: {
        text: yaxisLabel,
      },
      labels: {
        formatter: function (val) {
          return fShortenNumber(val);
        },
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
        borderRadius: 0,
      },
    },
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={columnChartOptions}
        series={[{
          name: "",
          data: dataset
        }]}
        type="bar"
        height={500}
      />
    </div>
  );
}

export default SectorSummaryColumnChart;
