import { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import useHttp from "../hooks/useHttp";
import { School } from "@mui/icons-material";

export const getHealthData = (fetchedHealthData) => {
  if (fetchedHealthData) {
    return fetchedHealthData;
  }
  return {
    beds: "",
    cadres: "",
    created_at: "",
    deleted_at: "",
    departments: "",
    email: "",
    facility: "",
    gps: "",
    id: 0,
    infrastructure: "",
    nature: "",
    open_hours: "",
    owner: "",
    phone: "",
    population: "",
    respondent: "",
    respondent_position: "",
    sector: "",
    status: "",
    type: "",
    updated_at: "",
    utilities: "",
    ward: "",
  };
};

const EducationPopUp = ({ educationCode, facility }) => {
  const { isLoading: isLoadingEducationData, sendRequest } = useHttp();
  const [fetchedEducationData, setFetchedEducationData] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const res = await sendRequest(
        `https://api.sabasi.mobi/api/nandi/data/${educationCode}`
      );
      if (res && res.data) {
        setFetchedEducationData(res.data);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return isLoadingEducationData ? (
    <Box sx={{ display: "flex" }}>
      <CircularProgress color="success" />
    </Box>
  ) : (
    <div className="flex flex-col p-2 bg-white">
      <div className="flex flex-row space-x-4 -mt-2 my-3">
        <School sx={{ color: "green", fontSize: 35 }} />
        <div>
          <h3 className="text-base">{facility}</h3>
        </div>
      </div>
      <div className="-mt-3 mb-2">
        <hr className="h-px border-gray-900 border-0"></hr>
      </div>
      <div className="flex flex-row justify-between">
        <div className="mr-8 text-sm">
          <p>{`Infrastructure Quality: ${
            getHealthData(fetchedEducationData).infrastructure
          }`}</p>
          <p className="text-blue-800">Click pin for more details </p>
        </div>
      </div>
    </div>
  );
};

export default EducationPopUp;
