import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import BreadcrumbSector from "../ui-components/BreadcrumbSector";

import useFetchSectors from "../hooks/useFetchSectors";
import useHttp from "../hooks/useHttp";

import IndicatorDetails from "../components/IndicatorDetails";
import axios from "axios";

export default function Sector() {
  const { id } = useParams();
  const [foundSector, setFoundSector] = useState();
  //const [foundIndicators, setFoundIndicators] = useState([]);
  const { sectors } = useFetchSectors();
  const [filterIndicators, setFilterIndicators] = useState([]);
  const [indicatorInfo, setIndicatorInfo] = useState([]);
  const [dataOnCharts, setDataOnCharts] = useState([]);
  const [foundIndicator, setFoundIndicator] = useState();
  const [displayData, setDisplayData] = useState({});
  const [initialIndicatorData, setInitialIndicatorData] = useState({});
  const [sectorColor, setSectorColor] = useState("#008000");
  const [isLoading, setIsLoading] = useState(false);
  const { sendRequest } = useHttp();

  useEffect(() => {
    if (sectors.length > 0) {
      const foundSct = sectors.filter((sector) => sector.id === +id);
      if (foundSct.length > 0) {
        setFoundSector(foundSct);
        if (foundSct.length > 0 && foundSct[0].color) {
          setSectorColor(foundSct[0].color);
        }
        //setFoundIndicators(foundSct[0].indicators);
        axios
          .get(`${process.env.REACT_APP_BASEDEVURL}/api/sectors/${id}`)
          .then((res) => {
            if (res.data && res.data.data) {
              const tdFilters = res.data.data.indicators.map((indicator) => ({
                name: indicator.name,
                value: indicator.index,
              }));
              setFilterIndicators(tdFilters);
            }
          });
      }
    }
  }, [id, sectors]);

  useEffect(() => {
    const handleIndicatorItems = async () => {
      try {
        if (sectors.length > 0) {
          const foundSct = sectors.filter((sector) => sector.id === +id);
          if (foundSct.length > 0) {
            setFoundSector(foundSct);
            //setFoundIndicators(foundSct[0].indicators);

            const { data } = await axios.get(
              `${process.env.REACT_APP_BASEDEVURL}/api/sectors/${id}`
            );

            if (data && data.data) {
              const firstIndicator = data.data.indicators[0].index;
              sendRequest(
                `${process.env.REACT_APP_BASEDEVURL}/api/indicator/${firstIndicator}`
              ).then((resData) => {
                if (resData && resData.data) {
                  if (resData.data && resData.data.stats) {
                    setDataOnCharts(resData.data.stats);
                  }
                  const display = {};
                  display.data = resData.data.data;
                  display.metadata = resData.data.metadata;
                  setInitialIndicatorData(display);
                }
              });
            }
          }
        }
      } catch (error) {}
    };
    handleIndicatorItems();
  }, [id, sectors, sendRequest]);

  const handleIndicator = useCallback(
    async (selectedKey) => {
      try {
        setIsLoading(true);
        const resData = await sendRequest(
          `${process.env.REACT_APP_BASEDEVURL}/api/indicator/${selectedKey}`
        );
        if (resData && resData.data) {
          if (resData.data && resData.data.stats) {
            setDataOnCharts(resData.data.stats);
          }
          const display = {};
          display.data = resData.data.data;
          display.metadata = resData.data.metadata;
          setFoundIndicator({
            name: resData.data.indicator ? resData.data.indicator.name : "",
            description: resData.data.indicator
              ? resData.data.indicator.description
              : "",
            createdAt: resData.data.indicator
              ? resData.data.indicator.created_at
              : "",
            updatedAt: resData.data.indicator
              ? resData.data.indicator.updated_at
              : "",
            source: resData.data.indicator ? resData.data.indicator.source : "",
          });
          setDisplayData(display);
          setIndicatorInfo(resData.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
    [sendRequest]
  );

  return (
    <>
      <BreadcrumbSector title={foundSector ? foundSector[0].name : ""} />
      <div className="wrapper">
        <IndicatorDetails
          isLoading={isLoading}
          indicatorInfo={indicatorInfo}
          itemIndicatorOptions={filterIndicators}
          dataOnCharts={dataOnCharts}
          display={displayData}
          foundIndicator={foundIndicator}
          initialIndicatorData={initialIndicatorData}
          handleSearchedIndicator={handleIndicator}
          handleFilterIndicator={handleIndicator}
          sectorId={id}
          sectorColor={sectorColor}
          foundSector={
            foundSector && foundSector[0] ? foundSector[0] : undefined
          }
        />
      </div>
    </>
  );
}
