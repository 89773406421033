import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
//
import BaseOptionChart from './BaseOptionChart';

import React from 'react';

// ----------------------------------------------------------------------

export default function ProjectReportDonut({ labels, chartData, colorMap }) {
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { show: false },
    plotOptions: {
      bar: {
        barHeight: '80%',
        barWidth: '100%',
        borderRadius: 0,
        distributed: true,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: labels,
      title: {
        text: 'Number of Projects',
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title',
        },
    }
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        maxWidth: 200,
        style: {
          cssClass: 'oc-charts-yaxis-label'
        },
      }
    },
    tooltip: { 
      followCursor: true,
      x: { 
        show: true 
      }, 
      marker: { 
        show: true 
      },
      items: {
        display: 'flex',
      }
    },
    legend: {
      show: false,
    },
    chart: {
      background: '#fff',
      toolbar: {
        show: true,
        offsetX: 15,
        offsetY: -35
      }
    },
    colors: colorMap,
    responsive: [
      {
        breakpoint: 767.98,
        options: {
          chart: {
            height: 500
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '80%',
            }
          },
          legend: {
            horizontalAlign: 'left',
            fontSize: '12px',
            position: 'bottom',
            show: true,
            offsetX: 0,
            offsetY: 0,      
            itemMargin: {
                horizontal: 0,
                vertical: 5
            }               
          },
          yaxis: {
            show: false
          }          
        }
      }
    ]    
    
  });

  return (
    <ReactApexChart
      type='bar'
      series={chartData}
      options={chartOptions}
      height="450"
      width="100%"
    />
  );
}
