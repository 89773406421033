import { merge } from "lodash";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { fShortenNumber } from "../../util/formatNumber";

import BaseOptionChart from "../BaseOptionChart";

const convertToTreeMap = (keys, values) => {
  if (keys.length !== values.length) return [];
  return keys.map((key, idx) => ({ x: key, y: values[idx] }));
};

function SectorSummaryLineChart({
  dataset,
  colors,
  yaxisLabel,
  xAxisLabel,
  categories,
  nameOfSeries,
  type,
  currentCard,
  cardSixDataSet,
}) {
  const lineChartOptions = merge(BaseOptionChart(), {
    chart: {
      height: 350,
      type: type,
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        color: "#ffff",
      },
      formatter: function (val, op) {
        return type === "treemap"
          ? [val, fShortenNumber(op.value)]
          : fShortenNumber(val);
      },
      offsetY: -4,
    },
    formatter: function (val, op) {
      return type === "treemap"
        ? [val, fShortenNumber(op.value)]
        : fShortenNumber(val);
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      show: false,
    },
    grid: {
      borderColor: "#e7e7e7",
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: categories,
      title: {
        text: xAxisLabel,
      },
    },
    yaxis: {
      title: {
        text: yaxisLabel,
      },
      labels: {
        formatter: function (val) {
          return fShortenNumber(val);
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -3,
      offsetX: -5,
    },
  });

  return (
    <div id='chart'>
      {currentCard === "card_six" ? (
        <ReactApexChart
          options={lineChartOptions}
          series={cardSixDataSet}
          type={type}
          height={450}
        />
      ) : (
        <ReactApexChart
          options={lineChartOptions}
          series={[
            {
              name: nameOfSeries,
              data:
                type === "treemap"
                  ? convertToTreeMap(categories, dataset)
                  : dataset,
            },
          ]}
          type={type}
          height={450}
        />
      )}
    </div>
  );
}
export default SectorSummaryLineChart;
