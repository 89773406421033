import { replace } from "lodash";
import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? "0,0" : "0,0.00");
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  const num = Math.floor(number);
  if (num.toString().length > 3 && num.toString().length < 7)
    return fNumber(number);
  const shortened = replace(numeral(number).format("0.00a"), ".00", "");
  return shortened === "0" ? "0" : shortened;
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}
