import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { styled } from '@mui/material/styles';

import useHttp from '../hooks/useHttp';
import ProjectReportChart from './ProjectReportChart';
import MapProjects from '../components/map/MapProjects';

const MapWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 0,
  height: 400,
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  // '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
  //   display: 'none'
  // }
}));

const ProjectReports = ({projectsBySubCounty}) => {
  const [statusData, setStatusData] = useState();
  const [paymentStatusData, setPaymentStatusData] = useState();
  const [projectsDepartments, setProjectsDepartments] = useState();
  const [projectNumber, setProjectsNumber] = useState(null);
  // const [indicators, setIndicators] = useState([]);
  const { sendRequest } = useHttp();
  const [colorMap, setColors] = useState([])
  // const [colorMap, setDepColors] = useState([])

  const fetchDepartmentProjects = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects/departments`
      );
      const summaryItem = { labels: [], data: [] };
      const resProjects = [];
      if (response && response.data) {
        for (const dt in response.data) {
          resProjects.push({
            id: response.data[dt].id,
            name: response.data[dt].name,
            projects_count: response.data[dt].projects_count,
          });
        }
        if (resProjects.length > 0) {
          for (const department of resProjects) {
            summaryItem.labels.push(department.name);
            summaryItem.data.push(department.projects_count);
          }
          setProjectsDepartments(summaryItem);
        }
      }
    } catch (error) {}
  }, [sendRequest]);
  useEffect(() => {
    fetchDepartmentProjects();
  }, [fetchDepartmentProjects]);

  const fetchProjectNumber = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects/constituencies`
      );
      if (response && response.data) {
        const sortedResponse = response.data.sort((a, b) => {
         return  a.name<b.name ? -1 : a.name>b.name? 1:0;
        })

      
        const summaryItem = {
          labels: sortedResponse.map((a) => a.name),
          data: sortedResponse.map((a) => a.count),
        };

        setProjectsNumber(summaryItem);
      }
    } catch (error) {}
  }, [sendRequest]);
  useEffect(() => {
    fetchProjectNumber();
  }, [fetchProjectNumber]);

  const fetchStatus = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects/summaries/status`
      );
      const summaryItem = { labels: [], data: [] };
      if (response && response.summaries) {
        for (const summary of response.summaries) {
          summaryItem.labels.push(summary.status);
          summaryItem.data.push(summary.count);
        }
        setStatusData(summaryItem);
      }
    } catch (error) {}
  }, [sendRequest]);
  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  const fetchPaymentStatus = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects/summaries/payment_status`
      );
      const summaryItem = { labels: [], data: [] };
      if (response && response.summaries) {
        for (const summary of response.summaries) {
          summaryItem.labels.push(summary.payment_status);
          summaryItem.data.push(summary.count);
        }
        setPaymentStatusData(summaryItem);
      }
    } catch (error) {}
  }, [sendRequest]);
  useEffect(() => {
    fetchPaymentStatus();
  }, [fetchPaymentStatus]);



  const fetchSubCountyColors = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/boundary`
      );
      if (res && res.data) {
       setColors(res.data.map(a => a.color))   
      }
    } catch (error) {
    }
  },
  [sendRequest]
  );
  useEffect(() => {
  fetchSubCountyColors();
  }, [fetchSubCountyColors]);

  return (
    <>
      <MapWrapperStyle className="mb-4">
        <MapProjects projectsBySubCounty={projectNumber} />
      </MapWrapperStyle>
      <Container>
      <div className="content-section">
        <Row>
          <Col lg="6" className="mb-4">
        <div className='oc-card mb-4'>
          <h6>Number of Projects by Department</h6>
          <ProjectReportChart
            chartData={
              projectsDepartments
                ? [{ name: 'Projects Count', data: projectsDepartments.data }]
                : []
            }
            labels={projectsDepartments ? projectsDepartments.labels : []}
          />
        </div>
        </Col>
        <Col lg="6" className="mb-4">
        <div className='oc-card mb-4'>
          <h6>Number of Projects by Sub-county</h6>
          <ProjectReportChart
            chartData={
              projectNumber
                ? [{ name: 'Projects Count', data: projectNumber.data }]
                : []
            }
            labels={projectNumber ? projectNumber.labels : []}
            colorMap={colorMap}
          />
        </div>
        </Col>
        <Col lg="6" className="mb-4">
        <div className='oc-card mb-4'>
          <h6>Number of Projects by Status</h6>
          <ProjectReportChart
            chartData={
              statusData ? [{ name: 'Projects', data: statusData.data }] : []
            }
            labels={statusData ? statusData.labels : []}
          />
        </div>
        </Col>
        <Col lg="6" className="mb-4">
        <div className='oc-card'>
          <h6>Number of Projects by Payment Status</h6>
          <ProjectReportChart
            chartData={
              paymentStatusData
                ? [{ name: 'Projects', data: paymentStatusData.data }]
                : []
            }
            labels={paymentStatusData ? paymentStatusData.labels : []}
          />
        </div>
        </Col>
        </Row>
      </div>
      </Container>
    </>
  );
};

export default ProjectReports;
