import React from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Legend from "./Legend";
//import useFetchGeoJson from "../../hooks/useFetchGeoJson";

import geojson from "../../assets/nandi-subcounties.geojson.json";

const center = [0.1836, 35.1269];

function countyStyle(feature, projectsBySubCounty) {
  const subCountyProjectIndex = projectsBySubCounty != null ? projectsBySubCounty.labels.indexOf(feature.properties.ADM2_EN) : -1;
  const d = subCountyProjectIndex > -1 ? projectsBySubCounty.data[subCountyProjectIndex] : 0;
  return {
  fillColor: getColor(d),
  weight: 2,
  opacity: 1,
  color: "white",
  fillOpacity: 1,}
};

function getColor(d) {
  return d >= 200 ? '#800026' :
         d >= 190  ? '#BD0026' :
         d >= 180  ? '#E31A1C' :
         d >= 170  ? '#FC4E2A' :
         d >= 160   ? '#FD8D3C' :
         d >= 150   ? '#FEB24C' :
         d >= 140   ? '#FED976' :
                    '#FFEDA0';
}

function getVoivodeshipName(feature, layer, projectsBySubCounty) {
  if (feature.properties && feature.properties.ADM2_EN) {
    const subCountyProjectIndex = projectsBySubCounty != null ? projectsBySubCounty.labels.indexOf(feature.properties.ADM2_EN) : -1;
  const d = subCountyProjectIndex > -1 ? projectsBySubCounty.data[subCountyProjectIndex] : 0;
    layer.bindPopup(
      ` <h3><b>${feature.properties.ADM2_EN}</b></h3> <p>${d} Projects</p> `
    );
  }
}

function onEachFeature(feature, layer, projectsBySubCounty) {

  layer.on("mouseover", function (e) {
    getVoivodeshipName(feature, layer, projectsBySubCounty);

    this.openPopup();

    // style
    this.setStyle({
      fillColor: "#3388ff",
      weight: 2,
      color: "#3388ff",
      fillOpacity: 1,
    });
  });
  layer.on("mouseout", function () {
    this.closePopup();
    // style
    this.setStyle(countyStyle(feature, projectsBySubCounty));
  });
}

export default function MapProjects({ projectsBySubCounty }) {
  //const { geojson } = useFetchGeoJson("https://raw.githubusercontent.com/Mondieki/kenya-counties-subcounties/master/geojson/nandi.json")

  return (
    <MapContainer
      center={center}
      zoom={9}
      scrollWheelZoom={false}
      style={{ width: "100%", height: "60vh" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <GeoJSON
        key = {projectsBySubCounty}
        data={geojson}
        onEachFeature={(feature, layer) => onEachFeature(feature, layer, projectsBySubCounty )}
        style={(feature) => countyStyle(feature, projectsBySubCounty)}
      />

      <Legend/>

    </MapContainer>
  );
};

