import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Autocomplete,
  TextField,
  Chip,
  Radio,
  Box,
  CircularProgress,
} from "@mui/material";
import { fShortenNumber } from "../../util/formatNumber";

function Chart4({ apiResponse, isLoading, type }) {
  const [filter1categories, setFilter1Categories] = useState([]);
  const [filter1, setFilter1] = useState({ id: "", name: "" });
  const [selectedDrillDown, setSelectedDrillDown] = useState("b");

  const handleDrillDownChange = (event) => {
    setSelectedDrillDown(event.target.value);
    setCategories(
      apiResponse,
      setFilter1Categories,
      setFilter1,
      event.target.value
    );
  };

  const yAxisLabels = new Set();

  const series = plotSeries(
    apiResponse,
    filter1,
    selectedDrillDown,
    yAxisLabels
  );

  useEffect(() => {
    setCategories(
      apiResponse,
      setFilter1Categories,
      setFilter1,
      selectedDrillDown
    );
  }, [apiResponse]);

  return (
    <>
      {isLoading ? (
        <CircularProgress sx={{ color: "green" }} />
      ) : (
        <div id="chart">
          <div className="mb-3">
            <Radio
              checked={selectedDrillDown === "b"}
              onChange={handleDrillDownChange}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
            />
            <span>Visual 1</span>
            <Radio
              checked={selectedDrillDown === "a"}
              onChange={handleDrillDownChange}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <span>Visual 2</span>
          </div>
          <Box
            sx={{
              mb: 3,
              display: "flex",
              flexDirection: { md: "row", xs: "column", sm: "column" },
              justifyContent: "space-between",
            }}
          >
            <Autocomplete
              disableClearable={true}
              options={filter1categories}
              fullWidth
              value={filter1}
              defaultValue={filter1}
              onChange={(event, value) => setFilter1(value)}
              sx={{
                ml: { md: 0, sm: 0 },
                mr: { md: 3, sm: 0 },
                mt: { md: 0, sm: 2, xs: 2 },
              }}
              getOptionLabel={(option) => option.name}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option.name}
                    size="small"
                    label={option.name}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Filter 1" />
              )}
            />
          </Box>

          <ReactApexChart
            options={options(type, apiResponse, yAxisLabels)}
            series={series}
            type={type}
            height={350}
          />
        </div>
      )}
    </>
  );
}

export default Chart4;

function options(type, apiResponse, yAxisLabels) {
  return {
    chart: {
      type: type,
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      // colors: ["transparent"],
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: apiResponse.metadata.periods,
    },
    yaxis: {
      title: {
        text: [...yAxisLabels].toString().replace(/,/g, "___"),
      },
      labels: {
        formatter: function (val) {
          return fShortenNumber(val);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${fShortenNumber(val)}`;
        },
      },
    },
  };
}

function plotSeries(apiResponse, filter1, selectedDrillDown, yAxisLabels) {
  if (selectedDrillDown === "a") {
    const data = seriesDataA(apiResponse, filter1, yAxisLabels);

    return [...new Set(apiResponse.data.map((datum) => datum.key))].map(
      (key) => ({
        name: key,
        data: data(key),
      })
    );
  } else if (selectedDrillDown === "b") {
    const data = seriesDataB(apiResponse, filter1, yAxisLabels);

    return [...new Set(apiResponse.data.map((datum) => datum.group))].map(
      (group) => ({
        name: group,
        data: data(group),
      })
    );
  }
}

function seriesDataB(apiResponse, filter1, yAxisLabels) {
  return (group) =>
    apiResponse.data
      .filter((a) => a.group === group && a.key === filter1.id)
      .map(function (b) {
        yAxisLabels.add(b.value_type);
        return b.value;
      });
}

function seriesDataA(apiResponse, filter1, yAxisLabels) {
  return (key) =>
    apiResponse.data
      .filter((a) => a.key === key && a.group === filter1.id)
      .map(function (b) {
        yAxisLabels.add(b.value_type);
        return b.value;
      });
}

function setCategories(
  apiResponse,
  setFilter1Categories,
  setFilter1,
  selectedDrillDown
) {
  const categories = [
    ...new Set(
      apiResponse.data.map((datum) =>
        selectedDrillDown === "a" ? datum.group : datum.key
      )
    ),
  ];

  const categoriesFinal = categories.map((category) => {
    return {
      id: category,
      name: category,
    };
  });

  setFilter1Categories(categoriesFinal);
  setFilter1(categoriesFinal[0]);
}
