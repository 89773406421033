import React from "react";
import { useState, useEffect, useCallback, useContext } from "react";
import useHttp from "../hooks/useHttp";
import { fNumber } from "../util/formatNumber";
import Breadcrumb from "../ui-components/Breadcrumb";
import Leader from "../ui-components/Leader";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faAddressCard,
  faGlobe,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faYoutube,
  // faRss,
} from "@fortawesome/free-brands-svg-icons";
import CountyLogo from "../images/nandi-county-logo.jpg";
import Area from "../images/icons/area.png";
import Population from "../images/icons/population.png";
import Woman from "../images/icons/woman.png";
import Man from "../images/icons/man.png";
import CountyContext from "../store/CountyContext";

const desiredOrder = ["governor", "deputy_governor", "senator", "women_rep"];
export const sortedLeaders = (data) =>
  data.sort(
    (a, b) => desiredOrder.indexOf(a.key) - desiredOrder.indexOf(b.key)
  );

const COUNTY_POSITION = {
  governor: "Governor",
  deputy_governor: "Deputy Governor",
  senator: "Senator",
  women_rep: "Women Rep",
};

export default function About() {
  const [boundaryData, setBoundaryData] = useState([]);
  const {
    name,
    description,
    code,
    administrative_hq,
    area,
    leaders,
    population,
    logo,
    contacts,
  } = useContext(CountyContext);

  const [countyDocs, setCountyDocs] = useState([]);
  let aboutCountyDocs =
    countyDocs && countyDocs.length > 0 ? countyDocs.slice(0, 4) : [];

  const { sendRequest } = useHttp();

  const fetchCountyDocs = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/documents`
      );
      if (response && response.data) {
        setCountyDocs(response.data);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchCountyDocs();
  }, [fetchCountyDocs]);

  useEffect(() => {
    const fetchBoundaryData = async () => {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/boundary`
      );
      if (response && response.data) {
        setBoundaryData(response.data);
      }
    };
    fetchBoundaryData();
  }, [sendRequest]);

  return (
    <>
      <Breadcrumb title="About"></Breadcrumb>
      <div className="wrapper">
        <div className="content-section">
          <Container>
            <h1 className="styled-heading styled-heading-large">
              General Information
            </h1>

            <div className="oc-card mb-4">
              <div className="oc-card-split">
                <div className="county-logo">
                  <img
                    loading="lazy"
                    src={logo && logo.length > 0 ? logo : CountyLogo}
                    alt="County County Logo"
                    height="140"
                    width="140"
                  />
                </div>
                <div className="county-info">
                  <h4 className="oc-card-small-heading">{`About ${name}`}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: description
                        ? description
                        : "No information found",
                    }}
                  />
                </div>
              </div>
            </div>

            <Row className="mb-4">
              <Col lg="6">
                <Row className="mb-4">
                  <Col lg="6">
                    <div className="oc-card">
                      <span className="stat-title-small">County Code</span>
                      <span className="stat-medium">{code ? code : ""}</span>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="oc-card">
                      <span className="stat-title-small">
                        Administrative HQ
                      </span>
                      <span className="stat-medium">
                        {administrative_hq && administrative_hq.length > 0
                          ? administrative_hq
                          : "-"}
                      </span>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <div className="oc-card">
                      <div className="icon-grid">
                        <div className="icon-grid-item">
                          <img src={Area} alt="Area" width="50" height="50" />
                          <h6 className="icon-grid-item-title">Area (SQ KM)</h6>
                          <p className="icon-grid-item-number">
                            {area ? area : ""}
                            {/* 2855.8 */}
                          </p>
                        </div>
                        <div className="icon-grid-item">
                          <img
                            src={Population}
                            alt="Population"
                            width="50"
                            height="50"
                          />
                          <h6 className="icon-grid-item-title">
                            Total Population
                          </h6>
                          <p className="icon-grid-item-number">
                            {population && population.length > 0
                              ? fNumber(population[0].total)
                              : "-"}
                          </p>
                        </div>
                        <div className="icon-grid-item">
                          <img src={Man} alt="Man" width="50" height="50" />
                          <h6 className="icon-grid-item-title">
                            Male Population
                          </h6>
                          <p className="icon-grid-item-number">-</p>
                        </div>
                        <div className="icon-grid-item">
                          <img src={Woman} alt="Woman" width="50" height="50" />
                          <h6 className="icon-grid-item-title">
                            Female Population
                          </h6>
                          <p className="icon-grid-item-number">-</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <div className="oc-card">
                  <h4 className="oc-card-small-heading">Sub-counties</h4>
                  <Accordion defaultActiveKey={0} flush>
                    {boundaryData && boundaryData.length > 0
                      ? boundaryData.map((subcounty, index) => (
                          <Accordion.Item
                            eventKey={index.toString()}
                            key={index}
                          >
                            <Accordion.Header>
                              {subcounty.name}&nbsp;
                              <span>({subcounty.wards.length})</span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="mt-2">
                                <p className="text-muted">{subcounty.name}</p>
                                <ul style={{ listStyle: "none" }}>
                                  {/* <li>
                            <p>Population:&nbsp; <strong>{fNumber(subcounty.population)}</strong></p>
                          </li>
                          <li>
                            <p>HouseHolds:&nbsp; <strong>{ fNumber(subcounty.household) }</strong></p>
                          </li> */}
                                  <li>
                                    <p>Wards:</p>
                                    <span></span>
                                  </li>
                                </ul>
                              </div>
                              <ul>
                                {subcounty.wards && subcounty.wards.length > 0
                                  ? subcounty.wards.map((subcounty, index) => (
                                      <li key={index}>{subcounty.name}</li>
                                    ))
                                  : "No Wards"}
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))
                      : "No Data"}
                  </Accordion>
                </div>
              </Col>
            </Row>

            <h4 className="styled-heading styled-heading-small">Leadership</h4>
            <div className="oc-team-grid mb-4">
              {leaders && leaders.length > 0
                ? sortedLeaders(leaders).map((leader, index) => (
                    <Leader
                      key={index}
                      avatar={
                        leader.avatar
                          ? leader.avatar
                          : "/images/account-check.svg"
                      }
                      alt={leader.name}
                      name={leader.name}
                      designation={COUNTY_POSITION[leader.key]}
                    />
                  ))
                : "No Data"}
            </div>

            <Row className="mb-5">
              <Col lg="6">
                <div className="oc-heading-group">
                  <h4 className="styled-heading styled-heading-small">
                    Documents
                  </h4>
                  <Link className="oc-card-small-link" to="/documents">
                    Browse documents
                  </Link>
                </div>
                <div className="oc-card">
                  <ul className="oc-documents-feed">
                    {aboutCountyDocs.map((doc, index) => (
                      <li className="oc-documents-feed-item" key={index}>
                        <a
                          href={doc.url}
                          rel="nofollow noreferrer"
                          target="_blank"
                        >
                          <span>
                            <FontAwesomeIcon icon={faFilePdf} />
                          </span>
                          <p>{doc.title}</p>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>

              <Col lg="6">
                <h4 className="styled-heading styled-heading-small">
                  Contacts
                </h4>
                <div className="oc-card">
                  <div className="oc-card-contacts">
                    <div className="oc-card-contacts-item">
                      <h6>County HQ Office</h6>
                      <p>
                        <span>
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        {administrative_hq && administrative_hq.length > 0
                          ? administrative_hq
                          : "-"}
                      </p>
                    </div>
                    <div className="oc-card-contacts-item">
                      <h6>Phone</h6>
                      <p>
                        <span>
                          <FontAwesomeIcon icon={faPhone} />
                        </span>
                        <a href={`tel:${contacts ? contacts.phone : "-"}`}>{`${
                          contacts ? contacts.phone : "-"
                        }`}</a>
                        <br></br>
                        {/* <a href='tel:+254535252355'>
                          <span>{`${name} County Call Centre`}</span>
                          <br />
                          1548 (Toll Free)
                        </a> */}
                      </p>
                    </div>
                    <div className="oc-card-contacts-item">
                      <h6>Postal Address</h6>
                      <p>
                        <span>
                          <FontAwesomeIcon icon={faAddressCard} />
                        </span>
                        {contacts ? contacts.address : "-"}
                      </p>
                    </div>
                    <div className="oc-card-contacts-item">
                      <h6>Web &amp; Email</h6>
                      <p>
                        <span>
                          <FontAwesomeIcon icon={faGlobe} />
                        </span>
                        <a
                          href={contacts ? contacts.website : "/#"}
                          target="_blank"
                          rel="nofollow noreferrer"
                        >
                          {contacts ? contacts.website : "-"}
                        </a>
                      </p>
                      <p>
                        <span>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </span>
                        <a href={`mailto:${contacts ? contacts.email : ""}`}>
                          {contacts ? contacts.email : "-"}
                        </a>
                      </p>
                    </div>
                    <div className="oc-card-contacts-item">
                      <h6>Social</h6>
                      <ul className="social-icons">
                        <li>
                          <a
                            href={
                              contacts &&
                              contacts.twitter &&
                              contacts.twitter.length > 0
                                ? contacts.twitter
                                : "/#"
                            }
                            target="_blank"
                            rel="nofollow noreferrer"
                            title={`${name} County on Twitter`}
                          >
                            <FontAwesomeIcon icon={faTwitter} />
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              contacts &&
                              contacts.facebook &&
                              contacts.facebook.length > 0
                                ? contacts.facebook
                                : "/#"
                            }
                            target="_blank"
                            rel="nofollow noreferrer"
                            title={`${name} County on Facebook`}
                          >
                            <FontAwesomeIcon icon={faFacebookF} />
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              contacts &&
                              contacts.youtube &&
                              contacts.youtube.length > 0
                                ? contacts.youtube
                                : "/#"
                            }
                            target="_blank"
                            rel="nofollow noreferrer"
                            title={`${name} County on YouTube`}
                          >
                            <FontAwesomeIcon icon={faYoutube} />
                          </a>
                        </li>
                        {/* <li>
                          <a
                            href='https://instagram.com'
                            target='_blank'
                            rel='nofollow noreferrer'
                            title='County Feed'
                          >
                            <FontAwesomeIcon icon={faRss} />
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
