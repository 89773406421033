import { filter } from "lodash";
import React, { useContext, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
//pdf export
import "jspdf-autotable";
//components
import { fNumber, fShortenNumber } from "../../../util/formatNumber";
import BudgetsAggregatesTabelHead from "./BudgetsAggregatesTabelHead";
import BudgetsAggregatesToolbar from "./BudgetsAggregatesToolbar";
// import Scrollbar from "../../../Scrollbar";
import ExportOptionsMenu from "../../../components/ExportOptions";
import { handlePdfExport } from "../../../util/pdfExports";
import Paper from "@mui/material/Paper";
import CountyContext from "../../../store/CountyContext";

// ----------------------------------------------------------------------

const SUMMARY_TABLE_HEAD = [
  {
    id: "recurrent",
    label: "Recurrent",
    tooltip:
      "A recurrent budget tracks ongoing revenues and expenses that occur on a regular basis, be they monthly, quarterly, semiannually, or annually. Also known as an operational budget, a recurrent budget includes line items such as wages, utilities, rent or lease payments, and taxes",
    alignRight: false,
  },
  {
    id: "development",
    label: "Development",
    tooltip:
      "The Cabinet Secretary shall, with the approval of the relevant Committee of the National Assembly, allocate funds for every constituency in each financial year in accordance with section 34. ",
    alignRight: false,
  },
  {
    id: "national_equitable_share",
    label: "National Equitable Share",
    tooltip:
      "A National Equitable Share is an allocation made by the National Government during a financial year, in its fiscal allocation, gazetted through the Division of Revenue Act (hereinafter “the DORA”), in order to assist municipalities to fund various expenses including expenses such as free basic services",
    alignRight: false,
  },
  {
    id: "local_revenue",
    label: "Local Revenue",
    tooltip:
      "Locala Revenue is where local governments collect tax revenues from three primary sources: income, sales, and property taxes. Income and sales taxes make up the majority of combined state tax revenue, while property taxes are the largest source of tax revenue for local governments, including school districts",
    alignRight: false,
  },
  {
    id: "conditinal_grants",
    label: "Conditional Grants",
    tooltip:
      "These additional allocations are known as conditional allocations or conditional grants. They are conditional when the National Government imposes restrictions on how County Governments will spend them. They are unconditional when the National Government does not impose any restrictions concerning their expenditure",
    alignRight: false,
  },
  {
    id: "other_revenue",
    label: "Other Revenue",
    tooltip:
      "Other Revenue means all revenue received (whether by Tenant or any subtenants, assignees, concessionaires or licensees)",
    alignRight: false,
  },
];

const SUMMARY_EXPORT_HEADERS = [
  { label: "Recurrent", key: "recurrent" },
  { label: "Development", key: "development" },
  { label: "National Equitable Share", key: "national_equitable_share" },
  { label: "Local Revenue", key: "local_revenue" },
  { label: "Conditional Grants", key: "conditinal_grants" },
  { label: "Other Revenue", key: "other_revenue" },
];

const REVENUE_TABLE_HEAD = [
  {
    id: "national_equitable_share",
    label: "National Equitable Share",
    tooltip:
      "A National Equitable Share is an allocation made by the National Government during a financial year, in its fiscal allocation, gazetted through the Division of Revenue Act (hereinafter “the DORA”), in order to assist municipalities to fund various expenses including expenses such as free basic services",
    alignRight: false,
  },

  {
    id: "local_revenue",
    label: "Local Revenue",
    tooltip:
      "Local Revenue is where local governments collect tax revenues from three primary sources: income, sales, and property taxes. Income and sales taxes make up the majority of combined state tax revenue, while property taxes are the largest source of tax revenue for local governments, including school districts",
    alignRight: false,
  },

  {
    id: "conditinal_grants",
    label: "Conditional Grants",
    tooltip:
      "These additional allocations are known as conditional allocations or conditional grants. They are conditional when the National Government imposes restrictions on how County Governments will spend them. They are unconditional when the National Government does not impose any restrictions concerning their expenditure",
    alignRight: false,
  },

  {
    id: "balance_brought_forward",
    label: "Balance Brought Forward",
    tooltip:
      "A balance brought forward is a term used in accounting and financial statements to refer to the remaining balance from a previous accounting period that is carried forward to the current period. This balance is typically included in the opening balance of the current accounting period and represents the ending balance of the previous period",
    alignRight: false,
  },

  {
    id: "other_revenue",
    label: "Other Revenue",
    tooltip:
      "Other Revenue means all revenue received (whether by Tenant or any subtenants, assignees, concessionaires or licensees)",
    alignRight: false,
  },
];

const REVENUE_EXPORT_HEADERS = [
  { label: "National Equitable Share", key: "national_equitable_share" },
  { label: "Local Revenue", key: "local_revenue" },
  { label: "Conditional Grants", key: "conditinal_grants" },
  { label: "Balance Brought Forward", key: "balance_brought_forward" },
  { label: "Other Revenue", key: "other_revenue" },
];

const EXPENDITURE_TABLE_HEAD = [
  {
    id: "recurrent_expenditure",
    label: "Recurrent Expenditure",
    tooltip:
      "A recurrent expenditure refers to the portion of local government spending that is used to cover routine or regular expenses, such as salaries and wages, pensions, utilities, supplies, maintenance, and other operating expenses. Recurrent expenditures are typically incurred on an ongoing basis and are necessary for the day-to-day operations of the government",
    alignRight: false,
  },
  {
    id: "development_expenditure",
    label: "Development Expenditure",
    tooltip:
      "Development expenditure refers to government spending that is intended to promote long-term economic growth and development in the country. This type of expenditure is usually allocated to various sectors and projects that are expected to have a positive impact on the country's economy and society over the long-term.",
    alignRight: false,
  },
  {
    id: "personal_emoluments",
    label: "Personal Emoluments",
    tooltip:
      "Personal emoluments refer to the total amount of money paid to public servants, including salaries, allowances, and other benefits. These emoluments are usually paid to employees of the national government, county governments, and other state agencies.",
    alignRight: false,
  },
  {
    id: "operations_and_maintenance",
    label: "Operations & Maintenance",
    tooltip:
      "In the Kenyan county government, operations and maintenance (O&M) refers to the activities and tasks that are necessary to ensure that public assets and infrastructure are properly managed, maintained and kept in good condition. This involves ongoing management, repair, and upkeep of public assets and facilities, such as roads, bridges, buildings, water and sanitation systems, public parks, and other public infrastructure.",
    alignRight: false,
  },

  {
    id: "sitting_allowances",
    label: "Sitting Allowances",
    tooltip:
      "Sitting allowances in the Kenyan county government refer to the payments made to members of county assembly and other public servants for attending official meetings, conferences, and other work-related events. These allowances are meant to compensate public servants for their time and expenses incurred while attending official duties outside their normal working hours.",
    alignRight: false,
  },
];

const EXPENDITURE_EXPORT_HEADERS = [
  { label: "Recurrent Expenditure", key: "recurrent_expenditure" },
  { label: "Development Expenditure", key: "development_expenditure" },
  { label: "Personal Emoluments", key: "personal_emoluments" },
  { label: "Operations & Maintenance", key: "operations_and_maintenance" },
  { label: "Sitting Allowances", key: "sitting_allowances" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_budget) =>
        _budget.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BudgetAggregatesTable({
  countyBudgets,
  onExportToCsv,
  budgetYear,
  header,
  aggregateType,
  budgetsAggregatesData,
  budgetsAggregatesData2,
}) {
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const { name } = useContext(CountyContext);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = countyBudgets.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredBudgets = applySortFilter(
    countyBudgets,
    getComparator(order, orderBy),
    filterName
  );

  const onHandlePdfExport = () => {
    handlePdfExport(
      ["Revenue Source", "Amount"],
      filteredBudgets.map((fb) => [
        fb.name,
        fb.total ? fNumber(fb.total) : fNumber(fb.amount),
      ]),
      `${Date.now().toString()}_${budgetYear}_${name}-County_Revenue`,
      `${Date.now().toString()}_${budgetYear}_${name}-County_Revenue.pdf`,
      `${budgetYear}_${name}-County_Revenue`
    );
  };

  const selectedAggregateData =
    aggregateType === "Summary"
      ? budgetsAggregatesData.filter((ba) => ba.budget === "Budget Allocation")
      : aggregateType === "Revenue"
      ? budgetsAggregatesData.filter(
          (ba) => ba.budget === "Actual Budget Received"
        )
      : aggregateType === "Expenditure"
      ? budgetsAggregatesData.filter((ba) => ba.budget === "Actual Expenditure")
      : [];

  var selectedAggregateDataByYear = [];

  if (aggregateType === "Summary") {
    var summedUpData = {};

    sumUpData(selectedAggregateData, summedUpData);
    selectedAggregateDataByYear.push(summedUpData);
    // aggregateDataByYear = selectedAggregateData;
  } else {
    selectedAggregateDataByYear = selectedAggregateData.filter(
      (ba) => ba.period === budgetYear.replace("-", "/")
    );

    if (aggregateType === "Expenditure") {
      var additional = budgetsAggregatesData2.filter(
        (ba) => ba.period === budgetYear.replace("-", "/")
      );
      // console.log(budgetsAggregatesData2);
      // console.log(budgetYear);
      additional.forEach((ad) => {
        if (selectedAggregateDataByYear[0]) {
          selectedAggregateDataByYear[0][
            ad.description.toLowerCase().replace(/ /g, "_")
          ] = ad.amount.replace(/,/g, "");
        } else {
          selectedAggregateDataByYear.push({
            [ad.description.toLowerCase().replace(/ /g, "_")]:
              ad.amount.replace(/,/g, ""),
          });
        }
      });
    }
  }

  return (
    <>
      {/* <Scrollbar> */}
      <div className="oc-heading-group">
        <h4 className="styled-heading styled-heading-small">{header}</h4>
      </div>
      <BudgetsAggregatesToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      >
        <ExportOptionsMenu
          fileName={`${Date.now().toString()}_${budgetYear}_${name}-County.csv`}
          headers={
            aggregateType === "Summary"
              ? SUMMARY_EXPORT_HEADERS
              : aggregateType === "Revenue"
              ? REVENUE_EXPORT_HEADERS
              : EXPENDITURE_EXPORT_HEADERS
          }
          items={filteredBudgets}
          excelFileName={`${Date.now().toString()}_${budgetYear}_${name}-County.xlsx`}
          onExportToCsv={onExportToCsv}
          onExportToPdf={onHandlePdfExport}
        />
      </BudgetsAggregatesToolbar>
      <TableContainer component={Paper}>
        <Table className="data-table" stickyHeader>
          <caption className="caption">
            Data Source: <strong>Office of the Controller of Budgets</strong>
          </caption>
          <BudgetsAggregatesTabelHead
            order={order}
            orderBy={orderBy}
            headLabel={
              aggregateType === "Summary"
                ? SUMMARY_TABLE_HEAD
                : aggregateType === "Revenue"
                ? REVENUE_TABLE_HEAD
                : EXPENDITURE_TABLE_HEAD
            }
            rowCount={countyBudgets.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {selectedAggregateDataByYear.map((ba, idx) =>
              aggregateType === "Summary"
                ? plotSummaryData(ba, idx)
                : aggregateType === "Revenue"
                ? plotRevenueData(ba, idx)
                : plotExpenditureData(ba, idx)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function plotSummaryData(ba, idx) {
  return (
    <TableRow sx={{ padding: 2 }} hover tabIndex={-1} role="checkbox" key={idx}>
      <TableCell align="left">
        {fShortenNumber(ba.recurrent ? ba.recurrent * 1000000 : 0)}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(ba.development ? ba.development * 1000000 : 0)}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(ba.equitable_share ? ba.equitable_share * 1000000 : 0)}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(ba.local_revenue ? ba.local_revenue * 1000000 : 0)}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(
          ba.conditional_grants ? ba.conditional_grants * 1000000 : 0
        )}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(ba.other_revenue ? ba.other_revenue * 1000000 : 0)}
      </TableCell>
    </TableRow>
  );
}

function plotRevenueData(ba, idx) {
  return (
    <TableRow sx={{ padding: 2 }} hover tabIndex={-1} role="checkbox" key={idx}>
      <TableCell align="left">
        {fShortenNumber(ba.equitable_share ? ba.equitable_share * 1000000 : 0)}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(ba.local_revenue ? ba.local_revenue * 1000000 : 0)}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(
          ba.conditional_grants ? ba.conditional_grants * 1000000 : 0
        )}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(ba.balance_bfw ? ba.balance_bfw * 1000000 : 0)}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(ba.other_revenue ? ba.other_revenue * 1000000 : 0)}
      </TableCell>
    </TableRow>
  );
}

function plotExpenditureData(ba, idx) {
  return (
    <TableRow sx={{ padding: 2 }} hover tabIndex={-1} role="checkbox" key={idx}>
      <TableCell align="left">
        {fShortenNumber(ba.recurrent ? ba.recurrent * 1000000 : 0)}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(ba.development ? ba.development * 1000000 : 0)}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(
          ba.personell_emoluments ? ba.personell_emoluments * 1000000 : 0
        )}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(
          ba.operations_and_maintenance
            ? ba.operations_and_maintenance * 1000000
            : 0
        )}
      </TableCell>
      <TableCell align="left">
        {fShortenNumber(
          ba.sitting_allowances_to_the_48_members_of_the_county_assembly
            ? ba.sitting_allowances_to_the_48_members_of_the_county_assembly *
                1000000
            : 0
        )}
      </TableCell>
    </TableRow>
  );
}

function sumUpData(selectedAggregateData, summedUpData) {
  selectedAggregateData.forEach((ba) => {
    summedUpData["recurrent"]
      ? (summedUpData["recurrent"] += parseFloat(
          ba.recurrent ? ba.recurrent : 0
        ))
      : (summedUpData["recurrent"] = parseFloat(ba.recurrent));
    summedUpData["development"]
      ? (summedUpData["development"] += parseFloat(
          ba.development ? ba.development : 0
        ))
      : (summedUpData["development"] = parseFloat(ba.development));
    summedUpData["equitable_share"]
      ? (summedUpData["equitable_share"] += parseFloat(
          ba.equitable_share ? ba.equitable_share : 0
        ))
      : (summedUpData["equitable_share"] = parseFloat(ba.equitable_share));
    summedUpData["local_revenue"]
      ? (summedUpData["local_revenue"] += parseFloat(
          ba.local_revenue ? ba.local_revenue : 0
        ))
      : (summedUpData["local_revenue"] = parseFloat(ba.local_revenue));
    summedUpData["conditional_grants"]
      ? (summedUpData["conditional_grants"] += parseFloat(
          ba.conditional_grants ? ba.conditional_grants : 0
        ))
      : (summedUpData["conditional_grants"] = parseFloat(
          ba.conditional_grants
        ));
    summedUpData["other_revenue"]
      ? (summedUpData["other_revenue"] += parseFloat(
          ba.other_revenue ? ba.other_revenue : 0
        ))
      : (summedUpData["other_revenue"] = parseFloat(ba.other_revenue));
  });
}
