import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import useHttp from '../hooks/useHttp';
import Breadcrumb from '../ui-components/Breadcrumb';
import { Alert, AlertTitle } from '@mui/material';
import { Pagination } from '@material-ui/lab'

function SearchResults() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const PER_PAGE = 20;
  const [count, setCount] = useState(0);
  const [_DATA, setData] = useState(undefined);

  const { sendRequest } = useHttp();
  const [searchResults, setSearchResults] = useState([]);
  const [query, setQuery] = useState(searchParams.get('q'));

  const handleChange = (e, p) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    _DATA.jump(p);
    setPage(p);
  };

  const handleSearch = useCallback(
    async (query) => {
      try {
        const response1 = await sendRequest(
          `${process.env.REACT_APP_BASEDEVURL}/api/search?key=${query ? query : searchParams.get('q')
          }`
        );
        const response2 = await sendRequest(
          `${process.env.REACT_APP_BASEDEVURL}/api/sectors`
        );
        const filteredSectors = response2.data.filter((s) => s.name.toLowerCase().includes(query ? query.toLowerCase() : searchParams.get('q').toLowerCase()))
          .map((sector) => {
            return {
              key: sector.name,
              link: `/sector/${sector.id}`,
              type: "sector",
            };
          });


        const d = response1.data.concat(filteredSectors);
        setSearchResults(d);
        setData(paginate(d, PER_PAGE))
        setCount(Math.ceil(d.length / PER_PAGE));
      } catch (error) { }
    },
    [sendRequest, searchParams]
  );


  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return (
    <>
      <Breadcrumb title="Search"></Breadcrumb>
      <div className="wrapper">
        <div className="content-section">
          <Container>
            <h1 className="styled-heading styled-heading-large">
              {searchResults ? searchResults.length : ""} results found
            </h1>

            <div className="search-filters">
              <div className="row mb-4">
                <div className="col-lg-3 mb-3">
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="search"
                      id="filter"
                      name="filter"
                      placeholder="Search..."
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          setSearchParams({ q: e.target.value });
                          handleSearch(e.target.value);
                        }
                      }}
                      value={query}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-3 mb-3">
                  <div className="input-group">
                    <select
                      className="form-select"
                      id=""
                      name=""
                      // onChange={handleDocTypeChange}
                    >
                      <option value="all">-- All --</option>
                      <option value="projects">Projects</option>
                      <option value="indicators">Indicators</option>
                      <option value="documents">Documents</option>
                    </select>
                  </div>
                </div> */}
              </div>
            </div>

            {_DATA && _DATA.currentData().length > 0 ? (
              <>
                <div className="mb-4"><h6>Page {page} of {count}</h6></div>
                <div className="oc-documents">
                  <div className="row mb-4">
                    {_DATA.currentData().map((result, index) => {
                      return (
                        <div className="col-lg-6 mb-3" key={index}>
                          <div className="oc-document">
                            <div className="oc-document-top">
                              <div className="oc-document-top-info">
                                <h6>
                                  {result.type.toLowerCase() === "document" ? (
                                    <a
                                      target="_blank"
                                      href={result.link}
                                      rel="noreferrer"
                                    >
                                      <div>{result.key}</div>
                                    </a>
                                  ) : (
                                    <Link to={result.link}>{result.key}</Link>
                                  )}
                                </h6>
                              </div>
                            </div>
                            <div className="oc-document-bottom">
                              <ul>
                                <li>
                                  Type: <span>{result.type}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Pagination
                  color="secondary"
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </>
            ) : (
              <Alert severity="info">
                <AlertTitle>No results found</AlertTitle>
                Sorry, we couldn't find any results for your search. Please try
                another keyword.
              </Alert>
            )}
          </Container>
        </div>
      </div>
    </>
  );
}

function paginate(data, itemsPerPage) {
  let currentPage = 1;
  let maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    currentPage = Math.min(currentPage + 1, maxPage);
  }

  function prev() {
    currentPage = Math.max(currentPage - 1, 1);
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    currentPage = Math.min(pageNumber, maxPage);
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export default SearchResults;
