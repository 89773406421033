import React, { useCallback, useEffect, useState } from 'react';
import Breadcrumb from '../ui-components/Breadcrumb';
import ProjectsComponent from '../components/ProjectsComponent';

import useHttp from '../hooks/useHttp';

export default function Projects() {
  const [projects, setProjects] = useState([]);
  const [projectsCount, setProjectsCount] = useState([]);
  const [projectsAmount, setProjectsAmount] = useState([]);
  const [numberOfProjects, setNumberOfProjects] = useState(0);

  const { sendRequest, isLoading } = useHttp();

  const handleFilterProjects = useCallback(
    async (url) => {
      if (url) {
        try {
          const response = await sendRequest(url);
          if (response && response.data && response.data.length > 0) {
            setProjects(response.data);
            setNumberOfProjects(response.data.length);
          }
        } catch (error) {}
      } else {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_BASEDEVURL}/api/projects?page=0`
          );
          if (response && response.data && response.data.length > 0) {
            setProjects(response.data);
            setNumberOfProjects(response.data.length);
          }
        } catch (error) {}
      }

      try {
        const response2 = await sendRequest(
          `${process.env.REACT_APP_BASEDEVURL}/api/projects/filters`
        );
        if (response2 && response2.data) {
          setProjectsCount(response2.data.constituencies);
        }
      } catch (error) {}
    },
    [sendRequest]
  );

  const fetchProjects = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects`
      );
      if (response && response.data && response.data.length > 0) {
        setProjects(response.data);
        setNumberOfProjects(response.data.length);
      }
    } catch (error) {}

    try {
      const response2 = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects/filters`
      );
      if (response2 && response2.data) {
        setProjectsCount(response2.data.constituencies);
      }

      const response3 = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects/amounts`
      );
      if (response3) {
        setProjectsAmount(response3.data);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);


  return (
    <>
      <Breadcrumb title='Projects' />
      <div className='data-wrapper'>
        <ProjectsComponent
          handleClearFilters={fetchProjects}
          handleFilterProjects={handleFilterProjects}
          loadingState={isLoading}
          countyProjects={projects}
          projectsCount={projectsCount}
          projectsAmount={projectsAmount}
          numberOfProjects={numberOfProjects}
        />
      </div>
    </>
  );
}
