import React, { useEffect, useState } from "react";
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
//
import BaseOptionChart from "./BaseOptionChart";
import { fShortenNumber } from "../util/formatNumber";

const getAmounts = (arry) => {
  return arry.map((arr) => arr.allocation);
};

const getLabels = (arry) => {
  return arry.map((arr) => arr.name);
};

// export const isObject = (obj) => {
//   return Object.prototype.toString.call(obj) === '[object Object]';
// };

export default function TopFiveBudgetAllocations({ expenditureItems }) {
  const [departments, setDepartments] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (expenditureItems) {
      if (expenditureItems.length >= 5) {
        const deptSpend = expenditureItems
          .sort((a, b) => parseFloat(b.allocation) - parseFloat(a.allocation))
          .slice(0, 5);
        const deptAmounts = [
          { name: "Amount in KES", data: getAmounts(deptSpend) },
        ];
        setAmounts(deptAmounts);
        const labelItems = getLabels(deptSpend);
        setDepartments(labelItems);
        setColors(
          expenditureItems.map((item) => (item.color ? item.color : "#FF5733"))
        );
      } else {
        const deptSpend = expenditureItems.sort(
          (a, b) => parseFloat(b.allocation) - parseFloat(a.allocation)
        );

        const deptAmounts = [
          { name: "Amount in KES", data: getAmounts(deptSpend) },
        ];
        setAmounts(deptAmounts);
        const labelItems = getLabels(deptSpend);
        setDepartments(labelItems);
        setColors(
          expenditureItems.map((item) => (item.color ? item.color : "#FF5733"))
        );
      }
    }
  }, [expenditureItems]);

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      background: "#fff",
      toolbar: {
        show: true,
        offsetX: 15,
        offsetY: -40,
      },
    },
    stroke: { show: false },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        //barHeight: '80%',
        //columnWidth: '80%',
        borderRadius: 0,
        distributed: true,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      formatter: function (val, opt) {
        return fShortenNumber(val);
      },
    },
    xaxis: {
      categories: departments,
      labels: {
        formatter: function (val) {
          return fShortenNumber(val);
        },
      },
      title: {
        text: "Amount in Kes",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        style: {
          cssClass: "oc-charts-yaxis-label",
        },
      },
      style: {
        textTransform: "lowercase",
      },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter(val) {
          return `${fShortenNumber(val)}`;
        },
      },
    },
    colors: colors,
    responsive: [
      {
        breakpoint: 767.98,
        options: {
          chart: {
            height: 500,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "80%",
            },
          },
          legend: {
            horizontalAlign: "left",
            fontSize: "12px",
            position: "bottom",
            show: true,
            offsetX: 0,
            offsetY: 0,
          },
          yaxis: {
            show: false,
          },
        },
      },
    ],
  });

  return (
    <div className="oc-card">
      <h6 className="styled-heading styled-heading-small">
        Top 5 Budget Allocation
      </h6>
      <ReactApexChart
        type="bar"
        series={amounts}
        options={chartOptions}
        height="auto"
      />
    </div>
  );
}
