import React, { useState, useEffect, useCallback } from "react";
// components
import { fPercent, fShortenNumber } from "../util/formatNumber";
import useHttp from "../hooks/useHttp";
import BudgetsComponent from "../components/BudgetsComponent";
import Breadcrumb from "../ui-components/Breadcrumb";
import { Container } from "react-bootstrap";
import useFetchProjects from "../hooks/useFetchProjects";
import { Alert } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

// ----------------------------------------------------------------------

export default function Budgets() {
  const [annualExpenditure, setAnnualExpediture] = useState(0);
  const [annualRevenue, setAnnualRevenue] = useState(0);
  const [totalRev, setTotalRev] = useState(0);
  const [totalExp, setTotalExp] = useState(0);
  const [allRevenue, setAllRevenue] = useState([]);
  const [allExpenditure, setAllExpenditure] = useState([]);
  const [revenueTrends, setRevenueTrends] = useState([]);
  const [expenditureTrends, setExpenditureTrends] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [expenditureData, setExpenditureData] = useState([]);
  const [revPeriods, setRevPeriods] = useState([]);
  // const [expPeriods, setExpPeriods] = useState([]);
  const [absorption, setAbsorption] = useState(0);
  const [departmentSpendData, setDepartmentSpendData] = useState([]);
  const [expenditureItems, setExpenditureItems] = useState([]);
  const [budgetComp, setBudgetComp] = useState();
  const [expenditureComp, setExpenditureComp] = useState();
  const [colorMap, setColors] = useState([]);
  const [budgetsAggregatesData, setBudgetsAggregatesData] = useState([]);
  const [budgetsAggregatesData2, setBudgetsAggregatesData2] = useState([]);
  const [budgetsOperationsAndMaintenance, setBudgetsOperationsAndMaintenance] =
    useState([]);
  const [budgetsTrendAnalysis, setBudgetsTrendAnalysis] = useState([]);

  const { sendRequest } = useHttp();
  const { projects } = useFetchProjects();

  const fetchRevenueForYear = useCallback(
    async (selectedYear = { id: "2023-2024", name: "2023-2024" }) => {
      try {
        const res = await sendRequest(
          `${process.env.REACT_APP_BASEDEVURL}/api/budgets/revenue/${selectedYear.id}`
        );
        if (res && res.data) {
          setRevenueData(res.data.data);
          setAnnualRevenue(res.data.total);
        }
      } catch (error) {}
    },
    [sendRequest]
  );

  const fetchTopSpendData = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects/departments`
      );
      if (res && res.data) {
        const arryData = [];
        for (const dt in res.data) {
          arryData.push({
            id: res.data[dt].id,
            name: res.data[dt].name,
            amount: res.data[dt].amount,
            color: res.data[dt].color,
          });
        }
        if (arryData.length > 0) {
          const dtSorted = arryData
            .sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount))
            .slice(0, 5);
          setDepartmentSpendData(dtSorted);
        }
      }
    } catch (error) {}
  }, [sendRequest]);

  const fetchExpenditureForYear = useCallback(
    async (selectedYear = { id: "2023-2024", name: "2023-2024" }) => {
      try {
        const res = await sendRequest(
          `${process.env.REACT_APP_BASEDEVURL}/api/budgets/expenditure/${selectedYear.id}`
          // `https://api.local.opencounty.org/api/budgets/expenditure`
        );
        if (res && res.data) {
          setAnnualExpediture(res.data.total_expenditure);
          setExpenditureData(res.data.data);
        }
      } catch (error) {}
    },
    [sendRequest]
  );

  const fetchDepartmentColors = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/budgets/expenditure/`
      );
      if (res && res.data) {
        setColors(
          res.data.data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((a) => a.color)
        );
      }
    } catch (error) {}
  }, [sendRequest]);

  const onExportExpenditureToCsv = useCallback(
    async (selectedYear) => {
      if (selectedYear) {
        try {
          const res = await sendRequest(
            `${process.env.REACT_APP_BASEDEVURL}/api/budgets/expenditure/${selectedYear.id}`
          );
          if (res && res.data) {
            setAnnualExpediture(res.data.total_expenditure);
            setExpenditureData(res.data.data);
          }
        } catch (error) {}
      }
    },
    [sendRequest]
  );

  const onExportRevenueToCsv = useCallback(
    async (selectedYear) => {
      if (selectedYear) {
        try {
          const res = await sendRequest(
            `${process.env.REACT_APP_BASEDEVURL}/api/budgets/revenue/${selectedYear.id}`
          );
          if (res && res.data) {
            setRevenueData(res.data.data);
            setAnnualRevenue(res.data.total);
          }
        } catch (error) {}
      }
    },
    [sendRequest]
  );

  const fetchRevenue = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/budgets/revenue`
      );
      if (res && res.data) {
        setRevenueTrends(res.data.trends);
        setTotalRev(res.data.total);
        setAllRevenue(res.data.data);
        // const pzd = res.data.periods
        //   .map((period) => ({
        //     id: period,
        //     name: period,
        //   }))
        //   .reverse();
        const pzd = [];
        for (const key in res.data.periods) {
          pzd.push({
            id: res.data.periods[key],
            name: res.data.periods[key],
          });
        }
        setRevPeriods(pzd.reverse());
      }
    } catch (error) {}
  }, [sendRequest]);

  const fetchExpenditure = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/budgets/expenditure`
      );
      if (res && res.data) {
        setExpenditureTrends(res.data.trends);
        setTotalExp(res.data.total);
        setExpenditureComp({
          name: "Revenue Allocation (KES)",
          total: fShortenNumber(res.data.allocation),
          type: "total",
          trend: "up",
          itemType: "revenue",
        });
        setBudgetComp({
          name: "Expenditure (KES)",
          total: fShortenNumber(+res.data.expenditure),
          type: "total",
          trend: "down",
          itemType: "expenditure",
        });
        setAbsorption({
          name: "Budget Absorption Rate (%)",
          total: fPercent(res.data.absorption * 100),
          type: "percentage",
          trend: "up",
          itemType: "revenue",
        });
        const dMap = res.data.data.map((rData) => ({
          id: rData.id,
          name: rData.name,
          total: rData.total,
          percentage: rData.percentage,
        }));
        setAllExpenditure(dMap);
        // const pzd = res.data.periods
        //   .map((period) => ({
        //     id: period,
        //     name: period,
        //   }))
        //   .reverse();
        setExpenditureItems(res.data.data);
        // setExpPeriods(pzd);
      }
    } catch (error) {}
  }, [sendRequest]);

  const fetchBudgetsAggregates = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/budgets/aggregates`
      );
      if (res && res.data) {
        setBudgetsAggregatesData(res.data);
      }
    } catch (error) {}
  }, [sendRequest]);

  const fetchExpenditure2 = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/budgets/data/expenditure`
      );
      if (res && res.data) {
        setBudgetsAggregatesData2(
          res.data.data.filter((a) => a.category === "Expenditure Summary")
        );
        setBudgetsOperationsAndMaintenance(
          res.data.data.filter(
            (a) => a.category === "Operations and Maintenance"
          )
        );
      }
    } catch (error) {}
  }, [sendRequest]);

  const fetchBudgetsTrendAnalysis = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/budgets/data/departments`
      );
      if (res && res.data) {
        const rData = res.data.data.map((dt) => ({
          budget_plan:
            parseFloat(dt.development_allocation) +
            parseFloat(dt.recurrent_allocation),
          actual_received:
            parseFloat(dt.development_exchequer) +
            parseFloat(dt.recurrent_exchequer),
          actual_expenditure:
            parseFloat(dt.development_expenditure) +
            parseFloat(dt.recurrent_expenditure),
          period: dt.period,
        }));
        setBudgetsTrendAnalysis(rData);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchExpenditure();
  }, [fetchExpenditure]);

  useEffect(() => {
    fetchRevenue();
  }, [fetchRevenue]);

  useEffect(() => {
    fetchRevenueForYear();
  }, [fetchRevenueForYear]);

  useEffect(() => {
    fetchTopSpendData();
  }, [fetchTopSpendData]);

  useEffect(() => {
    fetchDepartmentColors();
  }, [fetchDepartmentColors]);

  useEffect(() => {
    fetchBudgetsAggregates();
  }, [fetchBudgetsAggregates]);

  useEffect(() => {
    fetchExpenditure2();
  }, [fetchExpenditure2]);

  useEffect(() => {
    fetchBudgetsTrendAnalysis();
  }, [fetchBudgetsTrendAnalysis]);

  return (
    <>
      <Breadcrumb title="Budgets"></Breadcrumb>
      <Alert severity="info" color="info">
        Budget data is sourced from the{" "}
        <a href="https://cob.go.ke/" target="_blank" rel="nofollow noreferrer">
          Office of the Controller of Budget
          <FontAwesomeIcon className="ms-1" icon={faArrowUpRightFromSquare} />
        </a>
      </Alert>
      <div className="wrapper">
        <div className="content-section">
          <Container>
            <BudgetsComponent
              revPeriods={revPeriods}
              expPeriods={revPeriods}
              totalRev={totalRev}
              totalExp={totalExp}
              fetchRevenueForYear={fetchRevenueForYear}
              fetchExpenditureForYear={fetchExpenditureForYear}
              expenditureTrends={expenditureTrends}
              revenueTrends={revenueTrends}
              annualExpenditure={annualExpenditure}
              annualRevenue={annualRevenue}
              expenditureData={expenditureData}
              revenueData={revenueData}
              allExpenditure={allExpenditure}
              allRevenue={allRevenue}
              absorption={absorption}
              departmentSpendData={departmentSpendData}
              budgetsAggregatesData={budgetsAggregatesData}
              budgetsAggregatesData2={budgetsAggregatesData2}
              budgetsOperationsAndMaintenance={budgetsOperationsAndMaintenance}
              budgetsTrendAnalysis={budgetsTrendAnalysis}
              projects={projects}
              expenditureItems={expenditureItems}
              budgetComp={budgetComp}
              expenditureComp={expenditureComp}
              handleData={() => {}}
              onExportToCsv={onExportRevenueToCsv}
              onExportExpenditureToCsv={onExportExpenditureToCsv}
              colorMap={colorMap}
              //onExportExpenditureToXls={() => {}}
            />
          </Container>
        </div>
      </div>
    </>
  );
}
