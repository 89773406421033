import jsPDF from "jspdf";
import "jspdf-autotable";
import { base64LetterHead } from "./constants";
import { fShortenNumber } from "./formatNumber";

/**
 * @param  {string[]} head - this is an array of strings passed as the PDF header eg. ['Expenditure Type', 'Budget Allocation', 'Expenditure', 'Absorption']
 * @param  {any[]} body - this is where the data is mapped to the subsequent headers - should be an array of any primitive data type eg. [100, 200, 300, 400]
 * @param  {string} text - this will go to the footer of the document, typically - a short description of what the exported data is all about eg. 2019-20 County Expenditure
 * @param  {string} pdfName - this is the name of the document once exported (always use .pdf at the end of the document name, start the document name with Date.now().toString() to allow for some sort of document indexing) - eg - Date.now().toString()}_${expenditureYear}_County_Expenditure.pdf
 */
export const handlePdfExport = (head, body, text, pdfName, title) => {
  const doc = new jsPDF("l");
  if (title.length > 3) {
    doc.text(title, 12, 50);
  }
  doc.autoTable({
    head: [head],
    body: body,
    headStyles: {
      fillColor: [16, 130, 20],
    },
    didDrawPage: function (data) {
      // Header
      // doc.setFontSize(20);
      // doc.setTextColor(40);
      if (base64LetterHead) {
        doc.addImage(base64LetterHead, "PNG", 70, 5, 170, 40);
      }
      // Footer
      doc.setFontSize(8);
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.text(text, data.settings.margin.left, pageHeight - 10);
    },
    margin: { top: 55 },
  });
  doc.save(pdfName);
};

export const prepareDataForExport = (
  organizedData,
  years,
  columns,
  hasConstituency
) => {
  // Preparing the head
  const head = [hasConstituency ? "Constituency" : "Entry"];
  if (columns.length > 0) {
    years.forEach((year) => {
      columns.forEach((column) => {
        head.push(`${year} (${column})`);
      });
    });
  } else {
    // If no columns, just add year labels to ensure some structure in the PDF
    years.forEach((year) => {
      head.push(`${year}`);
    });
  }

  // Preparing the body
  const body = [];
  const pdfBody = [];
  Object.entries(organizedData).forEach(([identifier, yearData]) => {
    const row = [identifier]; // Start row with the identifier
    years.forEach((year) => {
      if (hasConstituency) {
        columns.forEach((column) => {
          const value =
            yearData[year] && yearData[year][column]
              ? yearData[year][column]
              : "0";
          row.push(value); // Convert number to a formatted string if needed
        });
      } else {
        const value = yearData[year] ? yearData[year] : "0";
        row.push(value);
      }
    });
    body.push(row);
    pdfBody.push(
      row.map((value) => (!isNaN(value) ? fShortenNumber(value) : value))
    );
  });

  return { head, body, pdfBody };
};

export const prepareColumnDataForExport = (
  data,
  years,
  columns,
  hasConstituency
) => {
  const head = [hasConstituency ? "Constituency" : "Entry"];
  const body = [];
  const pdfBody = [];

  if (columns.length > 0) {
    years.forEach((year) => {
      columns.forEach((column) => {
        head.push(`${year} (${column})`);
      });
    });
  } else {
    // If no columns, just add year labels to ensure some structure in the PDF
    years.forEach((year) => {
      head.push(`${year}`);
    });
  }

  Object.entries(data).forEach(([constituency, yearData]) => {
    // Initialize the row with the constituency name
    const row = [constituency];

    years.forEach((year) => {
      // For each year, check if there's data for that year
      const dataForYear = yearData[year];
      if (dataForYear) {
        // If there's data for that year, then for each column check if there's a value
        columns.forEach((column) => {
          // If the value exists, push it, otherwise push a placeholder like '0' or 'N/A'
          row.push(dataForYear[column] ? dataForYear[column] : "0");
        });
      } else {
        // If no data for that year, fill in the row with placeholders for each column
        columns.forEach(() => {
          row.push("0");
        });
      }
    });

    // Add the constructed row to the body
    body.push(row);
    pdfBody.push(
      row.map((value) => (!isNaN(value) ? fShortenNumber(value) : value))
    );
  });

  return { head, body, pdfBody };
};

export const prepareGroupColumnDataForExport = (
  data,
  yrs,
  cols,
  hasConstituency
) => {
  const head = [hasConstituency ? "Constituency" : "Entry"];
  // Prepare the header based on years and columns

  yrs.forEach((year) => {
    cols.forEach((column) => {
      head.push(`${year} (${column})`);
    });
  });

  // Prepare the body data from organizedData
  const body = [];
  const pdfBody = [];

  Object.entries(data).forEach(([constituency, groupData]) => {
    Object.entries(groupData).forEach(([group, yearData]) => {
      // Start each row with the constituency and group
      const row = [`${constituency} (${group})`];

      // Fill each column year and key-wise
      yrs.forEach((year) => {
        cols.forEach((column) => {
          const value =
            yearData[year] && yearData[year][column]
              ? yearData[year][column]
              : "0";
          row.push(value);
        });
      });

      // Add the completed row to the body
      body.push(row);
      pdfBody.push(
        row.map((value) => (!isNaN(value) ? fShortenNumber(value) : value))
      );
    });
  });

  return { head, body, pdfBody };
};
