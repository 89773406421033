import React, { useCallback, useEffect, useState } from "react";
import useHttp from "../hooks/useHttp";
import Breadcrumb from "../ui-components/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileWord } from "@fortawesome/free-regular-svg-icons";
import { Accordion, Container } from "react-bootstrap";

export function formatFileSize(bytes) {
  if (bytes >= 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    return (bytes / 1024).toFixed(1) + " kB";
  } else {
    return bytes + " bytes";
  }
}

export default function Documents() {
  const { sendRequest } = useHttp();
  const [query, setQuery] = useState("");
  const [docType, setDocType] = useState("all");
  const [countyDocs, setCountyDocs] = useState([]);
  const [filteredCountyDocs, setFilteredCountyDocs] = useState([]);

  const fetchCountyDocs = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/documents`
      );
      if (response && response.data) {
        setCountyDocs(response.data);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchCountyDocs();
  }, [fetchCountyDocs]);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    handleFilterDocs(event.target.value);
  };

  const handleFilterDocs = (query) => {
    if (query.trim().length > 0) {
      const filtered = countyDocs.filter((doc) =>
        doc.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredCountyDocs(filtered);
    } else setFilteredCountyDocs([]);
  };

  const handleDocTypeChange = (event) => {
    setDocType(event.target.value);
  };

  function handleFilterDocType(docs, type) {
    if (type === "all") {
      return docs;
    } else {
      const filtered = [];
      for (const doc of docs) {
        for (const d of doc.media) {
          if (d.mime_type.includes(type)) {
            filtered.push(doc);
          }
        }
      }
      return filtered;
    }
  }

  return (
    <>
      <Breadcrumb title='Documents'></Breadcrumb>
      <div className='wrapper'>
        <div className='content-section'>
          <Container>
            <h1 className='styled-heading styled-heading-large'>Documents</h1>

            <div className='search-filters'>
              <h6>Filter Results</h6>
              <div className='row mb-4'>
                <div className='col-lg-3 mb-3'>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type='search'
                      id='filter'
                      name='filter'
                      placeholder='Search...'
                      onChange={handleQueryChange}
                      value={query}
                    />
                  </div>
                </div>
                <div className='col-lg-3 mb-3'>
                  <div className='input-group'>
                    <select
                      className='form-select'
                      id=''
                      name=''
                      onChange={handleDocTypeChange}
                    >
                      <option value='all'>-- All document types --</option>
                      <option value='pdf'>PDF</option>
                      <option value='doc'>DOC</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className='oc-documents'>
              {query.length === 0 &&
                showDocuments(handleFilterDocType(countyDocs, docType))}
              {query.length > 0 &&
                showDocuments(handleFilterDocType(filteredCountyDocs, docType))}
            </div>
          </Container>
        </div>
      </div>
    </>
  );

  function showDocuments(finalDocs) {
    return finalDocs.map((doc, index) => (
      <div className='oc-document' key={index}>
        <div className='oc-document-top'>
          <div className='oc-document-top-info'>
            <h6 style={{ color: "#ff3514" }}>{doc.title}</h6>
          </div>
        </div>
        <div className='oc-document-bottom'>
          <Accordion>
            <Accordion.Item eventKey={doc.id} style={{ border: "none" }}>
              <Accordion.Header
                style={{ marginLeft: "1.5rem" }}
              >{`Documents(${doc.media.length})`}</Accordion.Header>
              <Accordion.Body>
                {doc.media.map((d) => (
                  <ul
                    key={d.id}
                    style={{ marginLeft: "1.5rem", padding: "1rem" }}
                  >
                    <li>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <FontAwesomeIcon
                          size='lg'
                          style={{ marginRight: "1rem" }}
                          icon={
                            d.mime_type.includes("pdf") ? faFilePdf : faFileWord
                          }
                        />
                        <a
                          href={d.original_url}
                          target='_blank'
                          rel='noreferrer noopener'
                          style={{ color: "#ff3514" }}
                        >
                          {d.name}
                        </a>
                      </div>
                    </li>
                    <li>
                      <span>Uploaded:</span>{" "}
                      {new Date(d.created_at).toDateString()}
                    </li>
                    <li>
                      <span>File size:</span> {formatFileSize(d.size)}
                    </li>
                    <li>
                      <span>File type:</span> {d.mime_type}
                    </li>
                  </ul>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <ul style={{ marginLeft: "1.5rem" }}>
            <li>
              <span>Uploaded:</span> {new Date(doc.created_at).toDateString()}
            </li>
          </ul>
        </div>
      </div>
    ));
  }
}
