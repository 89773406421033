import React from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";

import geojson from "../../assets/nandi-subcounties.geojson.json";
//import useFetchGeoJson from "../../hooks/useFetchGeoJson";
import "leaflet/dist/leaflet.css";

const center = [0.1836, 35.1269];

const countyStyle = {
  fillColor: "#eb4034",
  weight: 2,
  color: "#eb4034",
  fillOpacity: 0.2,
};

function getVoivodeshipName(feature, layer) {
  if (feature.properties && feature.properties.ADM2_EN) {
    layer.bindPopup(feature.properties.ADM2_EN);
  }
}

function onEachFeature(feature, layer) {
  layer.on("mouseover", function (e) {
    getVoivodeshipName(feature, layer);

    this.openPopup();

    // style
    this.setStyle({
      fillColor: "#3388ff",
      weight: 2,
      color: "#3388ff",
      fillOpacity: 0.7,
    });
  });
  layer.on("mouseout", function () {
    this.closePopup();
    // style
    this.setStyle({
      fillColor: "#eb4034",
      weight: 2,
      color: "#eb4034",
      fillOpacity: 0.2,
    });
  });
}

const HomeMap = () => {
  //const { geojson } = useFetchGeoJson("https://raw.githubusercontent.com/Mondieki/kenya-counties-subcounties/master/geojson/nandi.json")

  return <MapContainer
  center={center}
  zoom={9}
  scrollWheelZoom={false}
  style={{ width: "100%", height: "50vh" }}
  >
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  
  <GeoJSON
    data={geojson}
    onEachFeature={onEachFeature}
    style={countyStyle}
  />
  </MapContainer>
}

export default HomeMap;
