import { useState, useEffect, useCallback } from "react";
//hooks
import useHttp from "./useHttp";
// ----------------------------------------------------------------------
// https://api.sabasi.mobi/api/nandi/Data?sector=Health

const useFetchSabasiHealthData = (key) => {
  const [healthData, setHealthData] = useState([]);
  const { isLoading, sendRequest } = useHttp();

  const fetchFilterSources = useCallback(async () => {
    try {
      const response = await sendRequest(
        `https://api.sabasi.mobi/api/nandi/data?sector=${key}`
      );
      if (response && response.data && response.data.data.length > 0) {
        setHealthData(response.data.data);
      }
    } catch (error) {}
  }, [sendRequest, key]);

  useEffect(() => {
    fetchFilterSources();
  }, [fetchFilterSources]);

  return { isLoading, healthData, setHealthData };
};

export default useFetchSabasiHealthData;
