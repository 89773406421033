import { Container, Tab, Row, Col } from "react-bootstrap";
import BreadcrumbProject from "../ui-components/BreadcrumbProject";
import Tabs from "react-bootstrap/esm/Tabs";
import { useParams, useNavigate } from "react-router-dom";
import useHttp from "../hooks/useHttp";
import { capitalCase } from "change-case";
import React, { useEffect, useState, useCallback } from "react";
import { fNumber } from "../util/formatNumber";
import moment from "moment";
import Map from "../components/map/Map";
import { Button, Tooltip } from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";

export default function Project() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { sendRequest } = useHttp();
  const [foundProject, setFoundProject] = useState({
    status: "",
    title: "",
    constituency: "",
    ward: "",
    department: "",
    cipd: "",
    created_at: "",
    updated_at: "",
  });

  const fetchIndicatorInfo = useCallback(async () => {
    try {
      const countyProjects = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects`
      );
      const proj = countyProjects.data.filter(
        (countyProj) => countyProj.id === +id
      );
      if (proj) {
        setFoundProject(proj[0]);
      }
    } catch (error) {}
  }, [sendRequest, id]);

  useEffect(() => {
    fetchIndicatorInfo();
  }, [fetchIndicatorInfo]);

  return (
    <>
      <BreadcrumbProject
        title={capitalCase(
          foundProject && foundProject.name ? foundProject.name : ""
        )}
      />

      <div className="project-header">
        <Container>
          <div className="project-header-inner">
            <div className="project-header-inner-left">
              <h2>
                {capitalCase(
                  foundProject && foundProject.name ? foundProject.name : ""
                )}
              </h2>
              <ul>
                {foundProject && foundProject.constituency ? (
                  <li>{foundProject.constituency.name}</li>
                ) : (
                  ""
                )}

                {foundProject && foundProject.ward ? (
                  <li> {foundProject.ward.name}</li>
                ) : (
                  ""
                )}

                {foundProject && foundProject.department ? (
                  <li> {foundProject.department.name}</li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <div className="project-header-inner-right">
              <Tooltip title="Go to projects page">
                <Button
                  variant="outlined"
                  startIcon={<KeyboardBackspace />}
                  color="success"
                  onClick={() => navigate("/projects")}
                >
                  Back
                </Button>
              </Tooltip>
            </div>
          </div>
        </Container>
      </div>

      <div className="wrapper">
        <div className="content-section">
          <Container>
            <div className="project-details">
              <Tabs defaultActiveKey="project-info-map" id="project-info-tabs">
                <Tab
                  eventKey="project-info-map"
                  title="Project Information &amp; Map"
                >
                  <Row>
                    <Col lg="6">
                      <div className="project-info">
                        <div className="project-info-item">
                          <h6>Description of Activities</h6>
                          <p>
                            {foundProject && foundProject.activities
                              ? foundProject.activities
                              : "-"}
                          </p>
                        </div>
                        <div className="project-info-item">
                          <h6>Financial Year</h6>
                          <p>
                            {foundProject && foundProject.year
                              ? foundProject.year
                              : "-"}
                          </p>
                        </div>
                        <div className="project-info-item">
                          <h6>Budget (KES)</h6>
                          <p>
                            {foundProject && foundProject.amount
                              ? fNumber(foundProject.amount)
                              : "-"}
                          </p>
                        </div>
                        <div className="project-info-item">
                          <h6>Contractor / Consultant</h6>
                          <p>
                            {foundProject && foundProject.contractor
                              ? foundProject.contractor
                              : "-"}
                          </p>
                        </div>
                        <div className="project-info-item">
                          <h6>CIDP</h6>
                          <p>
                            {foundProject && foundProject.cipd
                              ? foundProject.cipd
                              : "-"}
                          </p>
                        </div>
                        <div className="project-info-item">
                          <h6>Impact</h6>
                          <p>
                            {foundProject && foundProject.impact
                              ? foundProject.impact
                              : "-"}
                          </p>
                        </div>
                        <div className="project-info-item">
                          <h6>Feedback</h6>
                          <p>
                            {foundProject && foundProject.feedback
                              ? foundProject.feedback
                              : "-"}
                          </p>
                        </div>
                        <div className="project-info-item">
                          {/* <h6>Status</h6> */}
                          <span className="status-badge bg-warning">
                            {foundProject && foundProject.status
                              ? foundProject.status
                              : ""}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="project-map">
                        <Map />
                      </div>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="project-images" title="Images" disabled={true}>
                  <div className="project-images"></div>
                </Tab>
              </Tabs>
              <div className="oc-card mt-4">
                <h6>About this project</h6>
                {/* <p className="text-muted">
                  {foundIndicator ? foundIndicator.description : ""}
                </p> */}
                <ul className="metadata">
                  <li>
                    <p>Last updated on:</p>
                    <span>
                      {foundProject
                        ? moment(foundProject.updated_at).format("LLL")
                        : ""}
                    </span>
                  </li>
                  <li>
                    <p>Created on:</p>
                    <span>
                      {foundProject
                        ? moment(foundProject.created_at).format("LLL")
                        : ""}
                    </span>
                  </li>
                  <li>
                    <p>Update frequency:</p>
                    <span>Quarterly</span>
                  </li>
                  <li>
                    <p>Source:</p>
                    <span>
                      Department of{" "}
                      {foundProject && foundProject.department
                        ? foundProject.department.name
                        : ""}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
