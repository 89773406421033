import { faFilter, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
// material
import { styled } from '@mui/material/styles';
import {
  Tab,
  Box,
  Tabs,
  Autocomplete,
  Chip,
  TextField,
  Button,
} from '@mui/material';
// components
import ProjectFilters from './ProjectFilters';
import Export from './ExportProjects'
import ProjectTable from './ProjectsTable';
import ProjectList from './ProjectList';
import ProjectReports from './ProjectsReports';
import MapProjects from '../components/map/MapProjects';
import { Alert } from "@mui/material";
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const MapWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 0,
  height: 700,
  overflow: 'hidden',
  position: 'relative',
  marginBottom: 2,
  borderRadius: theme.shape.borderRadius,
  // '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
  //   display: 'none'
  // }
}));

// const findCommonElements = (array1, array2) => {
//   const set1 = new Set(array1);
//   const commonElements = array2.filter((element) => set1.has(element));
//   return commonElements;
// }

const ProjectDetails = ({
  countyProjects,
  projectsCount,
  numberOfProjects,
  handleFilterProjects,
  handleClearFilters,
  loadingState,
  projectsAmount,
}) => {
  const [filtersSelected, setFiltersSelected] = useState([]);

  const handleSelectedFilters = (projectFilters) => {
    setFiltersSelected(projectFilters);
  };

  const handleUpdateFilter = (allFilters) => {
    //setFiltersSelected(prev=>[...new Set([...prev,...allFilters])]);
  }

  const PROFILE_TABS = [
    {
      value: 'filters',
      icon: <FontAwesomeIcon icon={faFilter} />,
      component: (
        <ProjectFilters
          handleSelectedFilters={handleSelectedFilters}
          handleClearFilters={() => {
            handleClearFilters();
            setFiltersSelected([]);
          }}
          handleUpdateFilter={handleUpdateFilter}
          handleFilterProjects={handleFilterProjects}
          loadingState={loadingState}
        />
      ),
    },
    {
      value: 'export',
      icon: <FontAwesomeIcon icon={faFileExport} />,
      component: <Export projectData={countyProjects} />,
    },
  ];

  const [currentTab, setCurrentTab] = useState('filters');
  const [selectedComponent, setSelectedComponent] = useState({
    id: 1,
    name: 'Report',
  });
  const [inputValue, setInputValue] = useState('');

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const [filterShowing, setFilterShowing] = useState(true);

  const filterStyle = {
    display: filterShowing ? 'block' : 'none',
  }

  //change the filter display
  const handleResize = () => {
    if (window.innerWidth < 992) {
      setFilterShowing(false);
    } else {
      setFilterShowing(true);
    }
  }

  //component did mount
  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  //component unmout
  React.useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <>
      {selectedComponent.name !== "Report" ? (
        <div>
          <div className="oc-sidebar-back"></div>
          <div className="oc-sidebar" style={
            filterStyle
          }>
            <div className="oc-sidebar-top">
              <Tabs
                value={currentTab}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={handleChangeTab}
              >
                {PROFILE_TABS.map((tab) => (
                  <Tab
                    className="sidebar-tab"
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={tab.value}
                  />
                ))}
              </Tabs>
            </div>
            <div className="oc-sidebar-main">
              {PROFILE_TABS.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        className={
          selectedComponent.name !== "Report" ? "ic-content" : "ic-content-full"
        }
      >
        <Alert severity="info" color="info">Project data is sourced from the <a href="https://nandicounty.go.ke/departments/department-of-finance-and-economic-planning/" target="_blank" rel="nofollow noreferrer">Department of Finance and Economic Planning<FontAwesomeIcon className="ms-1" icon={faArrowUpRightFromSquare} /></a></Alert>

        <div className="ic-content-header">
          <div className="ic-content-header-inner">
            <h6 className="ic-content-title">{loadingState ? 'Fetching total no. of projects...' : `${numberOfProjects} projects found`}</h6>
            <Autocomplete
              className="view-filter"
              disableClearable
              size="small"
              options={[
                { id: 1, name: "Report" },
                { id: 2, name: "Data Table" },
                { id: 3, name: "List" },
              ]}
              value={selectedComponent}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onChange={(event, newValue) => {
                setSelectedComponent(newValue);
              }}
              getOptionLabel={(option) => option.name}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option.name}
                    size="small"
                    label={option.name}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Change view"
                  label="Change View"
                />
              )}
            />
          </div>
        </div>
        <div className="ic-body">
          <div className="ic-body-content">
            <div className={filtersSelected.length !== 0 ? "mb-4" : "mb-0"}>
              {filtersSelected.length !== 0 ? (
                <h6 className="selected-filters">Applied filters:</h6>
              ) : (
                ""
              )}
              {filtersSelected.map((filterSelected, index) => (
                <Chip key={index} size="small" label={filterSelected} />
              ))}
            </div>
            {selectedComponent && selectedComponent.name === "Map" && (
              <Box>
                <MapWrapperStyle>
                  <MapProjects />
                </MapWrapperStyle>
              </Box>
            )}
            {selectedComponent && selectedComponent.name === "Data Table" && (
              <>
                <ProjectTable
                  countyProjects={countyProjects}
                  numberOfProjects={numberOfProjects}
                />

                <div className="filter-toggle mt-3">
                  <Button onClick={() => setFilterShowing(!filterShowing)} variant="contained" fullWidth disableElevation endIcon={<FilterAltIcon />}>Filters/Exports</Button>
                  {/* <Button onClick={() => setFilterShowing(!filterShowing)} variant="contained" color="secondary" fullWidth disableElevation endIcon={<IosShareIcon />}>Export</Button> */}
                </div>
              </>

            )}
            {selectedComponent && selectedComponent.name === "List" && (
              <>
                <ProjectList
                  projects={countyProjects}
                  numberOfProjects={numberOfProjects}
                />

                <div className="filter-toggle mt-3">
                  <Button onClick={() => setFilterShowing(!filterShowing)} variant="contained" fullWidth disableElevation endIcon={<FilterAltIcon />}>Filters/Exports</Button>
                  {/* <Button onClick={() => setFilterShowing(!filterShowing)} variant="contained" color="secondary" fullWidth disableElevation endIcon={<IosShareIcon />}>Export</Button> */}
                </div>

              </>

            )}
            {selectedComponent && selectedComponent.name === "Report" && (
              <ProjectReports projectsBySubCounty={projectsAmount} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;
