import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumb from "../ui-components/Breadcrumb";
import useFetchSectors from "../hooks/useFetchSectors";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Cow from "../images/icons/cow.png";

export default function Sectors() {
  const { sectors } = useFetchSectors();

  return (
    <>
      <Breadcrumb title="Sectors"></Breadcrumb>
      <div className="wrapper">
        <div className="content-section">
          <Container>
            <h1 className="styled-heading styled-heading-large">All Sectors</h1>

            <div className="sector-cards">
              {sectors.length === 0 ? (
                <CircularProgress sx={{ color: "green" }} />
              ) : (
                <>
                  {sectors.length > 0 &&
                    sectors.map((sector, index) => (
                      <div className="sector-card" key={index}>
                        <div className="sector-card-top">
                          <div className="sector-card-top-icon">
                            <Link
                              style={{
                                pointerEvents:
                                  sector.indicators_count === 0 ? "none" : "",
                              }}
                              to={`/sector/${sector.id}`}
                            >
                              <img
                                src={sector.icon}
                                alt={sector.name}
                                width="47"
                                height="47"
                              />
                            </Link>
                          </div>
                          <div className="sector-card-top-info">
                            <h6>
                              <Link
                                style={{
                                  pointerEvents:
                                    sector.indicators_count === 0 ? "none" : "",
                                }}
                                to={`/sector/${sector.id}`}
                                // to={`/summary`}
                              >
                                {sector.name}
                              </Link>
                            </h6>
                            <p>{sector.indicators_count} indicators</p>
                          </div>
                        </div>
                        <div className="sector-card-bottom">
                          <ul>
                            <li>
                              <span>Last updated: </span>
                              {sector?.updated_at
                                ? moment(sector.updated_at).format("MMM Do YY")
                                : "-"}
                            </li>
                            <li>
                              <span>Published: </span>
                              {sector?.created_at
                                ? moment(sector?.created_at).format("MMM Do YY")
                                : "-"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  <div className="sector-card" key={sectors.length}>
                    <div className="sector-card-top">
                      <div className="sector-card-top-icon">
                        <Link
                          // style={{
                          //   pointerEvents:
                          //     sector.indicators.length === 0 ? "none" : "",
                          // }}
                          to={`/sector/Digifarm`}
                        >
                          <img
                            src={Cow}
                            alt={"Digifarm"}
                            width="47"
                            height="47"
                          />
                        </Link>
                      </div>
                      <div className="sector-card-top-info">
                        <h6>
                          <Link
                            // style={{
                            //   pointerEvents:
                            //     sector.indicators.length === 0 ? "none" : "",
                            // }}
                            to={`/sector/Digifarm`}
                            // to={`/summary`}
                          >
                            {"Digifarm"}
                          </Link>
                        </h6>
                        <p> </p>
                      </div>
                    </div>
                    <div className="sector-card-bottom">
                      <ul>
                        <li>
                          <span>Last updated: June 24th 23 </span>
                          {/* {
                          sectors.sector?.indicators?.[0]?.updated_at &&
                            moment(sectors.sector.indicators[0].updated_at).format("MMM Do YY")
                          } */}
                        </li>
                        <li>
                          <span>Published: May 25th 23</span>
                          {/* {
                      sectors.sector?.indicators?.[0]?.created_at &&
                            moment(sectors.sector.indicators[0].created_at).format("MMM Do YY")
                          } */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
