import { filter } from "lodash";
import React, { useState } from "react";
import { TextField, TablePagination } from "@mui/material";
import ProjectListItem from "./ProjectListItem";
// import Scrollbar from '../../Scrollbar';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_project) =>
        _project.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const ProjectList = ({ projects, numberOfProjects }) => {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    //setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredProjects = applySortFilter(
    projects,
    getComparator("asc", "name"),
    filterName
  );

  return (
    <>
      <div className="mb-4">
        <TextField
          fullWidth
          size="small"
          label="Search for projects"
          className="bg-white"
          placeholder="Start typing..."
          value={filterName}
          onChange={handleFilterByName}
        />
      </div>

      <div className="project-cards">
        {/* <Scrollbar sx={{maxHeight: 500}}> */}
        {filteredProjects
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((project) => (
            <ProjectListItem
              key={project.id}
              projectItem={project}
              page={page}
            />
          ))}
        {/* </Scrollbar> */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={numberOfProjects}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>  
    </>
  );
};

export default ProjectList;
