import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Autocomplete, Chip, TextField } from "@mui/material";
import moment from "moment";
// components
import IndicatorTable from "./IndicatorTable";
import Chart from "./SectorIndicatorCharts/Chart";
import Chart2 from "./SectorIndicatorCharts/Chart2";
import Chart3 from "./SectorIndicatorCharts/Chart3";
import Chart4 from "./SectorIndicatorCharts/Chart4";
import Chart5 from "./SectorIndicatorCharts/Chart5";
import useHttp from "../hooks/useHttp";
import SectorSummary from "../views/SectorSummary";
import useFetchSectors from "../hooks/useFetchSectors";

const IndicatorDetails = ({
  itemIndicatorOptions,
  handleFilterIndicator,
  display,
  foundIndicator,
  isLoading,
  sectorId,
  handleSearchedIndicator,
  sectorColor,
  foundSector,
  indicatorInfo,
}) => {
  const [selectedItemIndicator, setSelectedItemIndicator] = useState();
  const [inputItemIndicator, setInputItemIndicator] = useState("");
  const [foundParams] = useSearchParams();
  const [sectorSummary, setSectorSummary] = useState(null);
  const [sectorName, setSectorName] = useState("");
  const { isLoading: loading, sendRequest } = useHttp();
  const { sectors } = useFetchSectors();
  const [colorMap, setColors] = useState([]);

  const fetchSubCountyColors = useCallback(async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/boundary`
      );
      if (res && res.data) {
        setColors(res.data.map((a) => a.color));
      }
    } catch (error) {}
  }, [sendRequest]);
  useEffect(() => {
    fetchSubCountyColors();
  }, [fetchSubCountyColors]);

  const fetchSectorSummary = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/sectors/dashboard/${sectorId}`
      );

      if (response && response.data) {
        setSectorSummary(response.data);
      }
    } catch (error) {}
  }, [sendRequest, sectorId]);

  useEffect(() => {
    fetchSectorSummary();
  }, [fetchSectorSummary]);

  useEffect(() => {
    const foundSector = sectors.filter((sector) => sector.id === +sectorId);
    if (foundSector.length > 0) {
      setSectorName(foundSector[0].name);
    } else {
      setSectorName("");
    }
  }, [sectors, sectorId]);

  useEffect(() => {
    const indicatorKey = foundParams.get("index");
    if (indicatorKey && itemIndicatorOptions.length > 0) {
      const foundInd = itemIndicatorOptions.filter(
        (itmInd) => itmInd.value === indicatorKey
      );
      handleSearchedIndicator(indicatorKey);
      setSelectedItemIndicator(foundInd[0]);
      setInputItemIndicator(foundInd[0].name);
    }
  }, [foundParams, handleSearchedIndicator, itemIndicatorOptions]);

  // const PROFILE_TABS = [
  //   {
  //     value: 'filters',
  //     icon: <FontAwesomeIcon icon={faFilter} />,
  //     component: (
  //       <IndicatorFilters selectedItemIndicator={selectedItemIndicator}>
  //         <Autocomplete
  //           freeSolo
  //           options={itemIndicatorOptions}
  //           //value={selectedItemIndicator}
  //           inputValue={inputItemIndicator}
  //           onInputChange={(event, newInputValue) => {
  //             setInputItemIndicator(newInputValue);
  //           }}
  //           onChange={(event, newValue) => {
  //
  //             setSelectedItemIndicator(newValue);
  //             handleFilterIndicator(newValue ? newValue.value : '');
  //           }}
  //           getOptionLabel={(option) => option.value + ' - ' + option.name}
  //           renderTags={(value, getTagProps) =>
  //             value.map((option, index) => (
  //               <Chip
  //                 {...getTagProps({ index })}
  //                 key={option.name}
  //                 size='small'
  //                 label={option.name}
  //               />
  //             ))
  //           }
  //           renderInput={(params) => (
  //             <div className='mb-3 mt-3'>
  //               <TextField
  //                 {...params}
  //                 variant='outlined'
  //                 label='Select Indicator'
  //               />
  //             </div>
  //           )}
  //         />
  //       </IndicatorFilters>
  //     ),
  //   },
  //   {
  //     value: 'export',
  //     icon: <FontAwesomeIcon icon={faFileExport} />,
  //     component: <Export indicatorData={indicatorInfo} />,
  //   },
  // ];

  //const [currentTab, setCurrentTab] = useState('filters');
  const [selectedComponent, setSelectedComponent] = useState({
    id: 1,
    name: "Data Table",
  });
  const [inputValue, setInputValue] = useState("");

  // const handleChangeTab = (event, newValue) => {
  //   setCurrentTab(newValue);
  // };

  return (
    <>
      {/* <div className='oc-sidebar-back'></div>

      <div className='oc-sidebar'>
        <div className='oc-sidebar-top'>
          <Tabs
            value={currentTab}
            scrollButtons='auto'
            variant='scrollable'
            allowScrollButtonsMobile
            onChange={handleChangeTab}
          >
            {PROFILE_TABS.map((tab) => (
              <Tab
                className='sidebar-tab'
                disableRipple
                key={tab.value}
                value={tab.value}
                icon={tab.icon}
                label={tab.value}
              />
            ))}
          </Tabs>
        </div>

        <div className='oc-sidebar-main'>
          {PROFILE_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </div>
      </div> */}
      <div className="ic-content-header">
        <div className="ic-content-header-inner">
          <Container>
            <h6 className="ic-content-title">
              {selectedItemIndicator
                ? selectedItemIndicator.value +
                  " - " +
                  selectedItemIndicator.name
                : sectorName.length > 0
                ? `Overview of ${sectorName}`
                : ""}
            </h6>
          </Container>
        </div>
      </div>
      <div className="content-section">
        <Container>
          <div className="mb-4">
            <Row>
              <Col lg="6" className="mb-3 mb-md-0">
                <Autocomplete
                  options={itemIndicatorOptions}
                  inputValue={inputItemIndicator}
                  onInputChange={(event, newInputValue) => {
                    setInputItemIndicator(newInputValue);
                  }}
                  onChange={(event, newValue) => {
                    setSelectedItemIndicator(newValue);
                    handleFilterIndicator(newValue ? newValue.value : "");
                  }}
                  getOptionLabel={(option) =>
                    option.value + " - " + option.name
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={option.name}
                        size="small"
                        label={option.name}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                        //variant='outlined'
                        label="Type or click to select an indicator"
                      />
                    </div>
                  )}
                />
              </Col>
              <Col lg="6">
                {selectedItemIndicator && (
                  <Autocomplete
                    disableClearable
                    className="view-filter"
                    //size='small'
                    options={[
                      { id: 1, name: "Data Table" },
                      { id: 2, name: "Bar Chart" },
                      // { id: 3, name: 'Line Chart' },
                    ]}
                    value={selectedComponent}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                      setSelectedComponent(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={option.name}
                          size="small"
                          label={option.name}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Change view"
                        label="View"
                      />
                    )}
                  />
                )}
              </Col>
            </Row>
          </div>
          {selectedItemIndicator ? (
            <Box>
              {
                selectedComponent &&
                  selectedComponent.name === "Bar Chart" &&
                  display &&
                  display.data.length > 0 &&
                  display.data[0].group == null &&
                  display.data[0].ward == null &&
                  display.data[0].constituency != null && (
                    <div className="oc-card">
                      <Chart
                        apiResponse={display}
                        isLoading={isLoading}
                        type="bar"
                        colorMap={colorMap}
                      />
                    </div>
                  )

                // <IndicatorBar dataOnCharts={dataOnCharts} />
              }
              {
                selectedComponent &&
                  selectedComponent.name === "Bar Chart" &&
                  display &&
                  display.data.length > 0 &&
                  display.data[0].group != null &&
                  display.data[0].ward == null &&
                  display.data[0].constituency != null && (
                    <div className="oc-card">
                      <Chart2
                        apiResponse={display}
                        isLoading={isLoading}
                        type="bar"
                        colorMap={colorMap}
                      />
                    </div>
                  )

                // <IndicatorBar dataOnCharts={dataOnCharts} />
              }
              {
                selectedComponent &&
                  selectedComponent.name === "Bar Chart" &&
                  display &&
                  display.data.length > 0 &&
                  display.data[0].group == null &&
                  display.data[0].ward == null &&
                  display.data[0].constituency == null && (
                    <div className="oc-card">
                      <Chart3
                        apiResponse={display}
                        isLoading={isLoading}
                        type="bar"
                        colorMap={colorMap}
                      />
                    </div>
                  )
                // <IndicatorBar dataOnCharts={dataOnCharts} />
              }
              {
                selectedComponent &&
                  selectedComponent.name === "Bar Chart" &&
                  display &&
                  display.data.length > 0 &&
                  display.data[0].group != null &&
                  display.data[0].ward == null &&
                  display.data[0].constituency == null && (
                    <div className="oc-card">
                      <Chart4
                        apiResponse={display}
                        isLoading={isLoading}
                        type="bar"
                        colorMap={colorMap}
                      />
                    </div>
                  )

                // <IndicatorBar dataOnCharts={dataOnCharts} />
              }
              {
                selectedComponent &&
                  selectedComponent.name === "Bar Chart" &&
                  display &&
                  display.data.length > 0 &&
                  display.data[0].group == null &&
                  display.data[0].ward != null &&
                  display.data[0].constituency != null && (
                    <div className="oc-card">
                      <Chart5
                        apiResponse={display}
                        isLoading={isLoading}
                        type="bar"
                        colorMap={colorMap}
                      />
                    </div>
                  )

                // <IndicatorBar dataOnCharts={dataOnCharts} />
              }
              {
                selectedComponent &&
                  selectedComponent.name === "Line Chart" &&
                  display &&
                  display.data.length > 0 &&
                  display.data[0].group == null &&
                  display.data[0].ward == null &&
                  display.data[0].constituency != null && (
                    <Chart
                      apiResponse={display}
                      isLoading={isLoading}
                      type="line"
                    />
                  )

                // <IndicatorBar dataOnCharts={dataOnCharts} />
              }
              {
                selectedComponent &&
                  selectedComponent.name === "Line Chart" &&
                  display &&
                  display.data.length > 0 &&
                  display.data[0].group != null &&
                  display.data[0].ward == null &&
                  display.data[0].constituency != null && (
                    <Chart2
                      apiResponse={display}
                      isLoading={isLoading}
                      type="line"
                    />
                  )

                // <IndicatorBar dataOnCharts={dataOnCharts} />
              }
              {
                selectedComponent &&
                  selectedComponent.name === "Line Chart" &&
                  display &&
                  display.data.length > 0 &&
                  display.data[0].group == null &&
                  display.data[0].ward == null &&
                  display.data[0].constituency == null && (
                    <Chart3
                      apiResponse={display}
                      isLoading={isLoading}
                      type="line"
                    />
                  )
                // <IndicatorBar dataOnCharts={dataOnCharts} />
              }
              {
                selectedComponent &&
                  selectedComponent.name === "Line Chart" &&
                  display &&
                  display.data.length > 0 &&
                  display.data[0].group != null &&
                  display.data[0].ward == null &&
                  display.data[0].constituency == null && (
                    <Chart4
                      apiResponse={display}
                      isLoading={isLoading}
                      type="line"
                    />
                  )

                // <IndicatorBar dataOnCharts={dataOnCharts} />
              }
              {
                selectedComponent &&
                  selectedComponent.name === "Line" &&
                  display &&
                  display.data.length > 0 &&
                  display.data[0].group == null &&
                  display.data[0].ward != null &&
                  display.data[0].constituency != null && (
                    <Chart5
                      apiResponse={display}
                      isLoading={isLoading}
                      type="line"
                    />
                  )

                // <IndicatorBar dataOnCharts={dataOnCharts} />
              }
              {selectedComponent && selectedComponent.name === "Data Table" && (
                <IndicatorTable
                  isLoading={isLoading}
                  display={display}
                  selectedItemIndicator={selectedItemIndicator}
                  data={indicatorInfo}
                />
              )}
              {/* {selectedComponent &&
                  selectedComponent.name === "Line Chart" && (
                    <IndicatorLine dataOnCharts={dataOnCharts} />
                  )} */}
              <div className="oc-card mt-4">
                <h6>About this indicator</h6>
                <p className="text-muted">
                  {foundIndicator ? foundIndicator.description : ""}
                </p>
                <ul className="metadata">
                  <li>
                    <p>Last updated on:</p>
                    <span>
                      {foundIndicator
                        ? moment(foundIndicator.updatedAt).format("LLL")
                        : ""}
                    </span>
                  </li>
                  <li>
                    <p>Created on:</p>
                    <span>
                      {foundIndicator
                        ? moment(foundIndicator.createdAt).format("LLL")
                        : ""}
                    </span>
                  </li>
                  <li>
                    <p>Update frequency:</p>
                    <span>Annual</span>
                  </li>
                  <li>
                    <p>Source:</p>
                    <span>{foundIndicator ? foundIndicator.source : ""}</span>
                  </li>
                </ul>
              </div>
            </Box>
          ) : (
            <SectorSummary
              sectorId={sectorId}
              sectorSummary={sectorSummary}
              sectorName={sectorName}
              loading={loading}
              itemIndicatorOptions={itemIndicatorOptions}
              setSelectedItemIndicator={setSelectedItemIndicator}
              setInputItemIndicator={setInputItemIndicator}
              handleFilterIndicator={handleFilterIndicator}
              sectorColor={sectorColor}
              foundSector={foundSector}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default IndicatorDetails;
