import { useState, useEffect, useCallback } from 'react';
//hooks
import useHttp from './useHttp';
// ----------------------------------------------------------------------

const projectStatus = {
  complete: 'Complete',
  notStarted: 'Not started',
  tenderingStage: 'Tendering stage',
  incomplete: 'Incomplete',
};

const useFetchProjects = () => {
  const [projects, setProjects] = useState([]);
  const [separatedProjects, setSeparatedProjects] = useState([]);

  const { isLoading, sendRequest } = useHttp();

  const fetchFilterSources = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects`
      );
      if (response && response.data && response.data.length > 0) {
        setProjects(response.data);
        const { notStarted, complete, tenderingStage, incomplete } =
          projectStatus;
        const nsPs = response.data.filter((prj) => prj.status === notStarted);
        const cPs = response.data.filter((prj) => prj.status === complete);
        const cPts = response.data.filter(
          (prj) => prj.status === tenderingStage
        );
        const incPs = response.data.filter((prj) => prj.status === incomplete);

        setSeparatedProjects([
          { status: complete, projectCount: cPs.length },
          { status: incomplete, projectCount: incPs.length },
          { status: notStarted, projectCount: nsPs.length },
          { status: tenderingStage, projectCount: cPts.length },
        ]);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchFilterSources();
  }, [fetchFilterSources]);

  return { isLoading, projects, separatedProjects };
};

export default useFetchProjects;
