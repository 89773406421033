import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";

const Breadcrumb = ({ title, children }) => (
  <div className="breadcrumbs">
    <div className="container">
      <div className="flex flex-row justify-between">
        <ul>
          <li>
            <Link to="/">
              <FontAwesomeIcon icon={faHouseChimney} />
            </Link>
          </li>
          <li>
            <span>{title}</span>
          </li>
        </ul>
        {children}
      </div>
    </div>
  </div>
);

export default Breadcrumb;
