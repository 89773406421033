import { useState, useEffect, useCallback } from "react";
//hooks
import useHttp from "./useHttp";
// ----------------------------------------------------------------------
// https://api.sabasi.mobi/api/nandi/geo?sector=Health

function getTypeArray(apiResponse) {
  const uniqueTypes = Array.from(new Set(apiResponse.map((item) => item.type)));

  return uniqueTypes.map((type, index) => ({ id: index, name: type }));
}

const useFetchFacilityTypes = (key) => {
  const [facilityTypes, setFacilityTypes] = useState([]);
  const { isLoadingFacilityTypes, sendRequest } = useHttp();

  const fetchFilterSources = useCallback(async () => {
    try {
      const response = await sendRequest(
        `https://api.sabasi.mobi/api/nandi/geo?sector=${key}`
      );
      if (response && response.data && response.data.length > 0) {
        const types = getTypeArray(response.data);
        setFacilityTypes(types);
      }
    } catch (error) {}
  }, [sendRequest, key]);

  useEffect(() => {
    fetchFilterSources();
  }, [fetchFilterSources]);

  return { isLoadingFacilityTypes, facilityTypes };
};

export default useFetchFacilityTypes;
