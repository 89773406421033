import PropTypes from 'prop-types';
// material
import { Typography } from '@mui/material';
import React from 'react';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    <>
      {/* <Typography gutterBottom align="center" variant="subtitle1">
        Not found
      </Typography> */}
      <Typography variant="body2" align="center">
        No results found
      </Typography>
    </>
  );
}
