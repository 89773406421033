import React from 'react';
import '../map/legend.css';

const Legend = () => {
    return (
        <div className="legend">
            <div>
                <p>Projects</p>
            </div>
            <div style={{ "--color": '#800026' }}>200+ </div>
            <div style={{ "--color": '#BD0026' }}>190 -199</div>
            <div style={{ "--color": '#E31A1C' }}>180 - 189</div>
            <div style={{ "--color": '#FC4E2A' }}>170 - 179</div>
            <div style={{ "--color": '#FD8D3C'}}>160 - 169</div>
            <div style={{ "--color": '#FEB24C' }}>150 - 159</div>
            <div style={{ "--color": '#FED976' }}>140 - 149</div>
            <div style={{ "--color": '#FFEDA0' }}>0 - 139</div>

        </div>
    );
}
export default Legend;