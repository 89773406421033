import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { fNumber } from "../util/formatNumber";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Alert, CircularProgress, Stack } from "@mui/material";
import SectorSummaryColumnChart from "../components/SectorSummaryCharts/SectorSummaryColumnChart";
import SectorSummaryLineChart from "../components/SectorSummaryCharts/SectorSummaryLineChart";
import "../styles/css/sector.css";
import axios from "axios";

// import json file
//import sectorSummaryData from "../../src/assets/dashboard.json";

export default function SectorSummary({ sectorColor, foundSector }) {
  const [isLoading, setIsLoading] = useState(false);
  const [cardOneMain, setCardOneMain] = useState(undefined);
  const [cardOneItems, setCardOneItems] = useState([]);

  const [cardTwoItems, setCardTwoItems] = useState(undefined);

  const [cardThreeItems, setCardThreeItems] = useState(undefined);

  const [cardFourMain, setCardFourMain] = useState(undefined);
  const [cardFourItems, setCardFourItems] = useState([]);

  const [cardFiveItems, setCardFiveItems] = useState(undefined);

  const [cardSixItems, setCardSixItems] = useState(undefined);

  useEffect(() => {
    if (foundSector && foundSector.has_summary) {
      setIsLoading(true);
      axios
        .get(
          `${
            process.env.REACT_APP_BASEDEVURL
          }/api/sectors/${foundSector.id.toString()}`
        )
        .then((res) => {
          if (res.data && res.data.data && res.data.data.summary.data) {
            const cardOne = res.data.data.summary.data
              .filter((data) => data.item === "card_one")
              .filter((itm) => itm.icon === null);
            const cardOneItem = res.data.data.summary.data
              .filter((data) => data.item === "card_one")
              .filter((itm) => itm.icon !== null)[0];

            const cardTwo = res.data.data.summary.data.filter(
              (data) => data.item === "card_two"
            )[0];

            const cardThree = res.data.data.summary.data.filter(
              (data) => data.item === "card_three"
            )[0];

            const cardFour = res.data.data.summary.data
              .filter((data) => data.item === "card_four")
              .filter((itm) => itm.icon === null);
            const cardFourItem = res.data.data.summary.data
              .filter((data) => data.item === "card_four")
              .filter((itm) => itm.icon !== null)[0];

            const cardFive = res.data.data.summary.data.filter(
              (data) => data.item === "card_five"
            )[0];
            const cardSix = res.data.data.summary.data.filter(
              (data) => data.item === "card_six"
            )[0];
            setCardOneItems(cardOne);
            setCardOneMain(cardOneItem);
            setCardTwoItems(cardTwo);
            setCardThreeItems(cardThree);
            setCardFourItems(cardFour);
            setCardFourMain(cardFourItem);

            setCardFiveItems(cardFive);
            setCardSixItems(cardSix);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [foundSector]);

  return (
    <>
      {isLoading ? (
        <CircularProgress sx={{ color: "green" }} />
      ) : (
        <>
          {!foundSector || !foundSector.has_summary ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="info">
                Looks like there's no sector summary configuration for this
                sector.
              </Alert>
            </Stack>
          ) : (
            <Row className="mb-0 mb-md-4">
              {cardOneMain && (
                <Col className="mb-3" lg="5" md="4" sm="12">
                  <div className="oc-card">
                    <h6 className="oc-card-small-heading">
                      {cardOneMain && cardOneMain.title
                        ? cardOneMain.title
                        : ""}
                    </h6>
                    <div className="content1">
                      <div className="">
                        <Icon
                          icon={`tabler:${
                            cardOneMain && cardOneMain.icon
                              ? cardOneMain.icon
                              : "file"
                          }`}
                          width="6rem"
                          color={sectorColor}
                        />
                      </div>
                      <div className="numbers">
                        <h1
                          className="num"
                          style={{
                            color: sectorColor,
                            fontWeight: "bold",
                            fontSize: "38px",
                          }}
                        >
                          {cardOneMain ? fNumber(cardOneMain.value) : "-"}
                        </h1>
                        <h6 className="oc-card-small-heading txt1">
                          {cardOneMain ? cardOneMain.name : "-"}
                        </h6>
                      </div>
                    </div>
                    <div className="content2">
                      {cardOneItems.length > 0 &&
                        cardOneItems.map((item, index) => {
                          return (
                            <table className="border-bottom-table" key={index}>
                              <tbody>
                                <tr>
                                  <td>{item.name}</td>
                                  <td
                                    className="numb"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {fNumber(item.value)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          );
                        })}
                    </div>
                  </div>
                </Col>
              )}

              {cardTwoItems && (
                <Col className="mb-3" lg="7" md="8" sm="12">
                  <div className="oc-card">
                    <h5 className="oc-card-small-heading">
                      {cardTwoItems ? cardTwoItems.title : ""}
                    </h5>
                    <SectorSummaryColumnChart
                      dataset={cardTwoItems ? cardTwoItems.dataset : []}
                      categories={cardTwoItems ? cardTwoItems.categories : []}
                      colors={[sectorColor]}
                      xAxisLabel={cardTwoItems ? cardTwoItems.title : ""}
                      yaxisLabel={""}
                    />
                  </div>
                </Col>
              )}

              {cardThreeItems && (
                <Col className="mb-3" lg="12" md="12" sm="12">
                  <div className="oc-card">
                    <h5 className="oc-card-small-heading">
                      {cardThreeItems ? cardThreeItems.title : ""}
                    </h5>
                    <SectorSummaryLineChart
                      nameOfSeries={cardThreeItems ? cardThreeItems.title : ""}
                      categories={
                        cardThreeItems ? cardThreeItems.categories : []
                      }
                      dataset={cardThreeItems ? cardThreeItems.dataset : []}
                      xAxisLabel={cardThreeItems ? cardThreeItems.title : ""}
                      yaxisLabel=""
                      colors={[sectorColor]}
                      cardSixDataSet={[]}
                      currentCard={cardThreeItems ? cardThreeItems.item : ""}
                      type="treemap"
                    />
                  </div>
                </Col>
              )}

              {cardFourMain && (
                <Col className="mb-3" lg="5" md="4" sm="12">
                  <div className="oc-card">
                    <h6 className="oc-card-small-heading">
                      {cardFourMain && cardFourMain.title
                        ? cardFourMain.title
                        : ""}
                    </h6>
                    <div className="content1">
                      <div className="">
                        <Icon
                          icon={`tabler:${
                            cardFourMain && cardFourMain.icon
                              ? cardFourMain.icon
                              : "file"
                          }`}
                          width="6rem"
                          color={sectorColor}
                        />
                      </div>
                      <div className="numbers">
                        <h1
                          className="num"
                          style={{
                            color: sectorColor,
                            fontWeight: "bold",
                            fontSize: "38px",
                          }}
                        >
                          {cardFourMain ? fNumber(cardFourMain.value) : "-"}
                        </h1>
                        <h6 className="oc-card-small-heading txt1">
                          {cardFourMain ? cardFourMain.name : "-"}
                        </h6>
                      </div>
                    </div>
                    <div className="content2">
                      {cardFourItems.length > 0 &&
                        cardFourItems.map((item, index) => {
                          return (
                            <table className="border-bottom-table" key={index}>
                              <tbody>
                                <tr>
                                  <td>{item.name}</td>
                                  <td
                                    className="numb"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {fNumber(item.value)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          );
                        })}
                    </div>
                  </div>
                </Col>
              )}

              {/* {cardFirstData.length>1 &&
                <Col className="mb-3" lg="6" md="6" sm="12">
                  <div className="oc-card">
                    <Row className="mb-0 mb-md-4">
                    <h6 className="oc-card-small-heading">{cardFirstData[1].title}</h6>
                    {cardFirstData.length>1 && cardFirstData.slice(1).map((item, index) => {
                        return item.data.filter(it=>it.icon!==null).map(i=><Col className="mb-3 mt-5" lg="6" md="6" sm="12" key={Math.random().toString()}>
                              <div className="content1">
                                <div className="">
                                  <img
                                    src={sectorIcons[i.icon]}
                                    alt={""}
                                    width="64"
                                    height="64"
                                  />
                                </div>
                                <div className="numbers">
                      <h1 className="num" style={{ color: sectorSummaryData[0].sector.colors[0], fontWeight: "bold", fontSize: "38px" }} >
                        {isNaN(i.data[0]) ? "-" : fNumber(i.data[0])}
                      </h1>
                      <h6 className="oc-card-small-heading txt1">{i.name}</h6>
                    </div>
                      </div>
                      
                      </Col>)
                      })}
                      {cardFirstData.length>1 && cardFirstData.slice(1).map((item, index) => {
                        return item.data.filter(it=>it.icon===null).map((it, index) => {
                                  return <Col lg="6" md="6" sm="12" key={index}>   
                                  <table className="border-bottom-table" key={index}>
                                      <tbody>
                                    <tr>
                                      <td>{it.name}</td>
                                      <td className="numb" style={{ fontWeight: "bold" }}>{isNaN(it.data) ? it.data[0] : fNumber(it.data[0])}</td>
                                    </tr>
                                      </tbody>
                                  </table>
                                  </Col>
                              })
                      })}
                    </Row>
                  </div>
                </Col>} */}

              {cardFiveItems && (
                <Col className="mb-3" lg="6" md="6" sm="12">
                  <div className="oc-card">
                    <h5 className="oc-card-small-heading">
                      {cardFiveItems ? cardFiveItems.title : ""}
                    </h5>
                    <SectorSummaryLineChart
                      dataset={cardFiveItems ? cardFiveItems.dataset : []}
                      categories={cardFiveItems ? cardFiveItems.categories : []}
                      colors={[sectorColor]}
                      xAxisLabel={cardFiveItems ? cardFiveItems.title : ""}
                      yaxisLabel={""}
                      cardSixDataSet={[]}
                      currentCard={cardFiveItems ? cardFiveItems.item : ""}
                      nameOfSeries={cardFiveItems ? cardFiveItems.title : ""}
                      type="line"
                    />
                  </div>
                </Col>
              )}

              {cardSixItems && (
                <Col className="mb-3" lg="12" md="12" sm="12">
                  <div className="oc-card">
                    <h5 className="oc-card-small-heading">
                      {cardSixItems ? cardSixItems.title : ""}
                    </h5>
                    <SectorSummaryLineChart
                      nameOfSeries={cardSixItems ? cardSixItems.title : ""}
                      categories={cardSixItems ? cardSixItems.categories : []}
                      dataset={[]}
                      colors={[sectorColor]}
                      yaxisLabel={""}
                      cardSixDataSet={
                        cardSixItems.dataset ? cardSixItems.dataset : []
                      }
                      xAxisLabel={cardSixItems ? cardSixItems.title : ""}
                      currentCard={cardSixItems ? cardSixItems.item : ""}
                      type="line"
                    />
                  </div>
                </Col>
              )}
            </Row>
          )}
        </>
      )}
    </>
  );
}
