import { LinearProgress } from "@mui/material";
import useHttp from "../hooks/useHttp";
import { useCallback, useEffect, useState } from "react";
import { getHealthData, resolveHours } from "./MapPopUp";
import {
  Bathtub,
  Close,
  Mail,
  PhoneCallback,
  PinDrop,
  QuestionMark,
  WaterRounded,
  WbIncandescent,
  Whatshot,
} from "@mui/icons-material";

export const getUtilities = (utils) =>
  utils.split(",").map((util) => util.trim());

export const getQuality = (rating) => {
  switch (rating) {
    case 1:
      return "Very Poor";
    case 2:
      return "Poor";
    case 3:
      return "Fair";
    case 4:
      return "Good";
    case 5:
      return "Very Good";
    default:
      return "-";
  }
};

export const getRating = (infrastructure) => {
  switch (infrastructure) {
    case "Very Poor":
      return 1;
    case "Poor":
      return 2;
    case "Fair":
      return 3;
    case "Good":
      return 4;
    case "Excellent":
      return 5;
    default:
      return 0;
  }
};
// Incinerator
const resolveIcon = (util) => {
  if (util === "Clean Water") {
    return <WaterRounded sx={{ color: "white" }} />;
  } else if (util === "Electricity") {
    return <WbIncandescent sx={{ color: "white" }} />;
  } else if (util === "Washrooms") {
    return <Bathtub sx={{ color: "white" }} />;
  } else if (util === "Incinerator") {
    return <Whatshot sx={{ color: "white" }} />;
  } else {
    return <QuestionMark sx={{ color: "white" }} />;
  }
};

const handleNoContactInfo = (contactInfo) => {
  if (contactInfo.trim() === "-:-") {
    return "No information";
  }
  return contactInfo;
};

const getUtils = (fetchedEducationData, util) => {
  return !getHealthData(fetchedEducationData).utilities.includes(util);
};

const EducationDetails = ({ setIsVisible, educationCode }) => {
  const { isLoading: isLoadingEducationData, sendRequest } = useHttp();
  const [fetchedEducationData, setFetchedEducationData] = useState(null);

  const fetchData = useCallback(
    async (educationCode) => {
      try {
        const res = await sendRequest(
          `https://api.sabasi.mobi/api/nandi/data/${educationCode}`
        );
        if (res && res.data) {
          setFetchedEducationData(res.data);
        }
      } catch (error) {}
    },
    [sendRequest]
  );

  useEffect(() => {
    fetchData(educationCode);
  }, [fetchData, educationCode]);

  return isLoadingEducationData ? (
    <LinearProgress />
  ) : (
    <div className="flex flex-col bg-white p-6">
      <div className="grid justify-items-end -mt-4 -mr-4">
        <Close
          size={16}
          onClick={() => setIsVisible(false)}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="mt-0 text-lg">
            {getHealthData(fetchedEducationData).facility}
          </h3>
          <p className="mt-0 text-sm">
            {getHealthData(fetchedEducationData).type}
          </p>
        </div>
        {resolveHours(getHealthData(fetchedEducationData).open_hours)}
      </div>
      <div className="mt-0 mb-2">
        <hr className="h-px border-gray-500 border-0"></hr>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <div className="text-sm">
            {" "}
            Facility <br />
            Owner{" "}
            <div className="text-xl font-bold mt-4">
              {getHealthData(fetchedEducationData).owner}
            </div>
          </div>
          <div class="flex items-center">
            <div class="h-full border-l border-gray-300"></div>
          </div>
          <div className="text-sm">
            {" "}
            Infrastructure <br />
            Quality{" "}
            <div className="text-xl font-bold mt-4">
              {getHealthData(fetchedEducationData).infrastructure}
            </div>
          </div>
          <div class="flex items-center">
            <div class="h-full border-l border-gray-300"></div>
          </div>
          <div className="text-sm">
            {" "}
            Distance <br />
            Coverage{" "}
            <div className="text-xl font-bold mt-4">
              {getHealthData(fetchedEducationData).population}
            </div>
          </div>
        </div>
        <div className="mt-2 mb-2">
          <hr className="h-px border-gray-500 border-0 "></hr>
        </div>
        <div className="text-base mb-4 font-semibold">Available Utilities</div>
        <div className="grid grid-cols-4">
          {getUtilities("Electricity,Clean Water,Washrooms,Incinerator")
            .sort((a, b) => {
              const aIsActive = getUtils(fetchedEducationData, a) ? 1 : 0;
              const bIsActive = getUtils(fetchedEducationData, b) ? 1 : 0;

              // Sort in descending order based on whether the utility is active
              return aIsActive - bIsActive;
            })
            .map((util, idx) => (
              <div
                className={`${
                  getUtils(fetchedEducationData, util) && "opacity-20"
                } flex flex-col items-center`}
                key={idx}
              >
                <div
                  className={`bg-gray-900 h-auto p-3 flex items-center rounded`}
                >
                  {" "}
                  {resolveIcon(util)}
                </div>{" "}
                <p>{util}</p>
              </div>
            ))}
        </div>
        <div className="mb-2">
          <hr className="h-px border-gray-500 border-0"></hr>
        </div>
        <div className="mt-2 mb-2">
          <hr className="h-px border-gray-500 border-0 "></hr>
        </div>
        <div>
          <div className="text-base mb-2 font-semibold">Contacts </div>
          <div className="text-base flex flex-row mb-2 ">
            <div className="mr-4">
              <PhoneCallback />{" "}
            </div>
            <a
              href={`tel:${handleNoContactInfo(
                getHealthData(fetchedEducationData).phone
              )}`}
            >
              {handleNoContactInfo(getHealthData(fetchedEducationData).phone)}
            </a>
          </div>
          <div className="text-base flex flex-row mb-2 ">
            <div className="mr-4">
              <Mail />{" "}
            </div>
            <a
              href={`mailto: ${handleNoContactInfo(
                getHealthData(fetchedEducationData).email
              )}`}
            >
              {handleNoContactInfo(getHealthData(fetchedEducationData).email)}
            </a>
          </div>
          <div className="text-base flex flex-row mb-2 ">
            <div className="mr-4">
              <PinDrop />{" "}
            </div>
            {getHealthData(fetchedEducationData).ward}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationDetails;
