import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumb from "../ui-components/Breadcrumb";
import { CircularProgress } from "@mui/material";

export const cgds = [
  {
    id: 1,
    icon: `${process.env.REACT_APP_BASEDEVURL}/icons/health.png`,
    name: "Health and Sanitation",
  },
  {
    id: 2,
    icon: `${process.env.REACT_APP_BASEDEVURL}/icons/education.png`,
    name: "Education and Vocational Training",
  },
];

export default function CitizenGeneratedData() {
  return (
    <>
      <Breadcrumb title="Sectors"></Breadcrumb>
      <div className="wrapper">
        <div className="content-section">
          <Container>
            <h1 className="styled-heading styled-heading-large">
              Citizen Generated Data (CGD)
            </h1>

            <div className="sector-cards">
              {cgds.length === 0 ? (
                <CircularProgress sx={{ color: "green" }} />
              ) : (
                <>
                  {cgds.length > 0 &&
                    cgds.map((cgd, index) => (
                      <div className="sector-card" key={cgd.id}>
                        <div className="sector-card-top">
                          <div className="sector-card-top-icon">
                            <Link to={`/citizen-generated-data/${cgd.id}`}>
                              <img
                                src={cgd.icon}
                                alt={cgd.name}
                                width="47"
                                height="47"
                              />
                            </Link>
                          </div>
                          <div className="sector-card-top-info">
                            <h6>
                              <Link to={`/citizen-generated-data/${cgd.id}`}>
                                {cgd.name}
                              </Link>
                            </h6>
                            <p> </p>
                          </div>
                        </div>
                        <div className="sector-card-bottom">
                          <ul>
                            <li>
                              <span>
                                {`Last updated: ${new Date().toDateString()}`}{" "}
                              </span>
                            </li>
                            <li>
                              <span>{`Published: ${new Date().toDateString()}`}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
