import React from 'react'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHouseChimney} from '@fortawesome/free-solid-svg-icons'

class BreadcrumbProject extends React.Component {
    render() {
        return(
            <div className="breadcrumbs">
                <div className="container">
                    <ul>
                        <li><Link to="/"><FontAwesomeIcon icon={faHouseChimney} /></Link></li>
                        <li><Link to="/projects">Projects</Link></li>
                        <li><span title={this.props.title}>{this.props.title}</span></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default BreadcrumbProject