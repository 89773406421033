import React, { useState, useEffect, useCallback } from "react";
// material
import { Row, Col } from "react-bootstrap";
import {
  Alert,
  Box,
  Chip,
  Autocomplete,
  TextField,
  Grid,
  Stack,
} from "@mui/material";
import ExpenditureTable from "../views/tables/ExpenditureTable/ExpenditureTable";
import RevenueChartPie from "../views/tables/BudgetsTable/RevenueChart";
import RevenueGraph from "../views/tables/BudgetsTable/RevenueGraph";
import ExpenditureGraph from "../views/tables/ExpenditureTable/ExpenditureGraph";
// import { varFadeInRight, MotionInView } from "../../animate";
import RevExpeditureLine from "../views/tables/RevExpenditure";
import TopSpendDepts from "./TopSpendDepts";
import TopSpendingProjects from "./TopSpendingProjects";
import TopFiveBudgetAllocations from "./TopFiveBudgetAllocation";
import TopBudgetAbsorptionDepts from "./TopFiveBudgetAbsorption";
import BudgetsAggregatesTable from "../views/tables/BudgetsAggregatesTable/BudgetsAggregatesTable";

// ----------------------------------------------------------------------

const CATEGORIES = [
  { id: "Summary", name: "Budget Plans" },
  { id: "Revenue", name: "Actual Revenue Sources" },
  { id: "Expenditure", name: "Actual Expenditure" },
];

const sortLocale = (data) => data.sort((a, b) => b.id.localeCompare(a.id));

export default function BudgetsComponent({
  onExportToCsv,
  onExportExpenditureToCsv,
  handleData,
  revenueTrends,
  expenditureTrends,
  revenueData,
  fetchRevenueForYear,
  fetchExpenditureForYear,
  expenditureData,
  revPeriods,
  expPeriods,
  departmentSpendData,
  budgetsAggregatesData,
  budgetsAggregatesData2,
  budgetsOperationsAndMaintenance,
  budgetsTrendAnalysis,
  projects,
  expenditureItems,
  colorMap,
}) {
  const [selectedCategory, setSelectedCategory] = useState({
    id: "Summary",
    name: "Budget Plans",
  });
  const [inputValue, setInputValue] = useState("");
  const [selectedFiscal, setSelectedFiscal] = useState({
    id: "2023-2024",
    name: "2023-2024",
  });
  const [inputValueFiscal, setInputValueFiscal] = useState("");

  const fetchData = useCallback(async () => {
    if (selectedCategory.id) {
      try {
        handleData({ category: selectedCategory.id });
      } catch (error) {}
    }
  }, [selectedCategory, handleData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <h1 className="styled-heading styled-heading-large">{`Budgets - ${selectedCategory.name}`}</h1>
      {/* <h6 className="small">Select To Change View</h6> */}
      <Box>
        <Box
          className="oc-card"
          sx={{
            mb: 3,
            display: "flex",
            flexDirection: { md: "row", xs: "column", sm: "column" },
            justifyContent: "space-between",
          }}
        >
          <Autocomplete
            options={CATEGORIES}
            fullWidth
            value={selectedCategory}
            defaultValue={selectedCategory}
            onChange={(event, value) => setSelectedCategory(value)}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            disableClearable
            sx={{
              mb: { md: 0, sm: 2, xs: 2 },
              ml: { md: 0, sm: 0 },
              mr: { md: 3, sm: 0 },
              mt: { md: 0, sm: 2, xs: 2 },
            }}
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option.name}
                  size="small"
                  label={option.name}
                />
              ))
            }
            renderInput={(params) => <TextField {...params} label="View" />}
          />
          {selectedCategory && selectedCategory.id === "Summary" ? (
            <></>
          ) : (
            <Autocomplete
              options={
                selectedCategory && selectedCategory.id === "Revenue"
                  ? sortLocale(revPeriods)
                  : sortLocale(expPeriods)
              }
              disableClearable
              value={selectedFiscal}
              defaultValue={selectedFiscal}
              inputValue={inputValueFiscal}
              onChange={(event, value) => {
                setSelectedFiscal(value);
                fetchRevenueForYear(value);
                fetchExpenditureForYear(value);
                onExportExpenditureToCsv(value);
                onExportToCsv(value);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValueFiscal(newInputValue);
              }}
              fullWidth
              sx={{ ml: 0, mr: { md: 3, sm: 0 } }}
              getOptionLabel={(option) => option.name}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option.name}
                    size="small"
                    label={option.name}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Fiscal Year" />
              )}
            />
          )}
        </Box>
      </Box>
      {selectedCategory && selectedCategory.id === "Expenditure" && (
        <>
          {expenditureData.length > 0 ? (
            <>
              <BudgetsAggregatesTable
                countyBudgets={revenueData}
                onExportToCsv={onExportToCsv}
                budgetYear={selectedFiscal.name}
                header={`Expenditure Breakdown (${selectedFiscal.name})`}
                aggregateType={selectedCategory.id}
                budgetsAggregatesData={budgetsAggregatesData}
                budgetsAggregatesData2={budgetsAggregatesData2}
              />
              <div className="oc-heading-group" style={{ marginTop: "2rem" }}>
                <h4 className="styled-heading styled-heading-small">
                  {`Analysis of Operations & Maintenance (${selectedFiscal.name})`}
                </h4>
              </div>
            </>
          ) : (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="warning">
                {`Expenditure data for the fiscal year ${selectedFiscal.name} is not available`}
              </Alert>
            </Stack>
          )}

          {expenditureData.length > 0 && (
            <Row className="mb-4">
              <Col lg="12">
                <div className="oc-card">
                  {/* <ExpenditureGraph
                    expenditureData={expenditureData}
                    budgetsOperationsAndMaintenance={budgetsOperationsAndMaintenance.filter(
                      (item) =>
                        item.period === selectedFiscal.name.replace("-", "/")
                    )}
                    periodSelected={selectedFiscal.name}
                    colorMap={colorMap}
                  /> */}
                  {/* <span className="text-muted small">
                    Source: <strong>Office of Controller of Budgets</strong>
                  </span> */}
                </div>
              </Col>
              {/* <Col lg="6">
                <div className="oc-card">
                  <ExpenditureChartPie
                    expenditureData={expenditureData}
                    periodSelected={selectedFiscal.name}
                    colorMap={colorMap}
                  />
                  <span className="text-muted small">
                    Source: <strong>Office of Controller of Budgets</strong>
                  </span>
                </div>
              </Col> */}
            </Row>
          )}
          {expenditureData.length > 0 && (
            <div>
              {/* <ExpenditureTable
                countyExpenditure={expenditureData}
                onExportExpenditureToCsv={onExportExpenditureToCsv}
                expenditureYear={selectedFiscal.name}
                //onExportExpenditureToXls={onExportExpenditureToXls}
              /> */}
              <p>Data</p>
              <Grid item xs={12} sm={12} md={6} sx={{ marginTop: "2rem" }}>
                <TopSpendingProjects projects={projects} isTable={true} />
              </Grid>
            </div>
          )}
        </>
      )}
      {selectedCategory && selectedCategory.id === "Revenue" && (
        <>
          {revenueData.length > 0 ? (
            <>
              <BudgetsAggregatesTable
                countyBudgets={revenueData}
                onExportToCsv={onExportToCsv}
                budgetYear={selectedFiscal.name}
                header={`Actual Received (${selectedFiscal.name})`}
                aggregateType={selectedCategory.id}
                budgetsAggregatesData={budgetsAggregatesData}
              />
              <div className="oc-heading-group" style={{ marginTop: "2rem" }}>
                <h4 className="styled-heading styled-heading-small">
                  {`Revenue Charts (${selectedFiscal.name})`}
                </h4>
              </div>
            </>
          ) : (
            <div className="alert alert-warning">
              {`Revenue data for the fiscal year ${selectedFiscal.name} is not available`}
            </div>
          )}

          {revenueData.length > 0 && (
            <>
              <Row className="mb-4">
                <Col lg="6">
                  <div className="oc-card">
                    <RevenueGraph
                      revenueData={revenueData}
                      periodSelected={selectedFiscal.name}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="oc-card">
                    <RevenueChartPie
                      revenueData={revenueData}
                      periodSelected={selectedFiscal.name}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          {/* {revenueData.length > 0 && (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <BudgetsTable
                  countyBudgets={revenueData}
                  onExportToCsv={onExportToCsv}
                  budgetYear={selectedFiscal.name}
                />
              </Grid>
            </Grid>
          )} */}
        </>
      )}

      {selectedCategory && selectedCategory.id === "Summary" && (
        <>
          {/* <div className="oc-budget-cards">
            <RevExCard revExItem={budgetComp} />
            <RevExCard revExItem={expenditureComp} />
            <RevExCard revExItem={absorption} />
          </div> */}
          <BudgetsAggregatesTable
            countyBudgets={revenueData}
            onExportToCsv={onExportToCsv}
            budgetYear={selectedFiscal.name}
            header={`Finance & Planning (Cumulative since FY 2013-2014)`}
            aggregateType={selectedCategory.id}
            budgetsAggregatesData={budgetsAggregatesData}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "2rem" }}>
              <RevExpeditureLine
                dataOnExpenditure={expenditureTrends}
                dataOnRevenue={revenueTrends}
                dataOnBudgetTrends={budgetsTrendAnalysis}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={{ marginTop: "2rem" }}>
              <TopSpendDepts departmentSpendData={departmentSpendData} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={{ marginTop: "2rem" }}>
              <TopFiveBudgetAllocations expenditureItems={expenditureItems} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={{ marginTop: "2rem" }}>
              <TopBudgetAbsorptionDepts
                departmentSpendData={departmentSpendData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={{ marginTop: "2rem" }}>
              <div className="oc-card">
                <TopSpendingProjects projects={projects} isTable={false} />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
