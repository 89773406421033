import { useState, useEffect, useCallback } from "react";
//hooks
import useHttp from "./useHttp";
// ----------------------------------------------------------------------

const useFetchSabasiWards = () => {
  const [wards, setWards] = useState([]);
  const { isLoading, sendRequest } = useHttp();

  const fetchFilters = useCallback(async () => {
    try {
      const response = await sendRequest(
        `https://api.sabasi.mobi/api/nandi/wards`
      );
      if (response && response.data) {
        setWards(response.data);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  return { isLoading, wards };
};

export default useFetchSabasiWards;
