// material
import React from 'react';
import { capitalCase } from 'change-case';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function ProjectListItem({ projectItem, page }) {
    const { id, name, department, activities, status, year,constituency, ward , cidp} = projectItem;
    const navigate = useNavigate();
    // let labelColor = "warning";
    //                 if (status && status === "Not started") {
    //                   labelColor = "error";
    //                 } else if (status && status === "Complete") {
    //                   labelColor = "success";
    //                 } else if (status && status === "Complete & operational") {
    //                   labelColor = "info";
    //                 } else if(status && status === "Incomplete") {
    //                   labelColor = "warning"
    //                 }
  return (
    <div className="project-card" onClick={()=>navigate(`/projects/${id}`, { replace: false })}>
      <div className="project-card-main">
        <h5>{capitalCase(name? name : '')}</h5>
        <ul>
          {constituency ? <li>{constituency.name} Sub-county</li> : ''}
          {ward.length !== 0 ? <li>{ward.name} Ward</li> : ''}
          {department ? <li><span>{department.name}</span></li> : ''}
        </ul>
        {activities ? <p>{activities}</p> : ''}
      </div>
      <div className="project-card-bottom">
        <ul>
          {status ? <li><span>Status: </span>{status}</li>: ''}
          {status ? <li><span>Financial Year: </span>{year}</li>: ''}
          {status ? <li><span>CIDP: </span>{cidp}</li>: ''}
        </ul>
      </div>

    </div>
  );
}
