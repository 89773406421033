import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@mui/utils';
import { Box, TableRow, TableCell, TableHead } from '@mui/material';
import React from 'react';
import {Tooltip} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons'

// ----------------------------------------------------------------------

BudgetsAggregatesTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func
};

export default function BudgetsAggregatesTableHead({
  order,
  orderBy,
  headLabel,
}) {

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
            ) : null}     
            <Tooltip title={headCell.tooltip} arrow placement='top'>
              <span style={{cursor: 'pointer'}}><FontAwesomeIcon className="ms-1" icon={faQuestionCircle} /></span>
            </Tooltip>                   
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
