import React, { useState } from "react";
// material
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { fShortenNumber } from "../util/formatNumber";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export default function OldIndicatorTable({
  isLoading,
  display,
  selectedItemIndicator,
}) {
  const [page, setPage] = useState(0);
  //const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showValue = (myKey, _set, product, period, format) => {
    if (display) {
      let cell = display.data.find(
        (a) =>
          a[display.metadata.key] === product.name &&
          a.period === period &&
          a[display.metadata.set_key] === _set
      );
      return cell
        ? cell.value != 0
          ? format === true
            ? fShortenNumber(cell.value)
            : cell.value
          : "0"
        : "0";
    }
  };

  const unitsOfMeasure = new Set();

  display.data &&
    display.data.forEach((d) => {
      unitsOfMeasure.add(`${d[display.metadata.set_key]} - ${d.value_type}`);
    });

  return (
    <>
      {isLoading ? (
        <CircularProgress color="success" />
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{ minWidth: 800, maxHeight: 510 }}
          >
            <Table
              stickyHeader
              size="small"
              aria-label="indicators"
              className="data-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ backgroundColor: "#108214", color: "#fff" }}
                    sx={{ border: 1, borderColor: "#efefef" }}
                    colSpan={2}
                  >
                    Sub-county / Product
                  </TableCell>
                  {display && display.metadata && display.metadata.sets ? (
                    display &&
                    display.metadata &&
                    display.metadata.periods &&
                    display.metadata.periods.map((p) => (
                      <TableCell
                        key={p}
                        align={
                          display &&
                          display.metadata &&
                          display.metadata.sets &&
                          display.metadata.sets.length > 1
                            ? "center"
                            : "left"
                        }
                        colSpan={
                          display && display.metadata
                            ? display.metadata.sets.length
                            : 0
                        }
                        style={{ backgroundColor: "#3c3c3c", color: "#fff" }}
                        sx={{ border: 1, borderColor: "#efefef" }}
                      >
                        {p}
                      </TableCell>
                    ))
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
                {display &&
                  display.metadata &&
                  display.metadata.sets &&
                  display.metadata.sets.length > 1 && (
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        sx={{
                          backgroundColor: "#108214",
                          color: "white",
                          border: 1,
                          borderColor: "#efefef",
                        }}
                        style={{
                          top: 32,
                          // position: "sticky",
                          // left: 0,
                          // zIndex: 11,
                        }}
                      ></TableCell>
                      {display &&
                        display.metadata &&
                        display.metadata.periods &&
                        display.metadata.periods.length > 0 &&
                        display.metadata.periods.map((p) => {
                          return (
                            display &&
                            display.metadata &&
                            display.metadata.sets &&
                            display.metadata.sets.map((set) => (
                              <TableCell
                                sx={{
                                  backgroundColor: "#f7f7f7",
                                  color: "#000",
                                  border: 1,
                                  borderColor: "#efefef",
                                }}
                                key={Math.random().toString()}
                                style={{ top: 32 }}
                              >
                                {set}
                                <br />(
                                {extractUnitsofMeasure(unitsOfMeasure, set)})
                              </TableCell>
                            ))
                          );
                        })}
                    </TableRow>
                  )}
              </TableHead>
              <TableBody>
                {display &&
                display.metadata &&
                display.metadata.products &&
                display.metadata.products.length !== 0 ? (
                  display &&
                  display.metadata &&
                  display.metadata.products &&
                  display.metadata.products
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((product) => {
                      return (
                        <TableRow tabIndex={-1} key={Math.random().toString()}>
                          <TableCell
                            colSpan={product.alias ? "" : 2}
                            sx={{
                              border: 1,
                              borderColor: "#efefef",
                              fontWeight: 600,
                            }}
                            style={{
                              backgroundColor: "#fff",
                              top: 57,
                              display: product.location ? "" : "none", // Hide cell if subcounty is absent
                              // position: "sticky",
                              // left: 0,
                              // zIndex: 10,
                              // backgroundColor: "#F3F6FB",
                            }}
                          >
                            {product.location}
                          </TableCell>
                          <TableCell
                            colSpan={product.location ? "" : 2}
                            sx={{
                              border: 1,
                              borderColor: "#efefef",
                              fontWeight: 600,
                            }}
                            style={{
                              backgroundColor: "#fff",
                              top: 57,
                              // position: "sticky",
                              // left: 98,
                              // zIndex: 10,
                              // backgroundColor: "#F3F6FB",
                            }}
                          >
                            {product.alias}
                          </TableCell>
                          {display &&
                            display.metadata &&
                            display.metadata.periods &&
                            display.metadata.periods.length > 0 &&
                            display.metadata.periods.map((per) => {
                              return (
                                display &&
                                display.metadata &&
                                display.metadata.sets &&
                                display.metadata.sets.map((set) => (
                                  <TableCell
                                    key={Math.random().toString()}
                                    sx={{ border: 1, borderColor: "#efefef" }}
                                  >
                                    {showValue(
                                      display.key,
                                      set,
                                      product,
                                      per,
                                      true
                                    )}
                                  </TableCell>
                                ))
                              );
                            })}
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell align="center" sx={{ padding: "20px" }}>
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="info">
                          Oops! Looks like there's no data in this table. Try
                          selecting another indicator.
                        </Alert>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={
              display && display.metadata && display.metadata.products
                ? display.metadata.products.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
}
function extractUnitsofMeasure(unitsOfMeasure, set) {
  var f = [...unitsOfMeasure].find((a) => a.includes(set));
  return f ? f.split(" - ")[1] : "";
}
