import React, { useState, useCallback, useEffect, useContext } from "react";
import { CSVLink } from "react-csv";
//import ReactToPrint from 'react-to-print';
import { Button, Box } from "@mui/material";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import CodeIcon from "@mui/icons-material/Code";
import LocalPrintshopIcon from "@mui/icons-material/PictureAsPdf";
import "jspdf-autotable";
import { handlePdfExport } from "../util/pdfExports";
import { fNumber } from "../util/formatNumber";
import CountyContext from "../store/CountyContext";

const EXPORT_HEADERS = [
  { label: "Name", key: "name" },
  { label: "Department", key: "department" },
  { label: "Sub County", key: "subcounty" },
  { label: "Status", key: "status" },
  { label: "Contractor", key: "contractor" },
  { label: "Year", key: "year" },
  { label: "Amount", key: "amount" },
  { label: "Impact", key: "impact" },
  { label: "Payment Status", key: "paymentStatus" },
  { label: "Status Description", key: "statusDescription" },
  { label: "Feedback", key: "feedback" },
  { label: "Activities", key: "activities" },
];

const Export = ({ projectData }) => {
  const [items, setItems] = useState([]);
  const { name } = useContext(CountyContext);
  //const componentRef = useRef();

  const handleDataConv = useCallback(() => {
    if (projectData) {
      const toExport = projectData.map((dt) => ({
        id: dt.id ? dt.id : "",
        name: dt.name ? dt.name : "",
        department: dt.department ? dt.department.name : "-",
        subcounty: dt.constituency ? dt.constituency.name : "-",
        status: dt.status ? dt.status : "-",
        contractor: dt.contractor ? dt.contractor : "-",
        year: dt.year ? dt.year : "-",
        amount: dt.amount ? dt.amount : "-",
        impact: dt.impact ? dt.impact : "-",
        paymentStatus: dt.payment_status ? dt.payment_status : "-",
        statusDescription: dt.status_description ? dt.status_description : "-",
        feedback: dt.feedback ? dt.feedback : "-",
        activities: dt.activities ? dt.activities : "-",
      }));
      setItems(toExport);
    }
  }, [projectData]);

  const onHandlePdfExport = () => {
    handlePdfExport(
      ["Name", "Department", "Sub County", "Status", "Year", "Amount"],
      projectData.map((dt) => [
        dt.name ? dt.name : "",
        dt.department ? dt.department.name : "-",
        dt.constituency ? dt.constituency.name : "-",
        dt.status ? dt.status : "-",
        dt.year ? dt.year : "-",
        dt.amount ? fNumber(dt.amount) : "-",
      ]),
      `${Date.now().toString()}-${name}_County_Projects`,
      `${Date.now().toString()}-${name}_County_Projects.pdf`,
      ""
    );
  };

  useEffect(() => {
    handleDataConv();
  }, [handleDataConv]);

  return (
    // <Scrollbar sx={{maxHeight: 800}}>
    <>
      <h5 className="sidebar-title">Export</h5>
      <Box
        className="export-options"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <CSVLink
          style={{ textDecoration: "none", color: "inherit" }}
          headers={EXPORT_HEADERS}
          asyncOnClick={true}
          data={items}
          filename={`${Date.now().toString()}-${name}_County-Projects.csv`}
          onClick={(event, done) => {
            handleDataConv();
            done();
          }}
        >
          <Button
            className="export-item"
            sx={{ mb: 2 }}
            startIcon={<FileDownloadDoneIcon />}
          >
            CSV
          </Button>
        </CSVLink>
        <CSVLink
          style={{ textDecoration: "none", color: "inherit" }}
          headers={EXPORT_HEADERS}
          asyncOnClick={true}
          data={items}
          filename={`${Date.now().toString()}-${name}_County-Projects.xlsx`}
          onClick={(event, done) => {
            handleDataConv();
            done();
          }}
        >
          <Button
            className="export-item"
            sx={{ mb: 2 }}
            startIcon={<CodeIcon />}
          >
            Excel
          </Button>
        </CSVLink>
        <Button
          className="export-item"
          sx={{ mb: 2 }}
          onClick={onHandlePdfExport}
          startIcon={<LocalPrintshopIcon />}
        >
          PDF
        </Button>
        {/* <ReactToPrint
          trigger={() => <Button 
              size='large'
              sx={{ mb: 2 }}
              variant="outlined" 
              color='info' 
              startIcon={<LocalPrintshopIcon />}>
                  Print
                </Button>}
          content={() => componentRef.current}
        /> */}
        {/* <div id='divToPrint' style={{display: 'none'}}>
            <ProjectsComponent ref={componentRef} data={items}/>
        </div> */}
      </Box>
      {/* </Scrollbar> */}
    </>
  );
};

export default Export;
