import { useState, useEffect, useCallback } from 'react';
//hooks
import useHttp from './useHttp';
// ----------------------------------------------------------------------

const useFetchSectors = () => {
  const [sectors, setSectors] = useState([]);
  const { isLoading, sendRequest } = useHttp();

  const fetchFilterSources = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/sectors`
      );
      if (response && response.data && response.data.length > 0) {
        setSectors(response.data);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchFilterSources();
  }, [fetchFilterSources]);

  return { isLoading, sectors };
};

export default useFetchSectors;
