export default function ProjectsToolbar({
  children,
}) {

  return (
    <>
      {children}
    </>
  );
}
