import React, { useState, useEffect, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import FilterIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Clear";
import {
  MenuItem,
  Select,
  Chip,
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Accordion } from "react-bootstrap";
import useHttp from "../hooks/useHttp";

// import Scrollbar from '../../Scrollbar';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, filterName, theme) {
  return {
    fontWeight:
      filterName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const getNamesByIds = (mainArray, idArray) => mainArray.filter(item => idArray.includes(item.id)).map(item => item.name);

const ProjectFilters = ({
  handleFilterProjects,
  handleClearFilters,
  handleSelectedFilters,
  handleUpdateFilter,
  loadingState,
}) => {
  const theme = useTheme();
  const [departments, setDepartments] = useState([]);
  const [wards, setWards] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const [years, setYears] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    period: [],
    department: [],
    subcounty: [],
    ward: [],
    status: []
  });
  const [appliedFilters, setAppliedFilters] = useState(0);
  const { sendRequest } = useHttp();

  const handleSelectedProjectFilters = () => {
    const url = `${process.env.REACT_APP_BASEDEVURL}/api/projects?`;
    const filterUrl = url.concat(
      `period=${selectedFilters.period.toString()}
      &status=${selectedFilters.status.toString()}
      &ward=${selectedFilters.ward.toString()}
      &constituency=${selectedFilters.subcounty.toString()}
      &department=${selectedFilters.department.toString()}`
    );
    handleSelectedFilters([
      ...selectedFilters.period,
      ...selectedFilters.status,
      ...wards.filter((ward) => selectedFilters.ward.includes(ward.id)).map((ward) => ward.name),
      ...constituencies.filter((constituency) => selectedFilters.subcounty.includes(constituency.id)).map((constituency) => constituency.name),
      ...departments.filter((department) => selectedFilters.department.includes(department.id)).map((department) => department.name),
    ]);
    handleFilterProjects(filterUrl);
  };

  const clearFilters = () => {
    handleClearFilters();
    setSelectedFilters({
      department: [],
      period: [],
      status: [],
      subcounty: [],
      ward: []
    })
  };

  const handleChange = (event) => {
    const { target: { value, name } } = event;
    setSelectedFilters(prev=>({...prev, [name]: typeof value === "string" ? value.split(",") : value}));
    if (name==="ward") {
      handleUpdateFilter(getNamesByIds(wards,typeof value === "string" ? value.split(",") : value))
    } else if (name==="subcounty") {
      handleUpdateFilter(getNamesByIds(constituencies,typeof value === "string" ? value.split(",") : value))
    } else if (name==="department") {
      handleUpdateFilter(getNamesByIds(departments,typeof value === "string" ? value.split(",") : value))
    } else {
      handleUpdateFilter(typeof value === "string" ? value.split(",") : value)
    }
  }

  useEffect(() => {
    const totalApplied =
    selectedFilters.subcounty.length +
    selectedFilters.department.length +
    selectedFilters.period.length +
    selectedFilters.status.length +
    selectedFilters.ward.length;
    setAppliedFilters(totalApplied);
  }, [
    selectedFilters
  ]);
  

  const fetchFilterSources = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BASEDEVURL}/api/projects/filters`
      );
      if (response && response.data && response) {
        setDepartments(response.data.departments);
        setWards(response.data.wards);
        setConstituencies(response.data.constituencies);
        setStatuses(response.data.statuses);
        setYears(response.data.years);
      }
    } catch (error) { }
  }, [sendRequest]);

  useEffect(() => {
    fetchFilterSources();
  }, [fetchFilterSources]);

  // <Scrollbar sx={{maxHeight: 800}}>
  return (
    <>
      <h5 className="sidebar-title">Filters</h5>
      <Accordion defaultActiveKey={1} flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{`Financial Year (${years.length})`}</Accordion.Header>
          <Accordion.Body>
            <FormControl fullWidth>
              <InputLabel id="period-label">Select Financial Year</InputLabel>
              <Select
                labelId="period-label"
                id="period-chip"
                variant="outlined"
                multiple
                name="period"
                value={selectedFilters.period}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {years.map((year, index) => (
                  <MenuItem
                    key={year.year}
                    value={year.year}
                    style={getStyles(year.year, selectedFilters.period, theme)}
                  >
                    <Checkbox
                      checked={selectedFilters.period.indexOf(year.year) > -1}
                    />
                    <ListItemText primary={year.year} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{`Departments (${departments.length})`}</Accordion.Header>
          <Accordion.Body>
            <FormControl fullWidth>
              <InputLabel id="department-label">Select Department</InputLabel>
              <Select
                labelId="department-label"
                id="department-chip"
                variant="outlined"
                multiple
                fullWidth
                name="department"
                value={selectedFilters.department}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((dep) => (
                      <Chip key={dep} label={departments.filter((
                        department) => department.id === dep)[0].name} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {departments.map((department, index) => (
                  <MenuItem
                    key={department.id}
                    value={department.id}
                    style={getStyles(
                      department.name,
                      selectedFilters.department,
                      theme
                    )}
                  >
                    <Checkbox
                      checked={
                        selectedFilters.department
                          .indexOf(department.id) > -1
                      }
                    />
                    <ListItemText primary={department.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{`Subcounty (${constituencies ? constituencies.length : "0"
            })`}</Accordion.Header>
          <Accordion.Body>
            <FormControl fullWidth>
              <InputLabel id="constituency-label">Select Sub-county</InputLabel>
              <Select
                labelId="constituency-label"
                id="constituency-chip"
                variant="outlined"
                multiple
                fullWidth
                name="subcounty"
                value={selectedFilters.subcounty}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((consti) => (
                      <Chip key={consti} label={constituencies.filter((
                        constituency) => constituency.id === consti)[0].name} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {constituencies.map((constituency, index) => (
                  <MenuItem
                    key={constituency.id}
                    value={constituency.id}
                    style={getStyles(
                      constituency.name,
                      selectedFilters.subcounty,
                      theme
                    )}
                  >
                    <Checkbox
                      checked={
                        selectedFilters.subcounty
                          .indexOf(constituency.id) > -1
                      }
                    />
                    <ListItemText primary={constituency.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{`Ward (${wards ? wards.length : "0"
            })`}</Accordion.Header>
          <Accordion.Body>
            <FormControl fullWidth>
              <InputLabel id="ward-label">Select Ward</InputLabel>
              <Select
                labelId="ward-label"
                id="ward-chip"
                variant="outlined"
                multiple
                fullWidth
                value={selectedFilters.ward}
                name="ward"
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((wrd) => (
                      <Chip key={wrd} label={wards.filter((
                        ward) => ward.id === wrd)[0].name} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {wards.map((ward, index) => (
                  <MenuItem
                    key={ward.id}
                    value={ward.id}
                    style={getStyles(ward.name, selectedFilters.ward, theme)}
                  >
                    <Checkbox
                      checked={
                        selectedFilters.ward.indexOf(ward.id) >
                        -1
                      }
                    />
                    <ListItemText primary={ward.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>{`Status (${statuses ? statuses.length : "0"
            })`}</Accordion.Header>
          <Accordion.Body>
            <FormControl fullWidth>
              <InputLabel id="status-label">Select Status</InputLabel>
              <Select
                labelId="status-label"
                id="status-chip"
                multiple
                fullWidth
                name="status"
                value={selectedFilters.status}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {statuses.map((status, index) => (
                  <MenuItem
                    key={status.status}
                    value={status.status}
                    style={getStyles(status.status, selectedFilters.status, theme)}
                  >
                    <Checkbox
                      checked={selectedFilters.status.indexOf(status.status) > -1}
                    />
                    <ListItemText primary={status.status} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          mt: 3,
        }}
      >
        <Button
          className="mb-2"
          variant="contained"
          style={{ fontWeight: 500, textTransform: "capitalize" }}
          color="success"
          disabled={loadingState || appliedFilters === 0}
          startIcon={<FilterIcon />}
          onClick={handleSelectedProjectFilters}
        >
          {`Apply Filters (${appliedFilters})`}
        </Button>
        <Button
          disabled={loadingState || appliedFilters === 0}
          style={{ fontWeight: 500, textTransform: "capitalize" }}
          color="error"
          startIcon={<ClearIcon />}
          onClick={clearFilters}
        >
          Clear Filters
        </Button>
      </Box>
    </>
  );
  // </Scrollbar>
};

export default ProjectFilters;
