import { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import useHttp from "../hooks/useHttp";
import { LocalHospital } from "@mui/icons-material";

export const getHealthData = (fetchedHealthData) => {
  if (fetchedHealthData) {
    return fetchedHealthData;
  }
  return {
    beds: "",
    cadres: "",
    created_at: "",
    deleted_at: "",
    departments: "",
    email: "",
    facility: "",
    gps: "",
    id: 0,
    infrastructure: "",
    nature: "",
    open_hours: "",
    owner: "",
    phone: "",
    population: "",
    respondent: "",
    respondent_position: "",
    sector: "",
    status: "",
    type: "",
    updated_at: "",
    utilities: "",
    ward: "",
  };
};

export const resolveHours = (hours) => {
  if (hours === "24 hours") {
    return (
      <div className="rounded bg-gray-900 py-7 px-4 max-h-4 flex flex-col justify-center items-center ml-8">
        <p className="text-white text-xs text-center">
          24 <br></br> Hrs
        </p>
      </div>
    );
  } else if (hours === "8 am - 5 pm") {
    return (
      <div className="rounded bg-gray-900 py-10 px-4 max-h-8 flex flex-col justify-center items-center ml-6 h-full">
        <p className="text-white text-xs text-center">
          <span className="block">8 AM</span>
          <span className="block">-</span>
          <span className="block">5 PM</span>
        </p>
      </div>
    );
  } else {
    return (
      <div className="rounded bg-gray-900 py-10 px-4 max-h-4 flex flex-col justify-center items-center ml-8">
        <p className="text-white text-xs text-center">{hours}</p>
      </div>
    );
  }
};

const MapPopUp = ({ healthCode, facility }) => {
  const { isLoading: isLoadingHealthData, sendRequest } = useHttp();
  const [fetchedHealthData, setFetchedHealthData] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const res = await sendRequest(
        `https://api.sabasi.mobi/api/nandi/data/${healthCode}`
      );
      if (res && res.data) {
        setFetchedHealthData(res.data);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return isLoadingHealthData ? (
    <Box sx={{ display: "flex" }}>
      <CircularProgress color="success" />
    </Box>
  ) : (
    <div className="flex flex-col p-2 bg-white">
      <div className="flex flex-row space-x-4 -mt-2 my-3">
        <LocalHospital sx={{ color: "red", fontSize: 40 }} />
        <div>
          <h3 className="text-base">{facility}</h3>
        </div>
      </div>
      <div className="-mt-3 mb-2">
        <hr className="h-px bg-gray-300 border-0"></hr>
      </div>
      <div className="flex flex-row justify-between">
        <div className="mr-8 text-sm">
          <p>{`Bed Capacity: ${getHealthData(fetchedHealthData).beds}`}</p>
          <p>{`Infrastructure Quality: ${
            getHealthData(fetchedHealthData).infrastructure
          }`}</p>
          <p className="text-blue-800">Click pin for more details </p>
        </div>
        {resolveHours(getHealthData(fetchedHealthData).open_hours)}
      </div>
    </div>
  );
};

export default MapPopUp;
