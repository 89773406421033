import { useState, useEffect, useCallback } from "react";
//hooks
import useHttp from "./useHttp";
// ----------------------------------------------------------------------
// https://api.sabasi.mobi/api/nandi/geo?sector=education

const useFetchSabasiEducationGeoData = () => {
  const [educationGeo, setEducationGeo] = useState([]);
  const { isLoading, sendRequest } = useHttp();

  const fetchFilterSources = useCallback(async () => {
    try {
      const response = await sendRequest(
        `https://api.sabasi.mobi/api/nandi/geo?sector=Education`
      );
      if (response && response.data && response.data.length > 0) {
        setEducationGeo(response.data);
      }
    } catch (error) {}
  }, [sendRequest]);

  useEffect(() => {
    fetchFilterSources();
  }, [fetchFilterSources]);

  return { isLoading, educationGeo, setEducationGeo };
};

export default useFetchSabasiEducationGeoData;
